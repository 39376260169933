import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import SelectBoxComponent from '../formFields/selectBoxComponent';

class PolicyDefaultAudienceLbxComponent extends Component {
    optionArr = []
    validate = (values) => {
        const errors = {};

        if (!values || !values.audiences || values.audiences === "") {
            errors.audiences = "Zorunlu";
        }

        return errors;
    }
    submit = (values) => {
        this.props.updateWebsitePolicy(values.audiences);
    }

    componentDidMount() {
        this.setOptionArr();
    }

    setOptionArr = () => {
        if (this.props.policyArr) {
            this.props.policyArr.forEach(item => {
                let optionItem = {
                    value: item.id,
                    text: item.name
                }
                this.optionArr.push(optionItem)
            })
        }
    }

    render() {
        let { isDefaultOpen, toggleDefault, policyArr, initialOption } = this.props;
        console.log("POLICYYY--------", policyArr);
        console.log("initialOption-------", initialOption);
        return (
            <div className={`lightBox-root default-aud ${isDefaultOpen ? "audience" : ""}`}>
                <div className="lightBox-wrapper">
                    <Form onSubmit={this.submit}
                        validate={this.validate}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="lightBox-container">
                                    <div className="head-item">
                                        <h2 className='light-box-title'>Default Audiences</h2>
                                        <div className="close-icon" onClick={toggleDefault}>
                                            <i>
                                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                            </i>
                                        </div>
                                    </div>
                                    <div className="form-block">
                                        <Field
                                            name={"audiences"}
                                            label="Default audiences"
                                            component={SelectBoxComponent}
                                            options={this.optionArr}
                                            placeholder={this.optionArr.find(x => x.value === initialOption.id)?.text}
                                            initialOption={this.optionArr.find(x => x.value === initialOption.id)}
                                        />
                                        <span className='info-text'> 
                                            Selected policy template will be valid for all audiences other than your choices below.
                                        </span>
                                    </div>
                                    <div className="btn-block">
                                        <div className="btn-item cancel" onClick={toggleDefault}>
                                            <span>Cancel</span>
                                        </div>
                                        <button className="btn-item" type='submit'>
                                            <span>Save</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}>
                    </Form>
                </div>
            </div>
        );
    }
}

export default PolicyDefaultAudienceLbxComponent;