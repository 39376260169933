export const categories = [
    {
        name:"Necessary",
        id:2,
        default:true
    },
    {
        name:"Analytics",
        id:1
    },
    {
        name:"Social",
        id:4
    },
    {
        name:"Adds",
        id:5
    }
]