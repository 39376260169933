import React, { Component } from 'react';
import PasswordRequirementsComponent from "./passwordReguirementsComponent";
import { ASSETS } from '../../constants/Paths';

class TextInputComponent extends Component {
    componentDidMount(){
        if(this.props.isID){
            this.props.input.onChange(this.props.placeholder)
        }
    }

    render() {
        const {forgotPass,input,messageText, label, disabled, placeholder, type,  err_txt, meta: { submitFailed, error, touched }, isFailed, isCreateUser, mailDomains, isRegion, isID } = this.props;     
        
        return (
            /*TODO: "pass-prop" için koşul yazılması gerekiyor.*/
            <div className={`input-item ${touched && error  ? "error" : ""}${isFailed === true ? "failed" : ""} ${0 ? "pass-prop" : ""} ${isCreateUser && input.value.length > 0 ? "suggest-mail-input" : ""}`}>
                <label htmlFor="" className="text-title-item">{label} {isRegion && <img src={`${ASSETS}img/info.svg`} alt='info'></img>}</label>
                <input {...input} placeholder={placeholder} autoComplete={isCreateUser ? "off" : "on"} disabled={isID ? true : false}/>
               {
                  forgotPass&&
                  <p className="forgot-password-item"><a href="reset">Forgot Password</a></p>
               }
                {
                    messageText &&
                    <p>{messageText}</p>
                }
                {
                    // isCreateUser && input.value.length > 0 &&
                    // <div className='suggest-mail-block'>
                    //     <p className='suggest-title-item'>Domain extensions which you can use</p>
                    //     {
                    //         mailDomains.map((item, key)=> (
                    //             <div className='suggest-mail-item'>
                    //                 <p>{input.value}</p>
                    //                 <p key={key}>{"@" + item}</p>
                    //             </div>
                    //         ))
                    //     }
                    // </div>
                }
                <PasswordRequirementsComponent/>
                <div className="error-block">
                    <span>{err_txt}</span>
                </div>
            </div>
        )
    }
};

export default TextInputComponent;
