import React, {Component} from "react";
import {ASSETS} from "../constants/Paths-prod";
import {Field, Form} from "react-final-form";
import {required} from "../helper";
import TextInputComponent from "../components/formFields/textInputComponent";
import RecaptchaFieldComponent from "../components/formFields/recaptchaFieldComponent";
import {NavLink} from "react-router-dom";
import {LOGIN_PAGE} from "../constants/routePaths";
import SlideComponent from "../components/slideComponent";
import PasswordResetFormComponent from "../components/forms/passwordResetFormComponent";
import CoverComponent from "../components/common/coverComponent";
import LogoComponent from "../components/common/logoComponent";


class faz2resetPassword extends Component {
    state = {
        slideState: 0,
        openedFaqItems:[]
    }

    quickTipArr = [
        {
            "id":1,
            slideText:"You can easily create new audiences for different regions."
        },
        {
            "id":2,
            slideText:"You can easily create new audiences for different regions."
        },
        {
            "id":3,
            slideText:"You can easily create new audiences for different regions."
        }
    ]


    changeSlideIndex = (direction) => {
        this.setState({
            slideState:(this.state.slideState+direction)<0?this.quickTipArr.length-1 : (this.state.slideState+direction)%this.quickTipArr.length
        })
    }

    toggleFaqItem = (index) => {
        let clickedIndex = this.state.openedFaqItems.indexOf(index);
        if(clickedIndex===-1){
            this.setState({
                openedFaqItems:[...this.state.openedFaqItems,index]
            })
        }else{
            this.setState({
                openedFaqItems:[...this.state.openedFaqItems.slice(0,clickedIndex),...this.state.openedFaqItems.slice(clickedIndex+1)]
            })
        }
    }
    render() {
        let slideText, slideNumber;

        return (
            <div className="login-root reset-pass-root">

                <div className="login-wrapper">
                    <div className="login-left-wrapper">
                        <LogoComponent/>
                        <div className="login-content-container">
                            <div className="login-container">
                                <div className="title-block">
                                    <div className="title-item">
                                        <h3>Password Reset</h3>
                                    </div>
                                </div>
                                <PasswordResetFormComponent/>
                                <div className="bottom-btn-block">
                                    <div className="text-item">
                                        <span>I have an account</span>
                                    </div>
                                    <NavLink exact to={LOGIN_PAGE} className={`btn-item white-btn`}>
                                        <span>Login</span>
                                    </NavLink>

                                </div>



                            </div>
                        </div>
                    </div>

                    <CoverComponent/>
                </div>

            </div>



        );
    }
}

export default faz2resetPassword;
