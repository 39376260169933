import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import TextInputComponent from '../formFields/textInputComponent';
import SelectBoxComponent from '../formFields/selectBoxComponent';
import { upsertDomainPolicy } from '../../services/webServices';

class PolicyEditAudienceLbxComponent extends Component {
    optionArr = [];
    validate = (values) => { 
        const errors = {};

        if (!values || !values.regions || values.regions === "") {
           errors.regions = "Zorunlu";
        }

        if (!values || !values.template || values.template === "") {
            errors.template = "Zorunlu";
        }

        return errors;
    }
    submit = (values) => {
        upsertDomainPolicy(this.props.domainItem.id, this.props.domainItem.domain_id, values.regions, values.template, this.props.domainItem.index_key).then((res)=> {
            if(res.data.success){
                this.props.getPolicies();
                this.props.toggleEdit();
            }
        }).catch(err => console.log("ERR---", err))
        
    }

    componentDidMount(){
        this.setOptionArr();
    }

    setOptionArr = () => {
        if(this.props.policyArr){
            this.props.policyArr.forEach(item => {
                let optionItem = {
                    value: item.id,
                    text: item.name
                }
                this.optionArr.push(optionItem)
            })
        }
    }

    render() {
        let { isEditOpen, toggleEdit, domainItem } = this.props;

        return (
            <div className={`lightBox-root ${isEditOpen ? "audience" : ""}`}>
                <div className="lightBox-wrapper">
                    <Form onSubmit={this.submit}
                        validate={this.validate}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="lightBox-container">
                                    <div className="head-item">
                                        <h2 className='light-box-title'>Edit Audience</h2>
                                        <div className="close-icon" onClick={toggleEdit}>
                                            <i>
                                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                            </i>
                                        </div>
                                    </div>
                                    <div className="form-block">
                                        <Field
                                            name={"regions"}
                                            type="text"
                                            label="Regions*"
                                            component={TextInputComponent}
                                            placeholder="Enter region(s)"
                                            isRegion={true}
                                            initialValue={domainItem.region_name}
                                        />
                                        <Field
                                            name={"id"}
                                            type="text"
                                            label="ID"
                                            component={TextInputComponent}
                                            placeholder={domainItem.index_key}
                                            isID={true}
                                        />
                                        <Field
                                            name={"template"}
                                            label="Policy template*"
                                            component={SelectBoxComponent}
                                            options={this.optionArr}
                                            placeholder={this.optionArr.find(x=> x.value === domainItem.policy_id)?.text}
                                            initialOption={this.optionArr.find(x=> x.value === domainItem.policy_id)}
                                        />
                                    </div>
                                    <div className="btn-block">
                                        <div className="btn-item cancel" onClick={toggleEdit}>
                                            <span>Cancel</span>
                                        </div>
                                        <button className="btn-item" type='submit'>
                                            <span>Save Changes</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}>
                    </Form>
                </div>
            </div>
        );
    }
}

export default PolicyEditAudienceLbxComponent;