import React, {Component,Fragment} from "react";
import {WEB_SITE_SITE_INFO, WEB_SITES} from "../../constants/routePaths";
import AsideNotifyComponent from "../layout/asideNotifyComponent";
import {Link, matchPath} from "react-router-dom";
import { Field, Form } from "react-final-form";
import {connect} from "react-redux";
import {
    getSiteInfoAction,
    updateDomainDetailSiteInfoAction
} from "../../actions/domainActions";
//import arrayMutators from "final-form-arrays";
import {required, turkishToLower} from "../../helper";
import TextInputComponent from "../formFields/textInputComponent";
import RadioInputComponent from "../formFields/radioInputComponent";
import BreadcrumbComponent from "../layout/breadcrumbComponent";
import SucsessComponent from "../layout/sucsessComponent";
class WebsiteDetailsComponent extends Component {
    state = {
        siteInfoData:null,
        success:false
    };
    componentDidMount() {
        let urlParams = matchPath(this.props.location.pathname, {
            path: WEB_SITE_SITE_INFO,
            exact: false,
            strict: false
        });
        this.getData(urlParams.params.id)
    }
    getData=(param)=>{
        this.props.dispatch(getSiteInfoAction(param,(result)=>{
            this.setState({
                siteInfoData:result
            })
        }));
    }
    onSubmit = (values) => {
        this.props.dispatch(updateDomainDetailSiteInfoAction(values,(res)=>{
            
            //TODO: Basarili mesaji
            this.getData(values.id)
            this.setState({
                success:true
            })
            clearTimeout(this.timer);
            this.timer = setTimeout(()=>{
                this.setState({
                    success:false
                })
            },2000)
        }))
    }
    render() {

       let{siteInfoData}=this.state;
        return (
            <div className="content-root website-details-page">
                {
                    this.props.siteInfoData &&
                    <BreadcrumbComponent breadName="Websites" breadLink={WEB_SITES} subBreadName={this.props.siteInfoData.name}/>
                }

                <SucsessComponent warn={this.state.success} message={"Domain updated."} />
                <div className="content-wrapper">
                    <div className="content-container">
                        <div className="content-block">
  {
                                siteInfoData &&
                                <Form
                                    onSubmit={this.onSubmit}
                                    // mutators={{
                                    //     ...arrayMutators
                                    // }}
                                    initialValues={this.state.siteInfoData}
                                    render={({handleSubmit, submitFailed, form, errors, values, form: {mutators: {push, pop}},}) =>

                                        <form onSubmit={handleSubmit} className="website-details-root">
                                            <Field
                                                name={`name`}
                                                label="Site Name"
                                                type="text"
                                                validate={required}
                                                component={TextInputComponent}
                                                placeholder="Site Adı giriniz"
                                                messageText=""
                                            />
                                            <Field
                                                name={`test_domain_name`}
                                                label="URL"
                                                type="text"
                                                validate={required}
                                                component={TextInputComponent}
                                                placeholder="URL giriniz"
                                                messageText={`Tool will be work on ${(new URL((values.test_domain_name.indexOf("http")===0 || values.test_domain_name.indexOf("https")===0?"":"http://")+values.test_domain_name)).hostname.replace('www.','')} domain`}
                                            />
                                            <div className="radio-container">
                                                <h3>Sandbox Mode</h3>

                                                <div className="radio-block">
                                                    <div className="radio-item">

                                                        <Field name="is_sandbox"  options={[
                                                            {value:1,name:"On"},
                                                            {value:0,name:"Off"},
                                                        ]} component={RadioInputComponent}/>
                                                    </div>
                                                    <a target="_blank" href={`${this.state.siteInfoData.test_domain_name.indexOf("http")===0 || this.state.siteInfoData.test_domain_name.indexOf("https")===0?"":"http://"}${this.state.siteInfoData.test_domain_name}?sandbox=true`} className="btn-item white-btn">
                                                        <span>Test Now</span>
                                                        <i>
                                                            <svg width={11} height={11} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_1600_413)">
                                                                    <path d="M3.5 2.00006L0.999999 2.00006L1 10.0001L9 10.0001L9 7.00006" stroke="#2723EA" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M9.70711 2.70711C10.0976 2.31658 10.0976 1.68342 9.70711 1.29289C9.31658 0.902369 8.68342 0.902369 8.29289 1.29289L9.70711 2.70711ZM8.29289 1.29289L3.29289 6.29289L4.70711 7.70711L9.70711 2.70711L8.29289 1.29289Z" fill="#2723EA" />
                                                                    <path d="M9.73588 2.32167L8.67833 1.26412L9.86808 1.13192L9.73588 2.32167Z" stroke="#2723EA" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_1600_413">
                                                                        <rect width={11} height={11} fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </i>
                                                    </a>
                                                </div>
                                                <p>When you open the sandbox mode, the interface elements can only be seen in the
                                                    given
                                                    link. By using this mode, you will have the opportunity to test it from the eyes
                                                    of
                                                    the user who will set the cookie policy.</p>

                                            </div>
                                            {/* <div className="radio-container cookie">
                                                <h3>Prevent cookies on load</h3>
                                                <div className="radio-item">
                                                    <Field name="prevent_cookies"  options={[
                                                        {value:1,name:"On"},
                                                        {value:0,name:"Off"},
                                                    ]} component={RadioInputComponent}/>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo posuere enim
                                                    etiam
                                                    interdum risus.</p>
                                            </div> */}
                                            <div className="radio-container auto">
                                                <h3>Auto update cookie list</h3>
                                                <div className="radio-item">
                                                    <Field name="auto_update_cookie"  options={[
                                                        {value:1,name:"On"},
                                                        {value:0,name:"Off"},
                                                    ]} component={RadioInputComponent}/>
                                                </div>
                                                <p>This feature listens for cookies set within the site and when a new cookie is detect, it lists this cookie under the Uncategorized tab on the Cookie List page.</p>
                                            </div>
                                            <div className="radio-container auto">
                                                <h3>Show placeholder for disabled content</h3>
                                                <div className="radio-item">
                                                    <Field name="is_placeholder_showing"  options={[
                                                        {value:1,name:"On"},
                                                        {value:0,name:"Off"},
                                                    ]} component={RadioInputComponent}/>
                                                </div>
                                                <p>This feature displays the placeholder if some areas not showing because user cookie selection.</p>
                                            </div>
                                            <div className="radio-container auto">
                                                <h3>Share consent selection with subdomains</h3>
                                                <div className="radio-item">
                                                    <Field name="share_cookies_with_subdomains"  options={[
                                                        {value:1,name:"On"},
                                                        {value:0,name:"Off"},
                                                    ]} component={RadioInputComponent}/>
                                                </div>
                                                <p>This feature allows user selections to keep same with other subdomains as well.</p>
                                            </div>
                                            {/* <div className="radio-container auto">
                                                <h3>Reject All Button Showing</h3>
                                                <div className="radio-item">
                                                    <Field name="is_reject_showing"  options={[
                                                        {value:1,name:"On"},
                                                        {value:0,name:"Off"},
                                                    ]} component={RadioInputComponent}/>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo posuere enim
                                                    etiam
                                                    interdum risus.</p>
                                            </div> */}
                                            <Field
                                                name={`cookie_policy_link`}
                                                label="Cookie policy URL"
                                                type="text"
                                                validate={required}
                                                component={TextInputComponent}
                                                placeholder="URL giriniz"
                                                messageText=""
                                            />
                                            <div className="btn-block">
                                                <button type="submit" className={`btn-item`}>
                                                    <span>Save</span>
                                                </button>
                                            </div>
                                        </form>

                                    }
                                />
                            }

                        </div>
                    </div>
                    {
                        this.state.siteInfoData &&
                        <AsideNotifyComponent did_integrate={this.state.siteInfoData.did_integrate} preSiteInfo={this.props.preSiteInfoData}/>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => ({
    siteInfoData: state.domainReducer.siteInfo&&state.domainReducer.siteInfo[ownProps.match.params.id]?state.domainReducer.siteInfo[ownProps.match.params.id]:null,
    preSiteInfoData: state.domainReducer.preSiteInfo&&state.domainReducer.preSiteInfo[ownProps.match.params.id]?state.domainReducer.preSiteInfo[ownProps.match.params.id]:null
})
export default connect(mapStateToProps)(WebsiteDetailsComponent);
