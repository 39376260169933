import {Link} from "react-router-dom";
import React, { Component } from 'react'
import {POLICIES_PAGE} from "../../constants/routePaths";
class BreadcrumbComponent extends Component {
    render() {
        let {breadName,breadLink,subBreadName} = this.props;

        return (
                <div className={`breadcrumb-block`}>
                    <Link to={breadLink || POLICIES_PAGE} className={`breadcrumb-item ${subBreadName?"right-arr":""}`}>{breadName}</Link>
                    {
                        subBreadName && <span className="breadcrumb-item" >{subBreadName}</span>
                    }
                </div>
        )
    }
}

export default BreadcrumbComponent;
