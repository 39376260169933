import React, { Component } from "react";

class SelectBoxComponent extends Component {
    state = {
        isOpen:false
    }
    optionRoot = React.createRef();
    componentDidMount(){
        window.addEventListener("click",this.windowClicked)
        setTimeout(()=>{
            if(this.props.initialOption){
                this.setInıtValue()
            }
        },50)
    }
    windowClicked = (e) => {
        if(this.optionRoot.current && !this.optionRoot.current.contains(e.target) && this.state.isOpen){
            this.setState({
                isOpen: false
            })
        }
    }
    setInıtValue = () => {
        this.props.input.onChange(this.props.initialOption.value)
    }
    toggleSelect = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    selectOption = (e,optionItem) => {
        this.props.input.onChange(optionItem.value)
    }
    componentWillUnmount(){
        window.removeEventListener("click",this.windowClicked)
    }
    render() {
        let { input, placeholder, label, options, initialOption, meta: {submitFailed, error, touched} } = this.props;

        return (
            <div className={`select-item ${touched && error ?"error":""}`}>
                <label className="text-title-item">{label}</label>
               
                    <div className={`select-block  ${this.state.isOpen?"opened":""}`} ref={this.optionRoot} onClick={this.toggleSelect}>
                        <span className={input.value?"selected":""}>
                            {(initialOption && initialOption.value === input.value) ? initialOption.text : (input.value ? options.find(item=> item.value===input.value).text  : placeholder)}
                        </span>
                        <div className="lang-option-block">
                            {
                                options.map((optionItem, key) =>
                                    <ul key={key} className="options-item" onClick={(e)=>{this.selectOption(e,optionItem)}}>
                                        <li>{optionItem.text}</li>
                                    </ul>
                                )
                            }
                        </div>
                    </div>
                
            </div>
        )
    }
};

export default SelectBoxComponent;
