import React, { Component } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import Quill from "quill";
import sanitizeHtml from "sanitize-html"

const Link = Quill.import('formats/link');

class CustomLink extends Link {
    static create(value) {
        const node = document.createElement('a');
        node.setAttribute('href', sanitizeHtml(value))
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', "noreferrer");
        return node;
    }
}

Quill.register('formats/link', CustomLink);

class HtmlEditorTextInputComponent extends Component {
    myQuill = React.createRef();
    formats = [
        'link',
        'bold',
        'clean'
    ];
    modules = {
        toolbar: {
            container: [
                ['link','bold','clean']
            ]
        }
    };
    render() {
        let { input } = this.props;
        return (
            <ReactQuill ref={this.myQuill}
                value={input.value || ''}
                style={{"max-width":"500px"}}
                theme="bubble"
                modules={this.modules}
                formats={this.formats}
                onChange={input.onChange}
            />
        );
    }
}

export default HtmlEditorTextInputComponent