import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { WEB_SITES } from "../../constants/routePaths";
import AddButtonComponent from "../buttons/addButtonComponent";
import DeleteComponent from "../layout/deleteComponent";
import SucsessComponent from "../layout/sucsessComponent";
import BreadcrumbComponent from "../layout/breadcrumbComponent";
// import AddLboxComponent from "../layout/AddLboxComponent";
import { getDomainsAction, searchAction } from "../../actions/domainActions";
import AddCategoryFormComponent from "../forms/addCategoryFormComponent";
import EditCategoryFormComponent from "../forms/editCategoryFormComponent";
import { allCategories } from "../../services/webServices";
import CategoryItemComponent from "../category/categoryItemComponent";


class WebsiteCategoriesComponent extends Component {
    state = {
        // lightBox: "close",
        allCategory: [],
        categoryLength: 0,
        type: "",
        change: false,
        sorted: false,
    }
    constructor(props) {
        super(props)
        this.state = {
            lightBox: "close",
            active: "",
            showWarning: false,
            deleteWarning: false,
            searchedText: "",
            isUp: false,
        }
        this.handleToggleClick = this.handleToggleClick.bind(this);
        this.handleToggleClickDelete = this.handleToggleClickDelete.bind(this);


    }
    orderType = "desc"
    orderName = "id"
    activeTap = (active) => {
        this.setState({
            active: active
        })
    }

    lightBox = (lightBox, deleteId) => {
        this.setState({
            lightBox: lightBox,
            deleteId
        })
        this.getCategories();
    }
    componentDidMount() {
        this.getCategories();
    }
    getCategories = (value) => {
        allCategories(this.props.match.params.id).then((result) => {

            if (result.data.success) {
                this.setState({
                    allCategory: result.data.success
                })
            }
        }).catch((err) => {
            console.log("sorted hata")
        })
    }
    onSearch = (searchedText) => {
        this.setState({
            searchedText
        })
        const filteredDomains = this.state.searchedText.length > 0 ? this.props.domains.filter(x => x.domain_name.toLowerCase().includes(searchedText.toLowerCase())) : this.props.domains
        const sortedDomains = this.orderType === "asc" ? (filteredDomains.sort((a, b) => a.domain_name > b.domain_name ? 1 : b.domain_name > a.domain_name ? -1 : 0)) : (filteredDomains.sort((a, b) => a.domain_name > b.domain_name ? 1 : b.domain_name > a.domain_name ? -1 : 0).reverse())
        this.props.dispatch(searchAction(sortedDomains))
    }
    filterSearch = (item) => {
        if (!this.state.searchedText || this.state.searchedText === "") {
            return item;
        }
        return (item.category_name_lang.tr.indexOf(this.state.searchedText) !== -1)
    }
    sortArr = (a, b) => {
        if (this.state.type === "lang") {
            return a.category_name_lang.tr.toLowerCase() > b.category_name_lang.tr.toLowerCase() ? (this.state.sorted?1:-1) : b.category_name_lang.tr.toLowerCase() > a.category_name_lang.tr.toLowerCase() ? (this.state.sorted?-1:1) : 0
        }
        if (this.state.type === "number") {
            return a.cookie_count > b.cookie_count ? (this.state.sorted?1:-1) : (b.cookie_count > a.cookie_count ? (this.state.sorted?-1:1) : 0)
        }

        if (this.state.type === "status") {
            return a.is_active > b.is_active ? (this.state.sorted?1:-1) : (b.is_active > a.is_active ? (this.state.sorted?-1:1) : 0)
        }
    }
    sorted = (item) => {
        this.setState({
            type: item,
            sorted: this.state.type===item ? !this.state.sorted : true
        })
    }
    handleToggleClickDelete() {
        this.setState(prevState => ({
            deleteWarning: !prevState.deleteWarning
        }));
        setTimeout(() => {
            this.setState(prevState => ({
                deleteWarning: !prevState.deleteWarning
            }));
        }, 3000)
    }

    handleToggleClick() {
        this.setState(prevState => ({
            showWarning: !prevState.showWarning
        }));
        setTimeout(() => {
            this.setState(prevState => ({
                showWarning: !prevState.showWarning
            }));
        }, 3000)
    }

    render() {
        let { allCategory, categoryLength } = this.state
        return (
            <div className="content-root website-categoriy-root">
                {
                    this.props.siteInfoData &&
                    <BreadcrumbComponent breadName="Websites" breadLink={WEB_SITES} subBreadName={this.props.siteInfoData.domain_name} />
                }

                <div className="content-wrapper">
                    <div className="content-container">
                        {/* TODO --> content-block--classı lightbox açılması addCategoriy clası gelmeli*/}
                        <div className={`content-block ${this.state.lightBox === "addCategoriy" ? "addCategoriy" : this.state.lightBox === "edit" ? "edit" : this.state.lightBox === "delete" ? "delete" : ""}`}>
                            <div className="table-container">
                                <div className="table-head-block">
                                    <div className="row-item">
                                        <div onClick={() => this.sorted("lang")} className={`row-td name-td ${this.state.type === "lang" && this.state.sorted === false ? "up" : ""}`}>
                                            <span >Category Name</span>
                                            <div className="search-block">
                                                <div className="input-item">
                                                    <input type="search" placeholder="Search" onChange={(e) => this.onSearch(e.target.value)} />
                                                    <i className="icon-item">
                                                        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="7.5" cy="7.5" r="4.5" stroke="#A4A1C0" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M11 11L13 13" stroke="#A4A1C0" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => this.sorted("number")} className={`row-td number-td ${this.state.type === "number" && this.state.sorted === false ? "up" : ""}`}>
                                            <span>Number of Cookies</span>
                                        </div>
                                        <div onClick={() => this.sorted("status")} className={`row-td status-td ${this.state.type === "status" && this.state.sorted === false ? "up" : ""}`}>
                                            <span >Status</span>
                                        </div>

                                        <div className="row-td button-td" onClick={() => this.lightBox("addCategoriy")}>
                                            <AddButtonComponent buttonName="New Category" />
                                        </div>


                                    </div>
                                </div>
                                {
                                    allCategory &&
                                    <div className="table-block">
                                        {
                                            [...allCategory.cookie_categories, ...allCategory.domain_cookie_categories].sort(this.sortArr).filter(this.filterSearch).map((ctr, key) => {
                                                return (
                                                    <CategoryItemComponent getCategories={this.getCategories} {...ctr} key={key} isStatic={ctr.category_name} lightBox={this.lightBox} />
                                                )
                                            })
                                        }

                                    </div>

                                }
                                <AddCategoryFormComponent lightBox={this.lightBox} domainId={this.props.match.params.id} getCategories={this.getCategories} />

                                {/* <AddLboxComponent  /> */}
                            </div>
                        </div>
                    </div>

                </div>


                <SucsessComponent warn={this.state.showWarning} />


                <DeleteComponent warn={this.state.deleteWarning} />


            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => ({
    domains: state.domainReducer.domains || [],
    filteredDomains: state.domainReducer.filteredDomains,
    siteInfoData: state.domainReducer.siteInfo && state.domainReducer.siteInfo[ownProps.match.params.id] ? state.domainReducer.siteInfo[ownProps.match.params.id] : null
})

export default connect(mapStateToProps)(WebsiteCategoriesComponent);