import React, {Component} from "react";
import { withRouter } from "react-router-dom";

class AddButtonComponent extends Component {
    goToLink = () => {
        if(this.props.link){
            this.props.history.push(this.props.link)
        }
    }
    render() {
        let {buttonName} = this.props;
        return (
            <div  className="btn-item" onClick={this.goToLink}>
                <i>
                    <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 5H5H9" stroke="white" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5 9V5L5 1" stroke="white" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </i>
                <span>{buttonName}</span>
            </div>
        );
    }
}

export default withRouter(AddButtonComponent);
