import React, {Component} from "react";
import AsideComponent from "../components/layout/asideComponent";
import AddNewComponentListWebsite from "../components/dashboard/addNewListWebsiteComponent";
import AddNewComponentList from "../components/dashboard/addNewListComponent";
import AddNewComponentListUser from "../components/dashboard/addNewListUserComponent";
import AddNewComponent from "../components/dashboard/addNewComponent";
import {getDomainsAction} from "../actions/domainActions";
import {connect} from "react-redux";
import { ADD_USERS_PAGE, ADD_WEB_SITES, POLICIES_ADD, USERS_PAGE, WEB_SITES } from "../constants/routePaths";
import {getDashboard} from "../services/webServices";
class Faz2DashboardPage extends Component {
   constructor(props) {
      super(props);
      props.dispatch(getDomainsAction())
   }
   state={
    users:[],
    policies:[],
    domains:[]

}
componentDidMount(){
    getDashboard().then((res)=>{
        if (res.data.success) {
            this.setState({
                    domains: res.data.success.domains,
                    policies: res.data.success.policies,
                    users: res.data.success.users
            })
        }
    })
}
    render() {
        let {users,policies,domains} = this.state;

        return (
            <div className="content-root dashboard-root">
                <div className="content-wrapper">
                    <div className="content-container">
                        {/* TODO --> active kısmında liste varsa gösterilir boş ise boş olan kısmı gösterilir */}
                        <div className="content-block add-new-wrapper ">
                            <AddNewComponent
                                title={"Add new site"}
                                text={"Vestibulum vestibulum dapibus scelerisque. Aliquam blandit dignissim mauris nec mollis. Nunc molestie bibendum ornare."}
                                buttonName={"New Site"}
                                link={WEB_SITES}
                                icon={<svg width="134" height="90" viewBox="0 0 134 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="1" y="1" width="132" height="88" rx="8" stroke="#C9C9D9" stroke-width="2"/>
                                    <rect x="11" y="33" width="32" height="46" rx="4" fill="#C9C9D9"/>
                                    <rect x="51" y="33" width="32" height="46" rx="4" fill="#C9C9D9"/>
                                    <rect x="91" y="33" width="32" height="46" rx="4" fill="#C9C9D9"/>
                                    <path d="M1 9C1 4.58172 4.58172 1 9 1H125C129.418 1 133 4.58172 133 9V23H1V9Z" stroke="#C9C9D9" stroke-width="2"/>
                                    <circle cx="12" cy="12" r="3" fill="#C9C9D9"/>
                                    <circle cx="22" cy="12" r="3" fill="#C9C9D9"/>
                                    <circle cx="32" cy="12" r="3" fill="#C9C9D9"/>
                                </svg>}
                                />
                            <AddNewComponent
                                title={"Policies"}
                                text={"Vestibulum vestibulum dapibus scelerisque. Aliquam blandit dignissim mauris nec mollis. Nunc molestie bibendum ornare."}
                                buttonName={"New Policy"}
                                link={POLICIES_ADD}
                                icon={<svg width="80" height="102" viewBox="0 0 80 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="8" width="72" height="88" rx="8" stroke="#C9C9D9" stroke-width="2"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M33 1C31.8954 1 31 1.89543 31 3V4C31 4.55228 30.5523 5 30 5H26C24.8954 5 24 5.89543 24 7V12C24 13.1046 24.8954 14 26 14H48C49.1046 14 50 13.1046 50 12V7C50 5.89543 49.1046 5 48 5H44C43.4477 5 43 4.55228 43 4V3C43 1.89543 42.1046 1 41 1H33Z" fill="#E0E0E9"/>
                                <path d="M32 3C32 2.44772 32.4477 2 33 2V0C31.3431 0 30 1.34315 30 3H32ZM32 4V3H30V4H32ZM26 6H30V4H26V6ZM25 7C25 6.44772 25.4477 6 26 6V4C24.3431 4 23 5.34315 23 7H25ZM25 12V7H23V12H25ZM26 13C25.4477 13 25 12.5523 25 12H23C23 13.6569 24.3431 15 26 15V13ZM48 13H26V15H48V13ZM49 12C49 12.5523 48.5523 13 48 13V15C49.6569 15 51 13.6569 51 12H49ZM49 7V12H51V7H49ZM48 6C48.5523 6 49 6.44771 49 7H51C51 5.34315 49.6569 4 48 4V6ZM44 6H48V4H44V6ZM42 3V4H44V3H42ZM41 2C41.5523 2 42 2.44772 42 3H44C44 1.34315 42.6569 0 41 0V2ZM33 2H41V0H33V2ZM44 4H42C42 5.10457 42.8954 6 44 6V4ZM30 4V6C31.1046 6 32 5.10457 32 4H30Z" fill="#C9C9D9"/>
                                <rect x="9" y="22" width="30" height="10" rx="4" fill="#C9C9D9"/>
                                <rect x="9" y="38" width="56" height="10" rx="4" fill="#C9C9D9"/>
                                <rect x="9" y="54" width="56" height="10" rx="4" fill="#C9C9D9"/>
                                <rect x="9" y="70" width="27" height="10" rx="4" fill="#C9C9D9"/>
                                <path d="M61.0123 100.995C61.0044 100.998 60.9956 100.998 60.9877 100.995C51.324 96.9536 44.66 87.9322 43.638 77.5075L43.0032 71.033C43.0013 71.0134 43.0127 70.9949 43.0309 70.9876C49.5605 68.3758 55.4324 64.2635 60.1046 59.0073L60.9152 58.0954C60.9603 58.0446 61.0397 58.0446 61.0848 58.0954L61.8954 59.0073C66.5676 64.2635 72.4395 68.3758 78.9691 70.9876C78.9873 70.9949 78.9987 71.0134 78.9968 71.033L78.362 77.5075C77.34 87.9321 70.676 96.9536 61.0123 100.995Z" fill="#E0E0E9" stroke="#C9C9D9" stroke-width="2"/>
                                <path d="M55 80L60 85L69 76" stroke="#C9C9D9" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>}
                                />
                            <AddNewComponent
                                title={"Users"}
                                text={"Vestibulum vestibulum dapibus scelerisque. Aliquam blandit dignissim mauris nec mollis. Nunc molestie bibendum ornare."}
                                buttonName={"Add User"}
                                link={USERS_PAGE}
                                icon={<svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="100" height="100" rx="8" stroke="#C9C9D9" stroke-width="2"/>
                                <circle cx="40" cy="28" r="14" fill="#C9C9D9" stroke="#C9C9D9" stroke-width="2"/>
                                <path d="M75 76H89C90.1046 76 91 75.1046 91 74V61.4785C91 55.0583 85.9467 49.7754 79.5328 49.4903L68.5 49" stroke="#C9C9D9" stroke-width="2" strokeLinecap="round"/>
                                <circle cx="72" cy="36" r="9" fill="#E0E0E9" stroke="#C9C9D9" stroke-width="2"/>
                                <path d="M12.6446 61.4407C12.8502 54.2437 18.1892 48.2312 25.3114 47.1761C35.0506 45.7332 44.9494 45.7332 54.6886 47.1761C61.8108 48.2312 67.1498 54.2437 67.3554 61.4407L67.98 83.2997C67.9914 83.6986 67.7015 84.0426 67.3064 84.0991C49.1941 86.6866 30.8059 86.6866 12.6936 84.0991C12.2985 84.0426 12.0086 83.6986 12.02 83.2997L12.6446 61.4407Z" fill="#C9C9D9" stroke="#C9C9D9" stroke-width="2"/>
                                </svg>
                                }
                                />
                        </div>
                        <div className="content-block add-new-list-wrapper active ">
                            <AddNewComponentListWebsite
                                title={"Websites"}
                                link={ADD_WEB_SITES}
                                buttonName={"New site"}
                                domains ={domains}

                            />
                            <AddNewComponentList
                                title={"Policies"}
                                link={POLICIES_ADD}
                                buttonName={"New Policy"}
                                policies = {policies}
                            />
                           <AddNewComponentListUser
                                link={ADD_USERS_PAGE}
                                title={"Users"}
                                buttonName={"New User"}
                                users={users}
                            />
                        </div>
                    </div>
                    <AsideComponent/>
                </div>

            </div>
        );
    }
}

export default connect() (Faz2DashboardPage);
