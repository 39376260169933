import React, { Component } from "react";
import AddButtonComponent from "../components/buttons/addButtonComponent";
import AsideComponent from "../components/layout/asideComponent";
import { NavLink } from "react-router-dom";
import { POLICIES_ADD, POLICIES_EDIT } from "../constants/routePaths";
import BreadcrumbComponent from "../components/layout/breadcrumbComponent";
import { deleteAccountPolicy, getAccountPolicies } from "../services/webServices";
import PolicyItemComponent from "../components/policies/policyItemComponent";
import ErrorComponent from "../components/layout/errorComponent";
import DeleteComponent from "../components/layout/deleteComponent";



class PoliciesPage extends Component {
    state = {
        policyDetail: false,
        policyId: 0,
        lightBox: "close",
        policyArr: []
    }

    componentDidMount() {
        this.getPolicies();
    }

    getPolicies = () => {
        getAccountPolicies().then(result => {
            if (result.data.success) {
                this.setState({
                    policyArr: result.data.success.accountPolicies
                })
            }
        }).catch((err)=>{
            console.log(err);
        })
    }


    lightBox = (lightBox, policy_id) => {
        this.setState({
            lightBox,
            policy_id
        })

    }

    pollicyTapId = (id) => {
        this.setState({
            policyDetail: !this.state.policyDetail,
            policyId: this.state.policyId = id
        })


    }

    deletePolicy = () => {
        deleteAccountPolicy({ policy_id: this.state.policy_id }).then((result) => {
            if (result.data.success) {
                this.lightBox("close",null)
                clearTimeout(this.successTimer)
                this.setState({
                    success:true
                })
                this.successTimer = setTimeout(()=>{
                    this.setState({
                        success:false
                    })
                },2000)
                this.getPolicies();
            } else {
                this.showErrorMsg();
            }
        }).catch((err)=>{
            this.showErrorMsg();
            console.log(err);
        })
    }
    showErrorMsg = () => {
        clearTimeout(this.timer)
        this.setState({
            error:true
        })
        this.timer = setTimeout(()=>{
            this.setState({
                error:false
            })
        },2000)
    }
    render() {
        return (
            <div className="content-root policies-root">
                <BreadcrumbComponent breadName="Policies" />
                <div className="content-wrapper">
                    <div className={`content-block ${this.state.lightBox === "open" ? "delete" : ""}`}>
                        <div className="head-item">
                            <h2>Policy Template Name</h2>
                            <NavLink exact to={POLICIES_ADD}>
                                <AddButtonComponent buttonName="New Policy" />

                            </NavLink>

                        </div>
                        <div className="policy-container">
                            {/* TODO -- plicy-block active geldiğind open menu gözükür default clası düzenleme durumunu pasifleştiri */}
                            {
                                this.state.policyArr && this.state.policyArr.map((obj, index) =>
                                    <PolicyItemComponent key={index} {...obj} lightBox={this.lightBox} />
                                )
                            }

                        </div>
                        {
                            this.state.policy_id &&
                            <div className="lightBox-root delete">
                                <div className="lightBox-wrapper">
                                    <div className="lightBox-container ">
                                        <div className="head-item">
                                            <h2 className="light-box-title">Delete Policy</h2>
                                            <div className="close-icon" onClick={() => this.lightBox("close")}>
                                                <i>
                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>

                                                </i>
                                            </div>
                                        </div>
                                        <div className="text-block">
                                            <p>Do you want to delete the policy?</p>
                                            <span>“Custom Policy {this.state.policyArr.find(item => item.id == this.state.policy_id).name}”</span>
                                        </div>
                                        <div className="btn-block">
                                            <div className="btn-item cancel" onClick={() => this.lightBox("close")}>
                                                <span>Cancel</span>
                                            </div>
                                            <div className="btn-item delete" onClick={this.deletePolicy}>
                                                <span>Delete</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <ErrorComponent warn={this.state.error}/>
                        <DeleteComponent warn={this.state.success}/>
                    </div>
                    <AsideComponent />

                </div>
            </div>
        );
    }
}

export default PoliciesPage;
