import React, { Component } from "react";
import { connect } from "react-redux";
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import { getDomainColors } from "../../services/webServices";
import { updateDomainColorsAction } from "../../actions/domainActions";
import ColorItemComponent from "./colorItemComponent";
import SucsessComponent from "../layout/sucsessComponent";
import SccriptAsideMenu from "../layout/sccriptAsideMenu";

class WebsiteDetailDesignComponent extends Component {
    state = {
        displayColorPicker: false,
        color: {
            r: '241',
            g: '112',
            b: '19',
            a: '1',
        },
        openBtn:"general"
    };
    domainColor={"--cctmc":"#1f85e3","--cctmchover":"#1B73C3","--cctmc2":"#E7F3FF","--cctbg":"#D1E9FE","--cctCookieText":"#000","--cctFullBg":"#fff","--cctChBoxItem":"#fff"};
    saved = false;

    echangeBtn = (btn) => {
        this.setState({
            openBtn:btn
        })

    }
    componentDidMount(){
        getDomainColors({domainId:this.props.domainId}).then((result)=>{
            if(result.data.success){
                this.domainColor = result.data.success.domain_colors ? JSON.parse(result.data.success.domain_colors):this.domainColor
                this.forceUpdate();
            }
        })
        this.props.changeColorTemplate(this.domainColor);
    }
    handleChangeComplete = (key, props) => {
        this.domainColor[key] = props.hex
        this.forceUpdate();
    }
    save = () => {
        clearTimeout(this.timeout)
        this.saved = true;
        this.forceUpdate();
        this.props.dispatch(updateDomainColorsAction({
            domainId: this.props.domainId,
            domainDetail:{
                domain_colors: JSON.stringify(this.domainColor)
            }
        }));
        this.timeout = setTimeout(()=>{
            this.saved = false;
            this.forceUpdate();
        },1000)
        this.props.changeColorTemplate(this.domainColor);
    }


    handleChange = (color) => {
        this.setState({ color: color.rgb })
    };

    saveBtnArry=[
        {
            "id":1,
            "name":"Button Text Color",
            "color_code":"#00000",
            "color_variable":"--cctSaveText"
        },
        {
            "id":2,
            "name":"Button Background Color",
            "color_code":"#fff",
            "color_variable":"--cctSaveBg"
        },
        {
            "id":3,
            "name":"Button Hover Color",
            "color_code":"#F0F0F0",
            "color_variable":"--cctSaveHover"
        },
        {
            "id":4,
            "name":"Button Border Color",
            "color_code":"#F0F0F0",
            "color_variable":"--cctSaveBorder"
        }
    ]
    hideBtnArry=[
        {
            "id":1,
            "name":"Button Text Color",
            "color_code":"#00000",
            "color_variable":"--cctHideText"
        },
        {
            "id":2,
            "name":"Button Background Color",
            "color_code":"#fff",
            "color_variable":"--cctHideBg"
        },
        {
            "id":3,
            "name":"Button Hover Color",
            "color_code":"#F0F0F0",
            "color_variable":"--cctHideHover"
        },
        {
            "id":4,
            "name":"Button Border Color",
            "color_code":"#F0F0F0",
            "color_variable":"--cctHideBorder"
        }
    ]
    acceptBtnArry=[
        {
            "id":1,
            "name":"Button Text Color",
            "color_code":"#00000",
            "color_variable":"--cctAcAllText"
        },
        {
            "id":2,
            "name":"Button Background Color",
            "color_code":"#fff",
            "color_variable":"--cctAcAllBg"
        },
        {
            "id":3,
            "name":"Button Hover Color",
            "color_code":"#F0F0F0",
            "color_variable":"--cctAcAllHover"
        },
        {
            "id":4,
            "name":"Button Border Color",
            "color_code":"#F0F0F0",
            "color_variable":"--cctAcAllBorder"
        }
    ]
    rejectBtnArry=[
        {
            "id":1,
            "name":"Button Text Color",
            "color_code":"#00000",
            "color_variable":"--cctRcAllText"
        },
        {
            "id":2,
            "name":"Button Background Color",
            "color_code":"#fff",
            "color_variable":"--cctRcAllBg"
        },
        {
            "id":3,
            "name":"Button Hover Color",
            "color_code":"#F0F0F0",
            "color_variable":"--cctRcAllHover"
        },
        {
            "id":4,
            "name":"Button Border Color",
            "color_code":"#F0F0F0",
            "color_variable":"--cctRcAllBorder"
        }
    ]
    cookieSetBtnArry=[
        {
            "id":1,
            "name":"Button Text Color",
            "color_code":"#00000",
            "color_variable":"--cctSettingText"
        },
        {
            "id":2,
            "name":"Button Background Color",
            "color_code":"#fff",
            "color_variable":"--cctSettingBg"
        },
        {
            "id":3,
            "name":"Button Hover Color",
            "color_code":"#F0F0F0",
            "color_variable":"--cctSettingHover"
        },
        {
            "id":4,
            "name":"Button Border Color",
            "color_code":"#F0F0F0",
            "color_variable":"--cctSettingBorder"
        }
    ]
    colorArr=[
        {
            "id":1,
            "name":"Button Text Color",
            "color_code":"#00000",
            "color_variable":"--cctmc2"
        },
        {
            "id":2,
            "name":"Button Background Color",
            "color_code":"#00000",
            "color_variable":"--cctmc"
        },
        {
            "id":3,
            "name":"Button Hover Color",
            "color_code":"#00000",
            "color_variable":"--cctmchover"
        },
        {
            "id":4,
            "name":"Button Border Color",
            "color_code":"#00000",
            "color_variable":"--cctbg"
        },
        {
            "id":5,
            "name":"Icon Background color",
            "color_code":"#00000",
            "color_variable":"--cctIcon"
        },
        {
            "id":6,
            "name":"Link Text Color",
            "color_code":"#00000",
            "color_variable":"--cctLink"
        },
        {
            "id":7,
            "name":"Link Text Hover Color",
            "color_code":"#00000",
            "color_variable":"--cctLinkHover"
        },
        {
            "id":8,
            "name":"Cookie Text Color",
            "color_code":"#00000",
            "color_variable":"--cctCookieText"
        },
        {
            "id":9,
            "name":"Cookie Background Color",
            "color_code":"#00000",
            "color_variable":"--cctFullBg"
        },
        {
            "id":10,
            "name":"Detail CheckBoxes Block Item Color",
            "color_code":"#00000",
            "color_variable":"--cctChBoxItem"
        }
    ]

    render() {

        const styles = reactCSS({
            'default': {
                color: {
                    width: '38px',
                    height: '22px',
                    borderRadius: '3px',
                    background: `rgba(${this.domainColor.r}, ${this.domainColor.g}, ${this.domainColor.b}, ${this.domainColor.a})`,
                },
                swatch: {
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
            },
        });
        return (
            <div>
                <main className="scripts-detail-root">
                    <SccriptAsideMenu echangeBtn={this.echangeBtn} openBtn={this.state.openBtn} />
                    {
                        this.state.openBtn === "general" ?

                        <div className="scripts-detail-wrapper">
                        <div className="color-detail-block">
                            {
                                this.colorArr.map((color,key)=>{
                                    return(
                                        <ColorItemComponent key={key} {...color} styles={styles} displayColorPicker={this.state.displayColorPicker} handleChangeComplete={this.handleChangeComplete} domainColor={this.domainColor} />

                                    )
                                })
                            }
                        
                           
                    
                        </div>
                        <div className="bottom-block" >
                            <div className="btn-item" onClick={this.save}>
                                <span>Save Changes</span>
                            </div>
                        </div>
                        <SucsessComponent warn={this.saved} />

                    </div>
                    :

                    this.state.openBtn === "save" ?
                    <div className="scripts-detail-wrapper">
                    <div className="color-detail-block">
                        {
                            this.saveBtnArry.map((color,key)=>{
                                return(
                                    <ColorItemComponent key={key} {...color} styles={styles} displayColorPicker={this.state.displayColorPicker} handleChangeComplete={this.handleChangeComplete} domainColor={this.domainColor} />

                                )
                            })
                        }
                    
                       
                
                    </div>
                    <div className="bottom-block" >
                        <div className="btn-item" onClick={this.save}>
                            <span>Save Changes</span>
                        </div>
                    </div>
                    <SucsessComponent warn={this.saved} />

                </div>
                :
                this.state.openBtn === "hide" ?
                <div className="scripts-detail-wrapper">
                <div className="color-detail-block">
                    {
                        this.hideBtnArry.map((color,key)=>{
                            return(
                                <ColorItemComponent key={key} {...color} styles={styles} displayColorPicker={this.state.displayColorPicker} handleChangeComplete={this.handleChangeComplete} domainColor={this.domainColor} />

                            )
                        })
                    }
                
                   
            
                </div>
                <div className="bottom-block" >
                    <div className="btn-item" onClick={this.save}>
                        <span>Save Changes</span>
                    </div>
                </div>
                <SucsessComponent warn={this.saved} />

            </div>
            :
                this.state.openBtn === "accept" ?
                <div className="scripts-detail-wrapper">
                <div className="color-detail-block">
                    {
                        this.acceptBtnArry.map((color,key)=>{
                            return(
                                <ColorItemComponent key={key} {...color} styles={styles} displayColorPicker={this.state.displayColorPicker} handleChangeComplete={this.handleChangeComplete} domainColor={this.domainColor} />

                            )
                        })
                    }
                
                   
            
                </div>
                <div className="bottom-block" >
                    <div className="btn-item" onClick={this.save}>
                        <span>Save Changes</span>
                    </div>
                </div>
                <SucsessComponent warn={this.saved} />

            </div>
            :
            this.state.openBtn === "reject" ?
            <div className="scripts-detail-wrapper">
            <div className="color-detail-block">
                {
                    this.rejectBtnArry.map((color,key)=>{
                        return(
                            <ColorItemComponent key={key} {...color} styles={styles} displayColorPicker={this.state.displayColorPicker} handleChangeComplete={this.handleChangeComplete} domainColor={this.domainColor} />

                        )
                    })
                }
            
               
        
            </div>
            <div className="bottom-block" >
                <div className="btn-item" onClick={this.save}>
                    <span>Save Changes</span>
                </div>
            </div>
            <SucsessComponent warn={this.saved} />

        </div>
        :
        this.state.openBtn === "cookieSet" ?
        <div className="scripts-detail-wrapper">
        <div className="color-detail-block">
            {
                this.cookieSetBtnArry.map((color,key)=>{
                    return(
                        <ColorItemComponent key={key} {...color} styles={styles} displayColorPicker={this.state.displayColorPicker} handleChangeComplete={this.handleChangeComplete} domainColor={this.domainColor} />

                    )
                })
            }
        
           
    
        </div>
        <div className="bottom-block" >
            <div className="btn-item" onClick={this.save}>
                <span>Save Changes</span>
            </div>
        </div>
        <SucsessComponent warn={this.saved} />

    </div>
    :
    <div/>
                    }
                   
                </main>
            </div>
        );
    }
}

export default connect()(WebsiteDetailDesignComponent);
