import React, {Component} from "react";
import {ASSETS} from "../constants/Paths-prod";
import {Field, Form} from "react-final-form";
import {required} from "../helper";
import TextInputComponent from "../components/formFields/textInputComponent";
import RecaptchaFieldComponent from "../components/formFields/recaptchaFieldComponent";
import {NavLink} from "react-router-dom";
import SlideComponent from "../components/slideComponent";
import CoverComponent from "../components/common/coverComponent";
import LogoComponent from "../components/common/logoComponent";


class faz2resetpassword2 extends Component {


    render() {


        return (
            <div className="login-root reset-pass2-root">

                <div className="login-wrapper">
                    <div className="login-left-wrapper">
                        <LogoComponent/>
                        <div className="login-content-container">
                            <div className="login-container">
                                <div className="text-block">
                                    <div className="text-item short">
                                        <p>Password reset link</p>
                                        <span>ahmetornekadam@honda.com.tr</span>
                                        <p>sent to your address.</p>
                                    </div>
                                    <div className="text-item">
                                        <p>Please check the inbox of your e-mail address.</p>
                                    </div>
                                </div>
                                <div className="bottom-btn-block">
                                    <div className="text-item">
                                        <span>I have an account</span>
                                    </div>
                                    {/* <NavLink exact to={REGISTER_PAGE}>
                                        <div className="btn-item white-btn">
                                            <span>Register</span>
                                        </div>
                                    </NavLink> */}

                                </div>

                            </div>
                        </div>
                    </div>

                    <CoverComponent/>
                </div>

            </div>



        );
    }
}

export default faz2resetpassword2;
