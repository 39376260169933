import React, { Component } from 'react';

class RadioCheckboxComponent extends Component {
    render() {
        let {input,options} = this.props;
        return(
            <div className="check-box-block general">
                {
                    options.map((optionItem,key)=>
                        <div key={key} className={"container "+(optionItem.value==input.value?"active":"")} onClick={()=>{
                            input.onChange(optionItem.value)
                        }}>
                            <input onChange={()=>{}} checked={optionItem.value==input.value} autoComplete="off" type="radio" name={input.name} />
                            <label htmlFor="">{optionItem.name}</label>
                        </div>
                    )

                }
            </div>
        )
    }
}

export default RadioCheckboxComponent;
