import React, {Component} from 'react';

class RadioInputComponent extends Component {
    render() {
        let {input,options} = this.props;
        return(
            <div className="radio-group-block">
                {
                    options.map((optionItem,key)=>
                        <div key={key} className={"radio-group-item "+(optionItem.value==input.value?"active":"")} onClick={()=>{
                            input.onChange(optionItem.value)
                        }}>
                            <input onChange={()=>{}} checked={optionItem.value==input.value} autoComplete="off" type="radio" name={input.name} />
                            <label htmlFor="">{optionItem.name}</label>
                        </div>
                    )
                    
                }
            </div>
        )
    }
}

export default RadioInputComponent;
