import React, { Component } from 'react';
//import { updateDomainCookieAction } from '../../actions/domainActions';

class FacebookBoxComponent extends Component {
    state = {
        isOpen:false,
        isOpenDot:false,
        editLigtbox:false,
        deleteLightbox: false,
        IsSuccess:false
        
    }
    dateArrar =[]
    facebook =[]
    componentDidMount() {
        this.props.cookie && this.props.cookie.filter(item => item.third_party == "facebook" ? this.dateArrar.push(item) : item)
        this.setTextCategory()
    }

    setTextCategory=()=>{
        this.dateArrar && this.dateArrar.forEach(item => {
            let categoryName = this.props.allCategories?.find(ctg => ctg.id === item.category_id)?.category_name
         
            if (item.policy_value ==="true" && this.facebook.filter(x=>x === categoryName).length === 0) {                       
                this.facebook.push(categoryName)
            }
        }
        )

        this.forceUpdate()
    }
    changeCookieCategory = (categoryItem,third,policy) => {
        let categoryName = this.props.allCategories?.find(ctg => ctg.id === categoryItem.id)?.category_name
    
        if(this.state.isOpen && categoryItem.id!==this.props.cookie.cookie_category_id){
            
            let params ={
                category_id : categoryItem.id,
                third_party : third,
                policy_value : policy,
                isDeleted: false
            }
            this.props.updateValFacebbok(params)
            if(this.facebook.filter(x=>x === categoryName).length === 0){
                this.facebook.push(categoryName)
                params.isDeleted = false
            } else{
                let index = this.facebook.indexOf(categoryName);
                if (index > -1) { 
                    this.facebook.splice(index, 1); 
                    params.isDeleted = true
                  }
            }
        }
        
    }
    toggleCategoryMenu = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    render() {
        let {allCategories,cookie} = this.props
        //console.log("allCategories",allCategories);
        
        return (
            <div className='parties-table-container'>
                <div className="parties-table-title-block">
                    <div className="title-item name-td">
                        <span>Name</span>
                    </div>
                    <div className="title-item category-td">
                        <span>Category</span>
                    </div>
                </div>
                <div className="parties-table-item-block">
                    <div className="table-item name-td">
                        <span>Consent Mode</span>
                    </div>
                    <div className="table-item category-td">
                    <div className={`select-block ${this.state.isOpen?"opened":""}`} ref={this.optionRoot} onClick={this.toggleCategoryMenu}>
                    {
                            this.facebook.map((item,key)=>{
                                return (
                                    <span key={key} >{item && key > 0 ? ", ":""}{item}  </span>
                                )
                            })
                         }
 
                        <ul className="category-list">
                        {
                                    allCategories && allCategories.length > 0 && allCategories.filter(item=> item.id !== cookie.category_id ).map((categoryItem,key) =>
                                    <li onClick={()=>{this.changeCookieCategory(categoryItem,"facebook","true")}} key={key} className="category-item" >
                                        {categoryItem && categoryItem.category_name ? categoryItem.category_name : "Bilinmeyen"}
                                    </li>
                                    )
                                }
      
                            
                        </ul>
                    </div>
                    </div>
                </div>
     
            </div>
        );
    }
}

export default FacebookBoxComponent;
