import React, { Component } from "react";
import { Field, Form } from "react-final-form";
import TextInputComponent from "../../components/formFields/textInputComponent";
import SelectBoxComponent from "../../components/formFields/selectBoxComponent";
import { addNewUserToAccount ,getRoles} from "../../services/webServices";
import ErrorComponent from "../../components/layout/errorComponent";
import {dataStandardized} from "../../helper/index";

const validate = (values) => {
    const errors = {};
    if (!values || !values.email || values.email === "" || !/^([a-zA-Z0-9!#$%&'*-`{|}~_.]{1}[a-zA-Z0-9!#$%&'*-`{|}~_.]*)(@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6})$/i.test(values.email)) {
        errors.email = "Email giriniz.";
    }
    if (!values || !values.role || values.role === "") {
        errors.role = "Role giriniz.";
    }
    return errors;
};

class CreateNewUserContainer extends Component {
    state = {
        isLoading: false,
        didSelectOpen: false,
        error:false,
        roles:[],
        mailDomains: []
    }

    componentDidMount(){
        getRoles().then((result)=>{
            if(result.data.success){
                this.setState({
                    roles:result.data.success
                })
            }
        })
        this.props.users.map(x=> this.state.mailDomains.includes(x.email.split("@")[1]) ? "" : this.state.mailDomains.push(x.email.split("@")[1]))
    }
    submit = (values) => {
        this.setState({
            isLoading: true
        })
        addNewUserToAccount({email:values.email,role_id:values.role}).then((result)=>{
            if(result.data.success){
                this.props.userAdded();
            }else{
                this.setState({
                    error:true
                })
                this.hideErrorMsg();
            }
        }).catch((err)=>{
            this.setState({
                error:true
            })
            this.hideErrorMsg();
        })

    }
    hideErrorMsg = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(()=>{
            this.setState({
                error:false
            })
        },2000)
    }
    toggleSelect = () => {
        this.setState({
            didSelectOpen: !this.state.didSelectOpen
        })
    }
    render() {
        let {users} = this.props;

        return (
            <div className="lightBox-root user">
                <div className="lightBox-wrapper">

                    <Form onSubmit={this.submit}
                        validate={validate}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} className="lightBox-container">
                                <div className="head-item">
                                    <h2 className="light-box-title">Add User</h2>
                                    <div className="close-icon" onClick={() => this.props.lightBox("close")}>
                                        <i>
                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </i>
                                    </div>
                                </div>
                                <div className="user-block">
                                   
                                   
                                        <Field
                                            name={"email"}
                                            type="text"
                                            label={"E-mail*"}
                                            component={TextInputComponent}
                                            placeholder={"email"}
                                            isCreateUser={true}
                                            mailDomains={this.state.mailDomains}

                                        />
                                        <Field
                                            name={"role"}
                                            type="text"
                                            label={"Role*"}
                                            component={SelectBoxComponent}
                                            placeholder={"role"}
                                            options={dataStandardized(this.state.roles,"id","role")}



                                        />
                                  
                                </div>
                                <div className="btn-block">
                                        <button className="btn-item cancel" onClick={() => this.props.lightBox("close")}>
                                            <span>Cancel</span>
                                        </button>
                                        <button type="submit" className="btn-item">
                                            <span>Add New User</span>
                                        </button>
                                    </div>
                            </form>
                        )}
                    >
                    </Form>
       

            </div>
            <ErrorComponent warn={this.state.error} />
            
            </div >

        )
    }
};

export default CreateNewUserContainer;