import React, { Component } from "react";
import AsideComponent from "../layout/asideComponent";
import AddButtonComponent from "../buttons/addButtonComponent";
import { WEB_SITES } from "../../constants/routePaths";
import { connect } from "react-redux";
import BreadcrumbComponent from "../layout/breadcrumbComponent";
import { getAccountPolicies, updateDomainPolicy } from "../../services/webServices";
import SucsessComponent from "../layout/sucsessComponent";
import ErrorComponent from "../layout/errorComponent";
import PolicyAudienceComponent from "../policies/policyAudienceComponent";
import { ASSETS } from "../../constants/Paths";
import PolicyDeleteAudienceLbxComponent from "../policies/policyDeleteAudienceLbxComponent";
import PolicyDefaultAudienceLbxComponent from "../policies/policyDefaultAudienceLbxComponent";

class WebsitePolicyComponent extends Component {
    state = {
        selectedLanguage: "tr",
        didSelectOpen: false,
        success: false,
        error: false,
        isDefaultOpen: false
    }
    componentDidMount() {
        this.getPolicies();
    }
    getPolicies = () => {
        getAccountPolicies(this.props.match.params.id).then(result => {
            if (result.data.success) {
                console.log("RESSS----", result.data);
                this.setState({
                    policyArr: result.data.success.accountPolicies,
                    selectedPolicy: result.data.status,
                    domainArr: result.data.success.domianPolicies
                })
                this.forceUpdate()
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    toggleSelect = () => {
        this.setState({
            didSelectOpen: !this.state.didSelectOpen
        })
    }
    toggleDefault = () => {
        this.setState({
            isDefaultOpen: !this.state.isDefaultOpen
        })
    }
    setTimer = () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                success: false,
                error: false
            })
        }, 2000)
    }
    updateWebsitePolicy = (selectedPolicy) => {
        updateDomainPolicy(selectedPolicy, this.props.match.params.id).then(result => {
            if (result.data.success) {
                this.setState({
                    success: true,
                    selectedPolicy: selectedPolicy
                })
                this.toggleDefault();
            } else {
                this.setState({
                    error: true
                })
            }
            this.setTimer();
        }).catch(err => {
            this.setState({
                error: true
            })
            this.setTimer();
            console.log("err", err);
        })
    }
    render() {
        let initialOption = this.state.policyArr?.find(item => item.id == this.state.selectedPolicy);
        return (
            <div className="content-root website-policy-root">
                {
                    this.props.siteInfoData &&
                    <BreadcrumbComponent breadName="Websites" breadLink={WEB_SITES} subBreadName={this.props.siteInfoData.domain_name} />
                }

                <div className="content-wrapper">
                    <div className="content-container">
                        <div className="content-block">
                            <div className="policy-container gdpr">
                                <div className="select-container">
                                    <div className="policy-block">
                                        <div className="text-block">
                                            <span>Default audiences</span>
                                            <p className="text-desc-item" >  Selected policy template will be valid for all audiences other than your choices below.</p>
                                        </div>

                                        {
                                            this.state.policyArr &&
                                            <div className="default-block">
                                                <span>{this.state.policyArr.find(item => item.id == this.state.selectedPolicy) ? this.state.policyArr.find(item => item.id == this.state.selectedPolicy).name : ""}</span>
                                            </div>
                                        }
                                    </div>
                                    <div className="edit-block" onClick={this.toggleDefault}>
                                        <span>Edit default</span>
                                        <div className='edit-item' >
                                            <img src={ASSETS + "img/edit.svg"} alt="edit" />
                                        </div>
                                    </div>
                                </div>
                                <PolicyAudienceComponent domainArr={this.state.domainArr} policyArr={this.state.policyArr} getPolicies={this.getPolicies} />
                                {
                                    this.state.isDefaultOpen &&
                                    <PolicyDefaultAudienceLbxComponent isDefaultOpen={this.state.isDefaultOpen} toggleDefault={this.toggleDefault} policyArr={this.state.policyArr} initialOption={initialOption} updateWebsitePolicy={this.updateWebsitePolicy}/>
                                }

                                

                                {/* <div className="btn-block">
                                    <button className="btn-item save" onClick={this.updateWebsitePolicy} >Save</button>
                                </div> */}
                                <SucsessComponent warn={this.state.success} message={"Policy successfuly updated."} />
                                <ErrorComponent warn={this.state.error} />
                            </div>
                        </div>
                    </div>
                    <AsideComponent />
                </div>

            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    siteInfoData: state.domainReducer.siteInfo && state.domainReducer.siteInfo[ownProps.match.params.id] ? state.domainReducer.siteInfo[ownProps.match.params.id] : null
})
export default connect(mapStateToProps)(WebsitePolicyComponent);
