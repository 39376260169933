import moment from 'moment';

export const customStyles = {
    control: (base, {isFocused}) => ({
        ...base,
        fontFamily: '"Open Sans", arial, helvetica, sans-serif',
        fontSize: "16px",
        fontWeight: 600,
        fontColor: "black",
        border: "1px solid #BBBBBB",
        cursor: 'text',
        borderRadius: 8,
        padding: "0 0 0 14px",
        backgroundColor: "transparent",
        outline: isFocused && "none",
        boxShadow: "unset",
        height: "56px",
        '&:hover': {
            borderColor: '#000000',
            boxShadow: "unset",
        },
        '&:active': {
            borderColor: '#000000',
            boxShadow: "unset",
        }
    }),
    container: (base, {isFocused}) => ({
        ...base,
        margin: "12px 0 0"
    }),
    option: (styles, {isFocused}) => ({
        ...styles,
        cursor: 'pointer',
        backgroundColor: 'white !important',
        color: isFocused ? 'black' : "#AEAEAE",
        // lineHeight: 2,
        fontFamily: '"Open Sans", arial, helvetica, sans-serif',
        fontWeight: 600,
        fontSize: 16,
        padding: "16px 0"
    }),
    noOptionsMessage: (base, state) => ({
        ...base,
        color: '#000',
        fontSize: '14px',
        fontFamily: '"Open Sans", arial, helvetica, sans-serif',
    }),
    indicatorSeparator: styles => ({
        ...styles,
        width: 0
    }),
    input: styles => ({
        ...styles,
        color: '#000',
        fontFamily: '"Open Sans", arial, helvetica, sans-serif',
        fontWeight: 600,
        margin: 0,
        paddingBottom: 0,
        paddingTop: 0,
    }),
    menu: styles => ({
        ...styles,
        marginTop: 1,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12);',
        borderRadius: "0 0 8px 8px",
        padding: "14px 30px",
        top: "48px !important",
        border: "1px solid #000",
        backgroundColor: 'white',
        zIndex: 5
    }),
    list: styles => ({
        ...styles,
        border: "1px solid #000"
    }),
    menuList: styles => ({
        ...styles,
        zIndex: 12,
    }),
    singleValue: styles => ({
        ...styles,
        color: 'black',
        lineHeight: 2
    }),
    
};

export const dataStandardized = (unStandardData, firstParam, secParam, secondNameParam) => {
    return [...unStandardData.map((x) => {
        return { value: x[firstParam], text: x[secParam] ? x[secParam].toString().trim()+(secondNameParam?" - "+x[secondNameParam]:"")  :null }
    })];
};

export const required = value => value ? undefined : 'Required'

export const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined


export const maxLength40 = value =>
    !value || value.trim() == "" ? "Required" : (value.length > 40 ? `Must be ${40} characters or less` : undefined)

export const checkYoutubeLink = (url) => {
    if (url){
        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/)([^#\&\?]*).*/;
        let match = url.match(regExp);

        return match ? undefined : "Link is not Youtube";
    }
}

export const getFormattedDate = (selectedDate) => {
    let newDate = new Date(Date.parse(selectedDate))

    let curr_date = (newDate.getDate() > 9) ? newDate.getDate() : ("0"+ newDate.getDate());
    let curr_month = (newDate.getMonth() + 1) > 9 ? (newDate.getMonth() + 1) : "0" + (newDate.getMonth()+1); //Months are zero based
    let curr_year = newDate.getFullYear() ;
    let curr_hour = (newDate.getHours() > 9) ? newDate.getHours() : ("0"+ newDate.getHours());
    let curr_mins = (newDate.getMinutes() > 9) ? newDate.getMinutes() : ("0"+ newDate.getMinutes())
    let curr_secs = (newDate.getSeconds() > 9) ? newDate.getSeconds() : ("0"+ newDate.getSeconds())

    return `${curr_date}.${curr_month}.${curr_year} saat: ${curr_hour}:${curr_mins}:${curr_secs}`


}

export const array_move=(arr, old_index, new_index)=> {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
}

export const focusLast = (element) => {
    let range, selection;
    if (document.createRange)
    {
        range = document.createRange();//Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(element.editingArea.getElementsByClassName('ql-editor')[0]);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection();//get the selection object (allows you to change selection)
        selection.removeAllRanges();//remove any selections already made
        selection.addRange(range);//make the range you have just created the visible selection
    }
    else if (document.selection)
    {
        range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
        range.moveToElementText(element.editingArea.getElementsByClassName('ql-editor')[0]);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        range.select();//Select the range (make it the visible selection
    }
};
export const getOffset = (el, topElement) => {
    let rect = el.getBoundingClientRect(),
        scrollLeft = topElement.offsetWidth || document.documentElement.scrollLeft,
        scrollTop = document.documentElement.scrollTop;
    return {top: rect.top + scrollTop - 182, left: rect.left + scrollLeft}
};
export const getExtension = (filename) => {
    let parts = filename.split('.');
    return parts[parts.length - 1];
};
export const isImage = (filename) => {
    let ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'jpg':
        case 'gif':
        case 'png':
            return true;
    }
    return false;
};

export const turkishToLower = (text) => {
    let letters = {
        "İ": "i",
        "ı": "i",
        "I": "i",
        "ş": "s",
        "Ş": "s",
        "ğ": "g",
        "Ğ": "g",
        "ü": "u",
        "Ü": "u",
        "ö": "o",
        "Ö": "o",
        "Ç": "c",
        "ç": "c"
    };
    text = text.replace(/(([İIŞĞÜÇÖ]))+/g, function (letter) {
        let text = "";
        letter.split("").map(item => {
            text += letters[item]
        });
        return text;
    });
    return text.toLowerCase();
};
export const generateString = (length) => {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
};
export const checkArrayLastElement = (arr, obj) => {
    if (arr[arr.length - 1] === obj && !arr[arr.length - 1].subItems) {
        return true;
    } else {
        if (arr[arr.length - 1] && arr[arr.length - 1].subItems) {
            return checkArrayLastElement(arr[arr.length - 1].subItems, obj);
        } else {
            return false;
        }
    }
};
export const diffTwoDate = (firstDate, secondDate) => {
    let seconds = moment(secondDate).diff(moment(firstDate), "seconds") % 60;
    let minutes = moment(secondDate).diff(moment(firstDate), "minutes") % 24;
    let hours = moment(secondDate).diff(moment(firstDate), "hours");
    return `${hours.toString().length < 2 ? "0" + hours : hours}.${minutes.toString().length < 2 ? "0" + minutes : minutes}.${seconds.toString().length < 2 ? "0" + seconds : seconds}`
};
export const getElementPosition = (el) => {
    var xPos = 0;
    var yPos = 0;
    while (el) {
        if (el.tagName == "BODY") {
            xPos += (el.offsetLeft + el.clientLeft);
            yPos += (el.offsetTop + el.clientTop);
        } else {
            // for all other non-BODY elements
            xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
            yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }

        el = el.offsetParent;
    }
    return {
        x: xPos,
        y: yPos
    };
};
export const checkValidJSON = (text) => {
    return !!(text && /^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, '')));
};