import React, { Component } from 'react';
import { Field, Form } from "react-final-form";
import ButtonBlockComponent from "../buttons/buttonBlockComponent";
import HtmlEditorTextInputComponent from '../formFields/htmlEditorTextInput';
import LanguageTextInputComponent from "../formFields/languageTextInputComponent";
import TextAreaInputComponent from "../formFields/textAreaInputComponent";
import sanitizeHtml from "sanitize-html"

class InterfaceLanguageComponent extends Component {
    state = {
        onlyMissingTransation: false
    }
    toggleOnlyMissing = () => {
        this.setState({
            onlyMissingTransation: !this.state.onlyMissingTransation
        })
    }
    render() {
        let { selectedLanguage, domainTexts, langueArr } = this.props;
        return (
            <>
                <div className="lang-table-wrapper interface">
                    <div className="lang-head-container">

                        <div className="check-box">
                            <input type="checkbox" onChange={this.toggleOnlyMissing} checked={this.state.onlyMissingTransation ? "checked" : ""} />
                            <label htmlFor="translations">Only missing translations</label>
                        </div>
                    </div>

                    <div className="description-table-block">
                        <div className="description-table-head-item">
                            <div className="row-th name-th">
                                <span>Element name</span>
                                <i className="icon-item">
                                    <svg width={16} height={16} viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7.5" cy="7.5" r="4.5" stroke="#A4A1C0"
                                            stroke-width={2} strokeLinecap="round"
                                            strokeLinejoin="round" />
                                        <path d="M11 11L13 13" stroke="#A4A1C0" stroke-width={2}
                                            strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </i>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&
                                    <div className="row-th lang-th input-item" key={key}>
                                        {/* <input type="text" placeholder="Turkish" /> */}
                                        <span>{languageItem.language.language_name} {languageItem.is_default ? "(Default)" : ""}</span>
                                    </div>
                                )
                            }
                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.title_lang || !JSON.parse(domainTexts.title_lang)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Title</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field
                                                    name={`interface.title_lang.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.title_lang ? JSON.parse(domainTexts.title_lang)[languageItem.language.language_code] : null}
                                                    component={LanguageTextInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.title_lang ? JSON.parse(domainTexts.title_lang)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }
                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.description_lang || !JSON.parse(domainTexts.description_lang)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Description</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.description_lang.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.description_lang ? JSON.parse(domainTexts.description_lang)[languageItem.language.language_code] : null}
                                                    component={HtmlEditorTextInputComponent}
                                                />
                                                :
                                                <span dangerouslySetInnerHTML={{__html:domainTexts.description_lang ? sanitizeHtml(JSON.parse(domainTexts.description_lang)[languageItem.language.language_code]) : ""}}></span>
                                        }
                                    </div>

                                )
                            }
                        </div>
                        {/* <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.about_cookie_title_lang || !JSON.parse(domainTexts.about_cookie_title_lang)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>About cookie title</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.about_cookie_title_lang.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.about_cookie_title_lang ? JSON.parse(domainTexts.about_cookie_title_lang)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.about_cookie_title_lang ? JSON.parse(domainTexts.about_cookie_title_lang)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }
                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.about_cookie_description_lang || !JSON.parse(domainTexts.about_cookie_description_lang)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>About cookie description</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.about_cookie_description_lang.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.about_cookie_description_lang ? JSON.parse(domainTexts.about_cookie_description_lang)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.about_cookie_description_lang ? JSON.parse(domainTexts.about_cookie_description_lang)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }

                        </div> */}
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.cookie_alert_text || !JSON.parse(domainTexts.cookie_alert_text)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Cookie alert description</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&

                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.cookie_alert_text.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.cookie_alert_text ? JSON.parse(domainTexts.cookie_alert_text)[languageItem.language.language_code] : null}
                                                    component={HtmlEditorTextInputComponent}
                                                />
                                                :
                                                <span dangerouslySetInnerHTML={{__html:domainTexts.cookie_alert_text ? JSON.parse(domainTexts.cookie_alert_text)[languageItem.language.language_code] : ""}}></span>
                                        }
                                    </div>

                                )
                            }

                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.cookie_settings || !JSON.parse(domainTexts.cookie_settings)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Cookie settings button</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&

                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.cookie_settings.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.cookie_settings ? JSON.parse(domainTexts.cookie_settings)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.cookie_settings ? JSON.parse(domainTexts.cookie_settings)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }

                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.reject_all || !JSON.parse(domainTexts.reject_all)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Reject all button</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&

                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.reject_all.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.reject_all ? JSON.parse(domainTexts.reject_all)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.reject_all ? JSON.parse(domainTexts.reject_all)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }

                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.accept_cookies || !JSON.parse(domainTexts.accept_cookies)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Accept cookies button</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&

                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.accept_cookies.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.accept_cookies ? JSON.parse(domainTexts.accept_cookies)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.accept_cookies ? JSON.parse(domainTexts.accept_cookies)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }

                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.save_and_close || !JSON.parse(domainTexts.save_and_close)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Save and close button</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&

                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.save_and_close.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.save_and_close ? JSON.parse(domainTexts.save_and_close)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.save_and_close ? JSON.parse(domainTexts.save_and_close)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }

                        </div>
                        {/* <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.select_all || !JSON.parse(domainTexts.select_all)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Select all button</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.select_all.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.select_all ? JSON.parse(domainTexts.select_all)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.select_all ? JSON.parse(domainTexts.select_all)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }

                        </div> */}
                        {/* <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.cookie_declation || !JSON.parse(domainTexts.cookie_declation)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Cookie declation button</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.cookie_declation.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.ok ? JSON.parse(domainTexts.cookie_declation)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.cookie_declation ? JSON.parse(domainTexts.cookie_declation)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }

                        </div> */}
                        {/* <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.last_update_text || !JSON.parse(domainTexts.last_update_text)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Last update text</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.last_update_text.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.ok ? JSON.parse(domainTexts.last_update_text)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.last_update_text ? JSON.parse(domainTexts.last_update_text)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }

                        </div> */}
                        {/* <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.ok || !JSON.parse(domainTexts.ok)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Ok button</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.ok.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.ok ? JSON.parse(domainTexts.ok)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.ok ? JSON.parse(domainTexts.ok)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }

                        </div> */}
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.hide_details || !JSON.parse(domainTexts.hide_details)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Hide details button</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&

                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.hide_details.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.hide_details ? JSON.parse(domainTexts.hide_details)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.hide_details ? JSON.parse(domainTexts.hide_details)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }

                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.show_details || !JSON.parse(domainTexts.show_details)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Show details button</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&

                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.show_details.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.show_details ? JSON.parse(domainTexts.show_details)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.show_details ? JSON.parse(domainTexts.show_details)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }

                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.export_data_title || !JSON.parse(domainTexts.export_data_title)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Data residency title</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&

                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.export_data_title.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.export_data_title ? JSON.parse(domainTexts.export_data_title)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.export_data_title ? JSON.parse(domainTexts.export_data_title)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }

                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.export_data_description || !JSON.parse(domainTexts.export_data_description)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Data residency description</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&

                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.export_data_description.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.export_data_description ? JSON.parse(domainTexts.export_data_description)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.export_data_description ? JSON.parse(domainTexts.export_data_description)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }

                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !domainTexts.must_text || !JSON.parse(domainTexts.must_text)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Must Text</span>
                            </div>
                            {
                                
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field

                                                    name={`interface.must_text.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={domainTexts.must_text ? JSON.parse(domainTexts.must_text)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{domainTexts.must_text ? JSON.parse(domainTexts.must_text)[languageItem.language.language_code] : ""}</span>
                                        }
                                    </div>

                                )
                            }

                        </div>
                    </div>

                </div>
            </>

        )
    }
}

export default InterfaceLanguageComponent;