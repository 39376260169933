import React, { Component, createRef } from 'react';
import { CDNURL } from '../../constants/Paths';
import { WEB_SITE_POLICY_AUDIENCE } from '../../constants/routePaths';
import {matchPath} from "react-router-dom";
import { getSiteInfoAction } from '../../actions/domainActions';
import { connect } from 'react-redux';

class PolicyScriptLbxComponent extends Component {
    text = React.createRef()
    state={
        siteInfoData: ""
    }
    copyText = () => {
        let copyText = this.text.current.innerText;
        navigator.clipboard.writeText(copyText);
    }

    componentDidMount() {
        if(typeof window !== 'undefined'){
            let urlParams = matchPath(window.location.pathname, {
                path: WEB_SITE_POLICY_AUDIENCE,
                exact: false,
                strict: false
            });
            this.getData(urlParams.params.id)
        }
    }
    
    getData=(param)=>{
        this.props.dispatch(getSiteInfoAction(param,(result)=>{
            if(result){
                this.setState({
                    siteInfoData:result
                })
            }
        }));
    }

    render() {
        let { toggleScriptLbx, isScriptOpen, indexKey } = this.props;
        return (
            <div className={`lightBox-root ${isScriptOpen ? "audience" : ""}`}>
                <div className="lightBox-wrapper">
                    <div className="lightBox-container">
                        <div className="head-item">
                            <h2 className='light-box-title'>Script</h2>
                            <div className="close-icon" onClick={toggleScriptLbx}>
                                <i>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                </i>
                            </div>
                        </div>
                        <div className="script-block">
                            <div className="script-item">
                                <span ref={this.text}>
                                    {`<script id="cookie-bundle" src='${CDNURL}${this.state.siteInfoData?this.state.siteInfoData.secret_key:""}&region=${indexKey}' type="text/javascript"></script>`}
                                </span>
                            </div>
                        </div>
                        <div className="btn-block">
                            <div className="btn-item cancel" onClick={toggleScriptLbx}>
                                <span>Cancel</span>
                            </div>
                            <button className="btn-item" onClick={this.copyText}>
                                <span>Copy Script</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(PolicyScriptLbxComponent);