import React, { Component } from 'react';

class StatisticDetailComponent extends Component {

    details1 = [
        {
            title : "Sessions tracked:",
            value: 11,
            explanation : "Each session begins when a user visits your site, and terminates once the browser tab is closed. Repeated user visits will increase your session numbers."
        },
        {
            title : "Sessions with cookies accepted:",
            value: 5,
            explanation : "This is the number of massa hendrerit habitant et dignissim vel sit gravida id. Mus tortor et sed vitae maecenas gravida neque pretium."
        },
        {
            title: "Percentage of session with cookies accepted:",
            value: "59.39%",

        }
    ]

    details2 = [
        {
            title : "Cookie settings screen opened:",
            value: 9,
            explanation : "Number of sesiions where the Cookie Settings screen was viewed by a user. Triggers: User clicikng on the Floating Button, Settings link cliked from the Cookie Banner, or user clkicking on a custom link (gdpr_8345984)"
        },
        {
            title : "Clicked “Accept all” button:",
            value: 7,
            explanation : "This statistic shows the leo maecenas gravida neque pretium. Massa hendrerit habitant et dignissim vel sit gravida id. Mus tortor et sed vitae."
        }
    ]

    render() {
        return (
            <div className='statistic-detail-container'>
                <div className="stat-detail-block statistic-item">
                    {
                        this.details1.map((item,key) => 
                           <div key={key} className="stat-detail-item">
                              <span>{item.title}</span>
                              <span>{item.value}</span>
                              <p>{item.explanation ? item.explanation : ""}</p>
                           </div>
                        )
                    }
                </div>
                <div className="stat-detail-block statistic-item">
                    {
                        this.details2.map((item,key) => 
                           <div key={key} className="stat-detail-item">
                              <span>{item.title}</span>
                              <span>{item.value}</span>
                              <p>{item.explanation ? item.explanation : ""}</p>
                           </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default StatisticDetailComponent;