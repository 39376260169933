import React, { Component } from 'react';
import { ASSETS } from '../../constants/Paths';

class DateButtonComponent extends Component {
    render() {
        let {text} = this.props;
        return (
            <div>
                <button className='btn-date'> 
                    <span>{text}</span>
                    <img src={`${ASSETS}img/date-icon.svg`} alt="date-icon"/>
                </button>
            </div>
        );
    }
}

export default DateButtonComponent;