import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { updateDomainCookie } from '../../services/webServices';
import RadioInputComponent from '../formFields/radioInputComponent';
import SelectBoxComponent from '../formFields/selectBoxComponent';
import TextInputComponent from '../formFields/textInputComponent';
import LoaderComponent from '../loaderComponent';
import SucsessComponent from './../layout/sucsessComponent';
const validate = (values) => {
    const errors = {};

    if (!values || !values.cookieName || values.cookieName === "") {
        errors.cookieName = "Zorunlu";
    }
    if (!values || !values.description || values.description === "") {
        errors.description = "Zorunlu";
    }
    return errors;
};
class EditCookieListFormComponent extends Component {
    state = {
        isLoading: false,
        categoryOptionArr:[],
        formText:{},
        success:false,
        values:[]

    }
    componentDidMount (){
        // this.setState({values:this.props.cookie})
        
        // console.log("333333333333333",this.props.cookie)
        let products = this.props.allCategories.map((ctr, key) => ({
            value: ctr.id,
            text: ctr.category_name,
    
        }))
        this.setState({
            categoryOptionArr: products,
            formText:{"cookieName":this.props.cookie.cookie_name && this.props.cookie.cookie_name,"category":this.props.cookie.cookie_category_id && this.props.cookie.cookie_category_id,"description":this.props.description && this.props.description}
        })

        
    
    }
    submit = (values) => {
        let cookieDetail = {id:this.props.cookie_id,cookie_category_id:values.category,description:values.description,is_abroad:values.cookieSource === 1 ?  true :false,cookie_name:values.cookieName}
        console.log("COOKIE-DETAIL",cookieDetail)
        updateDomainCookie({cookie:cookieDetail,domainId:this.props.domainId,category:{id:values.category}}).then((result)=>{
            if(result.data.success){
                this.success()
                this.props.toggleEditButton()
            }
        })
    }
    success = () =>{
        this.setState({
            success:true
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(()=>{
            this.setState({
                success:false
            })
        },2000)
    }
    optionArr = [
        {
            value: 1,
            text: "test"
        },
        {
            value: 2,
            text: "test2"
        }
    ]
    render() {
        let { isLoading,categoryOptionArr } = this.state
        let { cookie,description,cookie_category_id } = this.props
console.log("===============================",cookie)
     
        return (
            <div>
            {
                isLoading &&
                <LoaderComponent />
            }
            <Form onSubmit={this.submit}
                validate={validate}
                initialValues={this.state.formText}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="lightBox-container">
                            <div className="head-item">
                                <h2 className="light-box-title">Edit Cookie</h2>
                                <div className="close-icon" onClick={()=>this.props.toggleEditButton()}>
                                    <i>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                    </i>
                                </div>
                            </div>

                            <Field
                                name={"cookieName"}
                                type="text"
                                label="Cookie Name *"
                                component={TextInputComponent}
                                placeholder={cookie.cookie_name != "" ? cookie.cookie_name : "Cookie Name"}
                                // value={cookie.cookie_name && cookie.cookie_name}


                            />
                            {/* <Field
                                name={"host"}
                                type="text"
                                label="Host *"
                                component={TextInputComponent}
                                placeholder="Exm. gulenayva.cookie.com"
                            /> */}
                            {/* <Field
                                name={"lifespan"}

                                label="Lifespan *"
                                component={SelectBoxComponent}
                                options={this.optionArr}
                                placeholder="Exm. Session"
                            /> */}
                             <Field
                                    name={"category"}
                                    // value={cookie.cookie_category_id && cookie.cookie_category_id}

                                    label="Category *"
                                    component={SelectBoxComponent}
                                    options={categoryOptionArr}
                                    placeholder={cookie.cookie_category.category_name != "" ? cookie.cookie_category.category_name :"Select Category"}
                                />
                            <Field
                                name={"description"}
                                type="text"
                                label="Description *"
                                component={TextInputComponent}
                                placeholder={cookie.cookie_category.info_description != "" ? cookie.cookie_category.info_description :"Write description"}
                                // value={description && description}

                            />
                            {/* <div className="radio-container">
                                <h3>Select cookie party*</h3>
                                <div className="radio-block">
                                    <div className="radio-item">
                                        <Field
                                            name={"cookieParty"}
                                         
                                            component={RadioInputComponent}
                                            options={[{ value: 1, name: "First Party Cookies" }, { value: 2, name: "Third Party" }]}
                                        />
                                    </div>
                                </div>
                            </div> */}
                            <div className="radio-container">
                                <h3>This cookie source can store data to abroad server*</h3>
                                <div className="radio-block">
                                    <div className="radio-item">
                                        <Field
                                            name={"cookieSource"}
                                            component={RadioInputComponent}
                                            options={[{ value: 1, name: "Yes" }, { value: 2, name: "No" }]}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="btn-block">
                                <div className="btn-item cancel" onClick={()=>this.props.toggleEditButton()}>
                                    <span>Cancel</span>
                                </div>
                                <button type="submit" className="btn-item">
                                    Save Cookie
                                </button>
                            </div>
                        </div>


                    </form>
                )}>

            </Form>
            <SucsessComponent warn={this.state.success} message={"Cookie Düzenlendi"} />

        </div>
        );
    }
}

export default EditCookieListFormComponent;
