import {RECEIVE_SIGNED_USER, RECEIVE_THIRD_PARTY} from "../constants/actionTypes";

const initialStore = {
   
};

const thirdPartyReducer = (state = initialStore, action) => {
    switch (action.type) {
        case RECEIVE_THIRD_PARTY:
            return {
                third:action.payload
            };
        default :
            return state;
    }
};

export default thirdPartyReducer;