import React, { Component } from 'react';
import StatisticChartComponent from './statisticChartComponent';

class StatisticPercentageComponent extends Component {

    render() { 
        
        return (
            <div className='statistic-percentage-container'>
                <div className="stat-percentage-block statistic-item">
                    <span>Cookies Accepted</span>
                  
                            <div  className="stat-percentage-item">
                                <p style={{color:"#"+this.props.foeignColor}} >Percentage of users who accepted all cookies:</p>
                                <p style={{color:"#"+this.props.foeignColor}} >{this.props.apccetTotalValue +" / "+ this.props.allCookieT +".00%"}</p>
                            </div>
                            
                            <div  className="stat-percentage-item">
                                <p style={{color:"#"+this.props.requareColor}}>Percentage of users accepted Strictly Necessary Cookies:</p>
                                <p style={{color:"#"+this.props.requareColor}}>{this.props.apccetCount["Kalıcı Çerezler"] +" / "+ this.props.tottalApccet["Kalıcı Çerezler"]  +".00%"}</p>
                            </div>
                            
                            <div  className="stat-percentage-item">
                                <p style={{color:"#"+this.props.analistColor}}>Percentage of users accepted Analytics  Cookies:</p>
                                <p style={{color:"#"+this.props.analistColor}}>{this.props.apccetCount["Analytics"] +" / "+ this.props.tottalApccet["Analytics"]  +".00%"}</p>
                            </div>
                            
                            <div  className="stat-percentage-item">
                                <p style={{color:"#"+this.props.socialColor}}>Percentage of users accepted Social  Cookies:</p>
                                <p style={{color:"#"+this.props.socialColor}}>{this.props.apccetCount["Social"] +" / "+ this.props.tottalApccet["Social"]  +".00%"}</p>
                            </div>
                 
                </div>
                <div className="stat-percentage-block">
                    <StatisticChartComponent data ={this.props.data}  socialColor={this.props.socialColor} analistColor={this.props.analistColor} requareColor={this.props.requareColor} foeignColor={this.props.foeignColor}/>
                    <div className="text-block">
                        <div className="text-item">
                            <div style={{backgroundColor:"#"+this.props.foeignColor}} className="circle-item"/>
                            <span>Users who accepted all cookies</span>
                        </div>
                        <div className="text-item">
                            <div style={{backgroundColor:"#"+this.props.requareColor}} className="circle-item"/>
                            <span>Strictly Necessary Cookies only</span>
                        </div>
                        <div className="text-item">
                            <div style={{backgroundColor:"#"+this.props.analistColor}} className="circle-item"/>
                            <span>Analytics Cookies </span>
                        </div>
                        <div className="text-item">
                            <div style={{backgroundColor:"#"+this.props.socialColor}} className="circle-item"/>
                            <span>Social Cookies </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StatisticPercentageComponent;