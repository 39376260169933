import React, { Component } from 'react';
import EditCookieListFormComponent from '../forms/editCookieListFormComponent';

class  EditCookirListComponent extends Component {
    render() {
        return (
            <div className="lightBox-root cookie">
            <div className="lightBox-wrapper">
                <div className="lightBox-container">
                    <EditCookieListFormComponent domainId={this.props.domainId} toggleEditButton= {this.props.toggleEditButton} allCategories={this.props.allCategories} cookie={this.props.cookie} cookie_id={this.props.cookie_id} description={this.props.description} />
     
                </div>
            </div>
        </div>
        );
    }
}

export default EditCookirListComponent;
