import React, {Component} from 'react';
import {Field, Form} from "react-final-form";
import TextInputComponent from "../formFields/textInputComponent";
import RecaptchaFieldComponent from "../formFields/recaptchaFieldComponent";
import {signinAction} from "../../actions";
import {connect} from "react-redux";
import {DASHBOARD_PAGE} from "../../constants/routePaths";
import {withRouter} from "react-router-dom";
import LoaderComponent from "../loaderComponent";

const validate = (values) => {
   const errors = {};
   if (!values || !values.email || values.email === "" || !/^([a-zA-Z0-9!#$%&'*-`{|}~_.]{1}[a-zA-Z0-9!#$%&'*-`{|}~_.]*)(@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6})$/i.test(values.email)) {
      errors.email = "Email giriniz.";
   }
   if (!values || !values.password || values.password === "") {
      errors.password = "Şifre giriniz";
   }
   if (!values.recaptcha_code || values.recaptcha_code.trim().length === 0) {
      errors.recaptcha_code = true
   }
   console.log(errors)
   return errors;
};

class LoginFormComponent extends Component {
   state = {
      isLoading: false, 
      isFailed: false,
      reCaptchaControl:false
   }
   submit = (values) => {
      
      this.setState({
         isLoading: true
      })
      this.props.dispatch(signinAction(values, (success) => {
         if (success) {
            this.setState({
               isLoading: false
            })
            this.props.history.push(DASHBOARD_PAGE)
         } else {
            this.setState({
               isLoading: false,
               isFailed: true,
               reCaptchaControl:true
            })
            console.log("------>",this.state.isFailed)
         }
      }))
   }
changeRecaptcha =(value)=>{
   this.setState({
      reCaptchaControl:value
   })
   if(this.state.changeRecaptcha){
      this.setState({
         reCaptchaControl:false
      })
   }
}
   render() {
      let {isLoading,reCaptchaControl} = this.state;
      return (
          <div>
             {
                isLoading &&
                <LoaderComponent/>
             }
             <Form onSubmit={this.submit}
                   validate={validate}
                   render={({handleSubmit}) => (
                       <form onSubmit={handleSubmit}>
                          <div className="input-block">
                             <Field
                                 name={"email"}
                                 type="text"
                                 label="E-mail"
                                 component={TextInputComponent}
                                 isFailed ={this.state.isFailed}
                                 err_txt="Please enter e-mail"
                             />
                             <Field
                                 name={"password"}
                                 type="password"
                                 label="Password"
                                 component={TextInputComponent}
                                 isFailed ={this.state.isFailed}
                                 forgotPass={true}
                                 err_txt="Please enter password"
                             />
                             <Field
                                 name="recaptcha_code"
                                 component={RecaptchaFieldComponent}
                                 isFailed ={this.state.isFailed}
                                 reCaptchaControl={reCaptchaControl}
                                 changeRecaptcha={this.changeRecaptcha}
                             />

                          </div>
                          <div className="btn-block">
                             <button type="submit" className="btn-item">
                                Login
                             </button>
                          </div>
                       </form>
                   )}>

             </Form>

          </div>
      );
   }
}

export default withRouter(connect()(LoginFormComponent));
