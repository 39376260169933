import React, { Component } from "react";
import { Field, Form } from "react-final-form";
import SelectBoxComponent from "../../components/formFields/selectBoxComponent";
import { addNewUserToAccount ,getRoles,updateUser} from "../../services/webServices";
import ErrorComponent from "../../components/layout/errorComponent";
import {dataStandardized} from "../../helper/index";
import SucsessComponent from "../../components/layout/sucsessComponent";

const validate = (values) => {
    const errors = {};
   
    if (!values || !values.role || values.role === "") {
        errors.role = "Role giriniz.";
    }
    return errors;
}; 

class EditUserContainer extends Component {
    state = {
        isLoading: false,
        didSelectOpen: false,
        error:false,
        success:false,
        roles:[]
    }
    container= React.createRef();
    root= React.createRef();

    componentDidMount(){
        getRoles().then((result)=>{
            if(result.data.success){
                this.setState({
                    roles:result.data.success
                })
            }
        })
        if(typeof window !== "undefined"){
            this.root.current.addEventListener("click", this.outsideClick)
        }
    }
    componentWillUnmount(){
        if(typeof window !== "undefined"){
            this.root.current.removeEventListener("click", this.outsideClick)
        }
    }
    outsideClick = (e)=>{
        if(this.container && this.container.current && !this.container.current.contains(e.target)){
            this.props.lightBox("close")
        }
    }
    submit = (values) => {
        this.setState({
            isLoading: true
        })
        updateUser({user_id:this.props.user_id,role_id:values.role}).then((res)=>{
            if(res.data.success){
                this.props.lightBox("close")
                this.successMsg()
                this.setState({
                    success:true
                })
            }else{
                this.setState({
                    error:true
                })
                // this.resendError();
            }
        })

    }
    successMsg = () => {
        clearTimeout(this.timer)
       
        this.timer = setTimeout(()=>{
            this.setState({
                success:false
            })
        },2000)
    }
    hideErrorMsg = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(()=>{
            this.setState({
                error:false
            })
        },2000)
    }
    toggleSelect = () => {
        this.setState({
            didSelectOpen: !this.state.didSelectOpen
        })
    }
    render() {
        return (
            <div className="lightBox-root user edit" ref={this.root}>
                <div className="lightBox-wrapper">

                    <Form onSubmit={this.submit}
                        validate={validate}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} className="lightBox-container edit" ref={this.container}>
                                <div className="head-item">
                                    <h2 className="light-box-title">Edit User</h2>
                                    <div className="close-icon" onClick={() => this.props.lightBox("close")}>
                                        <i>
                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </i>
                                    </div>
                                </div>
                                <div className="user-block">
                                   
                                   
                                    
                                        <Field
                                            name={"role"}
                                            type="text"
                                            label={"Role*"}
                                            component={SelectBoxComponent}
                                            placeholder={this.props.role_id==1?"Owner":(this.props.role_id==2?"Admin":"User")}
                                            options={dataStandardized(this.state.roles,"id","role")}



                                        />
                                  
                                </div>
                                <div className="btn-block">
                                        <button className="btn-item cancel" onClick={() => this.props.lightBox("close")}>
                                            <span>Cancel</span>
                                        </button>
                                        <button type="submit" className="btn-item">
                                            <span>Save</span>
                                        </button>
                                    </div>
                            </form>
                        )}
                    >
                    </Form>
       

            </div>
            <ErrorComponent warn={this.state.error} />
            <SucsessComponent warn={this.state.success } message={"Code succesfuly sent."}/>
            </div >

        )
    }
};

export default EditUserContainer;