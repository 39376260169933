import React, { Component, Fragment } from "react";
import { ASSETS } from "../constants/Paths-prod";




class SlideComponent extends Component {
    sliderContainer = React.createRef();
    dots = React.createRef();
    itemLength = 0
    id = null
    state = {
        slideIndex: 1
    }
    componentDidMount() {
        // setInterval(this.nextSlide, 10000)
    }

    slideLength = () => {
        this.itemLength = this.dots.current && this.dots.current.children.length
        return this.itemLength
    }

    nextSlide = () => {
        let itemLength = this.slideLength()
        if (this.state.slideIndex !== itemLength) {
            this.setState({
                slideIndex: this.state.slideIndex + 1
            })

        }
        else if (this.state.slideIndex === itemLength) {
            this.setState({
                slideIndex: 1
            })
        }
    }

    moveDot = (props) => {
        this.setState({
            slideIndex: props
        })

    }

    slideArr = [
        {
            "id": 1,
            "image": "assets/img/login-cover.png",
            "title": "Manage your privacy program with CookieSuit, don’t worry about non-compliance risks.",
            "desc": "Don’t bother about cookie usage, get it done easily with CookieSuit!"
        }
        // {
        //     "id": 2,
        //     "image": "assets/img/login-cover.png",
        //     "title": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.2",
        //     "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at volutpat tincidunt mi non tincidunt at sed. Bibendum aliquet non lectus laoreet tortor dui egestas praesent. Eget et aliquam diam vel"
        // },
        // {
        //     "id": 3,
        //     "image": "assets/img/login-cover.png",
        //     "title": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.3",
        //     "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at volutpat tincidunt mi non tincidunt at sed. Bibendum aliquet non lectus laoreet tortor dui egestas praesent. Eget et aliquam diam vel"
        // }

    ]
    render() {
        return (
            <Fragment>
                {
                    this.slideArr.map((obj, index) => {
                        return(
                            <div key={index} ref={this.sliderContainer} className={this.state.slideIndex === index + 1 ? "cover-slider-container active" : "cover-slider-container"}  >
                                <div className="cover-block">
                                    <picture>
                                        <source media="(min-width: 768px)"
                                            srcSet={`${ASSETS}img/login-cover.webp 1x ${ASSETS}img/login-cover_2x.webp 2x`}
                                            type="image/webp" />
                                        <source media="(min-width: 768px)"
                                            srcSet={`${ASSETS}img/login-cover.png 1x ${ASSETS}img/login-cover_2x.png 2x`}
                                            type="image/png" />
                                        <img src={`${ASSETS}img/login-cover.png`} alt="cover-login" />
                                    </picture>

                                </div>
                                <div className="cover-text-block">
                                    <h3>
                                        {obj.title}
                                    </h3>
                                    <p>
                                        {obj.desc}
                                    </p>

                                </div>
                            {/* <div className="dots" ref={this.dots}>

                                {
                                    this.slideArr.map((item, subIndex) => (
                                        <div key={subIndex}
                                            onClick={() => this.moveDot(subIndex + 1)}
                                            className={this.state.slideIndex === subIndex + 1 ? "dot active" : "dot"}
                                        >
                                            <div className="inDot"></div>
                                        </div>
                                    ))
                                }

                            </div> */}
                            </div>
                        )

                    })
                }


            </Fragment>


        );
    }
}

export default SlideComponent;
