import React, { Component } from 'react';

class CookieLanguageComponent extends Component {
    render() {
        return (
            <div className="lang-table-wrapper cookie">
                <div className="lang-head-container">

                    <div className="check-box">
                        <input type="checkbox" />
                        <label htmlFor="translations">Only missing translations</label>
                    </div>
                </div>
                <div className="description-table-block">
                    <div className="description-table-head-item">
                        <div className="row-th name-th">
                            <span>Element name</span>
                            <i className="icon-item">
                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="7.5" cy="7.5" r="4.5" stroke="#A4A1C0"
                                        stroke-width={2} strokeLinecap="round"
                                        strokeLinejoin="round" />
                                    <path d="M11 11L13 13" stroke="#A4A1C0" stroke-width={2}
                                        strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </i>
                        </div>
                        <div className="row-th lang-th">
                            <span>Turkish</span>
                        </div>
                        <div className="row-th lang-defauld-th">
                            <span>English (Default)</span>
                        </div>
                    </div>
                    {/* TODO --> active classı tabloda boş alanlrı belirtmek için kullanılır */}
                    <div className="description-table-td-item active">
                        <div className="row-td  name-td">
                            <span>ok_button</span>
                        </div>
                        <div className="row-td  lang-td">
                            <span></span>
                        </div>
                        <div className="row-td  lang-defauld-td">
                            <span>Done</span>
                        </div>

                    </div>
                    <div className="description-table-td-item">
                        <div className="row-td  name-td">
                            <span>lorem_ipsum</span>
                        </div>
                        <div className="row-td  lang-td">
                            <span>Lorem Ipsum</span>
                        </div>
                        <div className="row-td  lang-defauld-td">
                            <span>Lorem Ipsum dolor sit ame</span>
                        </div>

                    </div>
                    <div className="description-table-td-item active">
                        <div className="row-td  name-td">
                            <span>title</span>
                        </div>
                        <div className="row-td  lang-td">
                            <span></span>
                        </div>
                        <div className="row-td  lang-defauld-td">
                            <span>Manage your cookie settings</span>
                        </div>

                    </div>
                    <div className="description-table-td-item">
                        <div className="row-td  name-td">
                            <span>desc</span>
                        </div>
                        <div className="row-td  lang-td">
                            <span>Tanımlama bilgilerini; sitemizin doğru şekilde çalışmasını sağlamak, içerikleri ve reklamları kişiselleştirmek, sosyal medya özellikleri sunmak ve site trafiğimizi analiz etmek için kullanıyoruz. Aynı zamanda site kullanımınızla ilgili bilgileri; sosyal medya, reklamcılık ve analiz ortaklarımızla paylaşıyoruz.</span>
                        </div>
                        <div className="row-td  lang-defauld-td">
                            <span>We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising</span>
                        </div>

                    </div>
                    <div className="description-table-td-item">
                        <div className="row-td  name-td">
                            <span>title</span>
                        </div>
                        <div className="row-td  lang-td">
                            <span>Çerez izinlerinizi yönetin</span>
                        </div>
                        <div className="row-td  lang-defauld-td">
                            <span>Manage your cookie settings</span>
                        </div>

                    </div>

                </div>

            </div>
        )
    }
}

export default CookieLanguageComponent;