import React, {Component, Fragment} from "react";
import { WEB_SITES, WEB_SITE_INTEGRATION } from "../../constants/routePaths";
import AsideComponent from "../layout/asideComponent";
import { connect } from "react-redux";
import BreadcrumbComponent from "../layout/breadcrumbComponent";
import { getSiteInfoAction } from "../../actions/domainActions";
import {Link, matchPath} from "react-router-dom";
import { CDNURL } from "../../constants/Paths";

class WebsiteIntegrationComponent extends Component {
    state = {
        activeWindow: "SDK Version 2.1"
    }

    activeWindov = (activeWindow) => {
        this.setState({
            activeWindow
        })
    }
    cookieScriptCopied = false;
    exampleScriptCopied = false;
    componentDidMount() {
        let urlParams = matchPath(this.props.location.pathname, {
            path: WEB_SITE_INTEGRATION,
            exact: false,
            strict: false
        });
        this.getData(urlParams.params.id)
    }
    getData=(param)=>{
        this.props.dispatch(getSiteInfoAction(param,(result)=>{
            this.setState({
                siteInfoData:result
            })
        }));
    }

    copyToClipBoard = (copyTextValue,copiedScriptKey) => {
        var dummy = document.createElement('input'),
            text = copyTextValue;
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        this[copiedScriptKey] = true;
        this.forceUpdate()
        this[`timeout${copiedScriptKey}`] = setTimeout(()=>{
            this[copiedScriptKey] = false;
            this.forceUpdate()
        },1000)
    }

    componentWillUnmount(){
        clearTimeout(this[`timeoutcookieScriptCopied`])
        clearTimeout(this[`timeoutexampleScriptCopied`])
    }
 
    render() {
        const codeText1 = 
        `
        <script id="cookie-bundle" src="${CDNURL}${this.state.siteInfoData?this.state.siteInfoData.secret_key:""}" type="text/javascript"></script>
        `;
        const codeText2 = 
        `
        <script type="text/plain" data-cookie-category="analytics" async="" src="https://www.youtube.com/iframe_api"></script>
        `;
        const codeText3 = 
        `
        <script id="cookie-bundle" src="${CDNURL}${this.state.siteInfoData?this.state.siteInfoData.secret_key:""}&lang=tr-TR" type="text/javascript"></script>
        `;
        return (
            <div className="content-root integration-root">
                {
                    this.props.siteInfoData &&
                    <BreadcrumbComponent breadName="Websites" breadLink={WEB_SITES} subBreadName={this.props.siteInfoData.domain_name}/>
                }
                <div className="content-wrapper">
                    <div className="content-container">
                        <div className="tab-btn-block">
                            {/* TODO --> active classı seçili olan sayfayı gösterir */}
                            <div className={`tab-btn-item ${this.state.activeWindow==="SDK Version 2.1"?"active":""}`} onClick={()=>{
                                this.activeWindov("SDK Version 2.1")
                            }}>
                                <span>Intergration</span>
                            </div>
                            {/* <div className={`tab-btn-item ${this.state.activeWindow==="SDK Version 2.2"?"active":""}`} onClick={()=>{
                                this.activeWindov("SDK Version 2.2")
                            }}>
                                <span>SDK Version 2.2</span>
                                <p>New</p>

                            </div> */}
                        
                        </div>
                        <div className="content-block">
                            <div className="text-item">
                                <h3>Scripts</h3>
                                <p>You need to put the following code block in the head of your site.</p>

                            </div>
                            <div className="scripts-container">
                            <div className="scripts-item low-script">
                            <div className={`copy-code ${this.cookieScriptCopied?"copied":""}`} onClick={()=>{this.copyToClipBoard(codeText1,"cookieScriptCopied")}}>
                                        <span>Copy</span>
                                        
                                    </div>
                                    <div className="script">
                                        <pre>
                                            {codeText1}
                                        </pre>
                                    </div>
                               
                            </div>
                            </div>
                            <div className="text-item">
                                <h3>For 3th party cookies</h3>
                                <p>For 3rd party script applications, you need to define the type attribute of the script tag 'text/plain'. And if you want the script to load when the category is approved, you need to add it to the 'data-cookie-category' attribute with a space between those values.</p>

                            </div>
                            <div className="scripts-container">
                            <div className="scripts-item">
                            <div className={`copy-code ${this.cookieScriptCopied?"copied":""}`} onClick={()=>{this.copyToClipBoard(codeText2,"cookieScriptCopied")}}>
                                        <span>Copy</span>
                                        
                                    </div>
                                    <div className="script">
                                        <pre>
                                            {codeText2}
                                        </pre>
                                    </div>
                               
                            </div>
                            </div>
                            <div className="text-item">
                                <h3>Language Support</h3>
                                <p>For Language Support you need to define lang properties on your script code. Ex. -Turkish(TR-tr), -English (EN-en)</p>

                            </div>
                            <div className="scripts-container">
                            <div className="scripts-item">
                            <div className={`copy-code ${this.cookieScriptCopied?"copied":""}`} onClick={()=>{this.copyToClipBoard(codeText3,"cookieScriptCopied")}}>
                                        <span>Copy</span>
                                        
                                    </div>
                                    <div className="script">
                                        <pre>
                                            {codeText3}
                                        </pre>
                                    </div>
                               
                            </div>
                            </div>
                        </div>
                    </div>
                    
                    <AsideComponent/>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    siteInfoData: state.domainReducer.siteInfo&&state.domainReducer.siteInfo[ownProps.match.params.id]?state.domainReducer.siteInfo[ownProps.match.params.id]:null
})

export default connect(mapStateToProps)(WebsiteIntegrationComponent);
