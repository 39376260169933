import React, { Component, Fragment } from "react";
import AddButtonComponent from "../components/buttons/addButtonComponent";
import AddNewComponent from "../components/dashboard/addNewComponent";
import BreadcrumbComponent from "../components/layout/breadcrumbComponent";
import SucsessComponent from "../components/layout/sucsessComponent";
import UserItemComponent from "../components/superAdmin/userItemComponent";
import CreateNewUserContainer from "../containers/user/createNewUserContainer";
import DeleteUserContainer from "../containers/user/deleteUserContainer";
import { getAccountUser } from "../services/webServices";
import {getDomainsAction, searchAction} from "../actions/domainActions";
import { connect } from "react-redux";
import { ADD_USERS_PAGE } from "../constants/routePaths";
import EditUserContainer from "../containers/user/editUserContainer";
import ErrorComponent from "../components/layout/errorComponent";



class UserPage extends Component {
    constructor(props) {
        super(props);
        props.dispatch(getDomainsAction())
     }
    state = {
        lightBox: "",
        users: [],
        success:false,
        searchedText:"",
        isUp:false,
        lbxType: ""
    }
    orderType = "desc"
    orderName = "id"
   
    onSearch = (searchedText) => {
       this.setState({
          searchedText
       })
    }

    filterSearch = (item) => {
        if(!this.state.searchedText || this.state.searchedText===""){
            return item;
        }
        return (item.email.indexOf(this.state.searchedText)!==-1)
    }
    order = (_orderName) => {
       this.orderName = _orderName
       this.orderType = this.orderType==="desc" ? "asc" : "desc"
       this.setState({
          isUp:this.orderType === "asc"
       })
    }
    sortFunc = (a,b) => {
        if(this.orderType === "asc"){
            return (a[this.orderName]>b[this.orderName] ? 1:b[this.orderName]>a[this.orderName]?-1:0)
        }else{
            return (a[this.orderName]>b[this.orderName] ? -1:b[this.orderName]>a[this.orderName]?1:0)
        }
    }
    componentDidMount() {
        if(this.props.location.pathname===ADD_USERS_PAGE){
            this.lightBox("user")
        }
        this.getAccountUsers();
    }

    getAccountUsers = () => {
        getAccountUser().then((res) => {
            if (res.data.success) {
                this.setState({
                    users: res.data.success
                })
            }
        }).catch((err) => {

        })
    }


    lightBox = (lightBox,deleteId,getData) => {
        this.setState({
            lightBox,
            deleteId
        })
        if(getData){
            this.getAccountUsers();
        }
    }
    userAdded = () =>{
        this.lightBox("close",null,true);
        this.setState({
            success:true
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(()=>{
            this.setState({
                success:false
            })
        },2000)
    }
    render() {

        return (
            <div className="content-root user-root">

                <BreadcrumbComponent breadName="Users" />

                <div className={`content-wrapper ${this.state.lightBox === "delete" ? "delete" : this.state.lightBox === "user" ? "user" : "" || this.state.lightBox ==="edit"? "edit":""}`}>
                    {/* TODO --> table-container a active clası geldiğinde listelenen user lar gösterili  */}
                    <div className="content-block add-new-wrapper" >
                        <AddNewComponent
                            title={"Users"}
                            text={"Vestibulum vestibulum dapibus scelerisque. Aliquam blandit dignissim mauris nec mollis. Nunc molestie bibendum ornare."}
                            buttonName={"Add User"}
                            icon={<svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="100" height="100" rx="8" stroke="#C9C9D9" stroke-width="2" />
                                <circle cx="40" cy="28" r="14" fill="#C9C9D9" stroke="#C9C9D9" stroke-width="2" />
                                <path d="M75 76H89C90.1046 76 91 75.1046 91 74V61.4785C91 55.0583 85.9467 49.7754 79.5328 49.4903L68.5 49" stroke="#C9C9D9" stroke-width="2" strokeLinecap="round" />
                                <circle cx="72" cy="36" r="9" fill="#E0E0E9" stroke="#C9C9D9" stroke-width="2" />
                                <path d="M12.6446 61.4407C12.8502 54.2437 18.1892 48.2312 25.3114 47.1761C35.0506 45.7332 44.9494 45.7332 54.6886 47.1761C61.8108 48.2312 67.1498 54.2437 67.3554 61.4407L67.98 83.2997C67.9914 83.6986 67.7015 84.0426 67.3064 84.0991C49.1941 86.6866 30.8059 86.6866 12.6936 84.0991C12.2985 84.0426 12.0086 83.6986 12.02 83.2997L12.6446 61.4407Z" fill="#C9C9D9" stroke="#C9C9D9" stroke-width="2" />
                            </svg>
                            }
                        />
                    </div>
                    <div className="table-container active">
                        <div className="table-head-block">
                            <div className="row-item">
                                <div className="row-td name-td">
                                    <span className={this.state.isUp && this.orderName === "email"?"up":""} onClick={()=>this.order("email")}>E-Mail</span>
                                    <div className="search-block">
                                        <div className="input-item">
                                            <input type="search" placeholder="Search" onChange={(e)=>this.onSearch(e.target.value)}/>
                                            <i className="icon-item">
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="7.5" cy="7.5" r="4.5" stroke="#A4A1C0" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11 11L13 13" stroke="#A4A1C0" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row-td login-td">
                                    <span className={this.state.isUp && this.orderName === "last_login"?"up":""} onClick={()=>this.order("last_login")}>Last Login</span>
                                </div>
                                <div className="row-td register-td">
                                    <span className={this.state.isUp && this.orderName === "created_date"?"up":""} onClick={()=>this.order("created_date")}>Register Date</span>
                                </div>
                                <div className="row-td number-td">
                                    <span className={this.state.isUp && this.orderName === "number"?"up":""} onClick={()=>this.order("number")}>Status</span>
                                </div>
                                <div className="row-td status-td">
                                    <span className={this.state.isUp && this.orderName === "role_id"?"up":""} onClick={()=>this.order("role_id")}>Role</span>
                                </div> */}
                                <div className="row-td button-td" onClick={() => this.lightBox("user")}>
                                    <AddButtonComponent buttonName="New User" />
                                </div>
                            </div>
                        </div>
                        <div className="table-block">
                            {
                                this.state.users.filter(this.filterSearch).sort(this.sortFunc).map((userItem, key) =>
                                    <UserItemComponent lightBox={this.lightBox} {...userItem} key={key} />
                                )
                            }
                        </div>
                    </div>
                    {
                        this.state.lightBox === "user" &&
                        <CreateNewUserContainer userAdded={this.userAdded} lightBox={this.lightBox} users={this.state.users}/>
                    }
                    {
                        this.state.lightBox === "delete" && 
                        <DeleteUserContainer deleteId={this.state.deleteId} lightBox={this.lightBox}/>
                    }
                    {
                        this.state.lightBox === "edit" && 
                        <EditUserContainer lightBox={this.lightBox} />
                    }
                    <SucsessComponent warn={this.state.success} message={"User succesfuly added."}/>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    domains: state.domainReducer.domains || [],
    filteredDomains:state.domainReducer.filteredDomains
 })
export default connect(mapStateToProps) (UserPage);
