import React, { Component } from "react";

import AsideComponent from "../layout/asideComponent";
import{Link} from "react-router-dom";
import {POLICIES_EDIT_WITHOUT_PARAM, POLICIES_PAGE} from "../../constants/routePaths";
import PolicyFormComponent from "./policyFormComponent";
import { getPolicyDetail } from "../../services/webServices";

class PoliciyEditComponent extends Component {
    state = {
        detail:null
    }
    componentDidMount(){
        getPolicyDetail({policy_id:this.props.match.params.id}).then((result)=>{
            if(result.data.success){
                this.setState({
                    detail:result.data.success
                })
            }else{
                this.props.history.replace(POLICIES_PAGE)
            }
        }).catch((err)=>{
            this.props.history.replace(POLICIES_PAGE)
            console.log(err);
        })
    }

   
    render() {
        return (
            <div className="content-root policies-add-root">
                 <div className="breadcrumb-block">
                    <Link to={POLICIES_PAGE} className="breadcrumb-item right-arr">Policies</Link>
                    <span className="breadcrumb-item" >Edit Policy</span>
                </div>
                <div className="content-wrapper">
                    <div className="content-block">
                        <div className="head-item">
                            <h1>Edit Policy</h1>
                        </div>
                        {
                            this.state.detail &&
                            <PolicyFormComponent history={this.props.history} detail={this.state.detail}/>
                        }
                    </div>
                    <AsideComponent />

                </div>
            </div>
        );
    }
}

export default PoliciyEditComponent;
