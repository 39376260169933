import React, { Component } from "react";

class LoaderComponent extends Component {

    render() {
        return (
            <div className="loader-root">
                <div className="loader-block">
                    <div className="loader"></div>
                    <p>Yükleniyor...</p>
                </div>
            </div>
        )
    }
}

export default LoaderComponent;
