import React, { Component } from 'react';
import { deleteUserFromAccount } from '../../services/webServices';

class DeleteUserContainer extends Component {
    state = {
        loader:false,
        error:true,
        success:false
    }
    container= React.createRef();
    root= React.createRef();

    componentDidMount(){
        if(typeof window !== "undefined"){
            this.root.current.addEventListener("click", this.outsideClick)
        }
    }
    componentWillUnmount(){
        if(typeof window !== "undefined"){
            this.root.current.removeEventListener("click", this.outsideClick)
        }
    }
    outsideClick = (e)=>{
        if(this.container && this.container.current && !this.container.current.contains(e.target)){
            this.props.lightBox("close")
        }
    }
    deleteUser = () => {
        this.setState({
            loader:true
        })
        deleteUserFromAccount({user_id:this.props.deleteId}).then(result=>{
            if(result.data.success){
                this.props.lightBox("close",null,true)
                this.setState({
                    success:true,
                    loader:false
                })
            }else{
                this.setState({
                    fail:true,
                    loader:false
                })
            }
        }).catch(err=>{
            this.setState({
                fail:true,
                loader:false
            })
            console.log("err",err);
        })
    }
    render() {
        return (
            <div className="lightBox-root delete" ref={this.root}>
                <div className="lightBox-wrapper">
                    <div className="lightBox-container " ref={this.container}>
                        <div className="head-item">
                            <h2 className="light-box-title">Delete User</h2>
                            <div className="close-icon" onClick={() => this.props.lightBox("close")}>
                                <i>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </i>
                            </div>
                        </div>
                        <div className="text-block">
                            <p>Do you want to delete the user?</p>
                            <span>“Delete User ”</span>
                        </div>
                        <div className="btn-block">
                            <div className="btn-item cancel" onClick={() => this.props.lightBox("close")}>
                                <span>Cancel</span>
                            </div>
                            <div className="btn-item delete" onClick={this.deleteUser}>
                                <span>Delete</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
};

export default DeleteUserContainer