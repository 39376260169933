import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
    DASHBOARD_PAGE,
    PLANS_PAGE,
    POLICIES_PAGE,
    REPORTS_PAGE,
    SDK_PAGE,
    USERS_PAGE,
    WEB_SITES,
    STATISTICS_PAGE
} from "../../constants/routePaths";

class NavComponent extends Component {

    render() {
        let {user} = this.props;
        return (
            <Fragment>
                <nav className="nav-block">
                    <ul>
                        <li>
                            <NavLink exact to={DASHBOARD_PAGE} className={`nav-item`}>
                                <i>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 6C4 5.44772 4.44772 5 5 5H11C11.5523 5 12 5.44772 12 6V10C12 10.5523 11.5523 11 11 11H5C4.44772 11 4 10.5523 4 10V6Z" stroke="#716D92" stroke-width={2} />
                                        <path d="M20 15C20 14.4477 19.5523 14 19 14H12C11.4477 14 11 14.4477 11 15V18C11 18.5523 11.4477 19 12 19H19C19.5523 19 20 18.5523 20 18V15Z" stroke="#716D92" stroke-width={2} />
                                        <path d="M15 6C15 5.44772 15.4477 5 16 5H19C19.5523 5 20 5.44772 20 6V10C20 10.5523 19.5523 11 19 11H16C15.4477 11 15 10.5523 15 10V6Z" stroke="#716D92" stroke-width={2} />
                                        <path d="M8 15C8 14.4477 7.55228 14 7 14H5C4.44772 14 4 14.4477 4 15V18C4 18.5523 4.44772 19 5 19H7C7.55228 19 8 18.5523 8 18V15Z" stroke="#716D92" stroke-width={2} />
                                    </svg>
                                </i>
                            Dashboard
                        </NavLink>
                        </li>
                        <li>
                            <NavLink to={WEB_SITES} className="nav-item">
                                <i>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 7C4 5.89543 4.89543 5 6 5H18C19.1046 5 20 5.89543 20 7V17C20 18.1046 19.1046 19 18 19H6C4.89543 19 4 18.1046 4 17V7Z" stroke="#716D92" stroke-width={2} />
                                        <circle cx={7} cy={8} r={1} fill="#716D92" />
                                        <path d="M4 11H20" stroke="#716D92" stroke-width={2} />
                                        <path d="M9 12V19" stroke="#716D92" stroke-width={2} />
                                        <path d="M20 15H10" stroke="#716D92" stroke-width={2} />
                                        <circle cx={10} cy={8} r={1} fill="#716D92" />
                                        <circle cx={13} cy={8} r={1} fill="#716D92" />
                                    </svg>
                                </i>
                            Websites
                        </NavLink>
                        </li>
                        <li>
                            <NavLink to={POLICIES_PAGE} className="nav-item">
                                <i>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.10995 8.09951C4.05108 7.51082 4.51337 7 5.10499 7H5.66667C7.18126 7 8.65499 6.50875 9.86667 5.6L10.8 4.9C11.5111 4.36667 12.4889 4.36667 13.2 4.9L14.1333 5.6C15.345 6.50875 16.8187 7 18.3333 7H18.895C19.4866 7 19.9489 7.51082 19.89 8.0995L19.5861 11.1388C19.2136 14.864 17.2532 18.2477 14.2067 20.4238L13.1625 21.1697C12.4671 21.6664 11.5329 21.6664 10.8375 21.1697L9.79326 20.4238C6.74683 18.2477 4.7864 14.864 4.41388 11.1388L4.10995 8.09951Z" stroke="#716D92" stroke-width={2} />
                                        <path d="M16 10L11 15L9 13" stroke="#716D92" stroke-width={2} />
                                    </svg>
                                </i>
                            Policies
                        </NavLink>
                        </li>
                        <li>
                            <a href="#" className="nav-item soon">
                                <i>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5 4.44338L18.2942 7.78867C18.6036 7.96731 18.7942 8.29743 18.7942 8.6547V15.3453C18.7942 15.7026 18.6036 16.0327 18.2942 16.2113L12.5 19.5566C12.1906 19.7353 11.8094 19.7353 11.5 19.5566L5.70577 16.2113C5.39637 16.0327 5.20577 15.7026 5.20577 15.3453V8.6547C5.20577 8.29743 5.39637 7.96731 5.70577 7.78868L11.5 4.44338C11.8094 4.26474 12.1906 4.26474 12.5 4.44338Z" stroke="#716D92" stroke-width={2} />
                                        <path d="M12 12L18.5 8" stroke="#716D92" stroke-width={2} />
                                        <path d="M5.5 8L12 12" stroke="#716D92" stroke-width={2} />
                                        <path d="M12 12V19.5" stroke="#716D92" stroke-width={2} />
                                    </svg>                                    </i>
                            SDK
                        </a>
                        </li>
                        <li>
                            <a href="#" className="nav-item soon">
                                <i>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 14L9 11L11 13L14 10" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17 16L20 19" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M19 11C19 14.866 15.866 18 12 18C8.13401 18 5 14.866 5 11C5 7.13401 8.13401 4 12 4C15.866 4 19 7.13401 19 11Z" stroke="#716D92" stroke-width={2} />
                                        <path d="M14.7359 10.3217L13.6783 9.26412L14.8681 9.13192L14.7359 10.3217Z" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </i>
                            Reports
                        </a>
                            {/*<NavLink to={REPORTS_PAGE} className="nav-item soon">
                            <i>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 14L9 11L11 13L14 10" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17 16L20 19" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M19 11C19 14.866 15.866 18 12 18C8.13401 18 5 14.866 5 11C5 7.13401 8.13401 4 12 4C15.866 4 19 7.13401 19 11Z" stroke="#716D92" stroke-width={2} />
                                    <path d="M14.7359 10.3217L13.6783 9.26412L14.8681 9.13192L14.7359 10.3217Z" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </i>
                            Reports
                        </NavLink>*/}
                        </li>
                        {
                            user.role_id == 1 &&
                            <li>
                                <NavLink to={USERS_PAGE} className="nav-item">
                                    <i>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 19V19C17 16.6742 15.3583 14.6717 13.0777 14.2155L12.9806 14.1961C12.3333 14.0667 11.6667 14.0667 11.0194 14.1961L10.9223 14.2155C8.64166 14.6717 7 16.6742 7 19V19" stroke="#716D92" stroke-width={2} strokeLinecap="round" />
                                            <circle cx={12} cy={8} r={3} stroke="#716D92" stroke-width={2} strokeLinecap="round" />
                                        </svg>
                                    </i>
                            Users
                        </NavLink>
                            </li>
                        }

                        <li>
                            <a href="#" className="nav-item soon">
                                <i>
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 7C8 7.82843 7.32843 8.5 6.5 8.5C5.67157 8.5 5 7.82843 5 7C5 6.17157 5.67157 5.5 6.5 5.5C7.32843 5.5 8 6.17157 8 7Z" fill="#716D92" />
                                        <path d="M8 12C8 12.8284 7.32843 13.5 6.5 13.5C5.67157 13.5 5 12.8284 5 12C5 11.1716 5.67157 10.5 6.5 10.5C7.32843 10.5 8 11.1716 8 12Z" fill="#716D92" />
                                        <path d="M8 17C8 17.8284 7.32843 18.5 6.5 18.5C5.67157 18.5 5 17.8284 5 17C5 16.1716 5.67157 15.5 6.5 15.5C7.32843 15.5 8 16.1716 8 17Z" fill="#716D92" />
                                        <path d="M18 7H11" stroke="#716D92" stroke-width={2} strokeLinecap="round" />
                                        <path d="M18 12H11" stroke="#716D92" stroke-width={2} strokeLinecap="round" />
                                        <path d="M18 17H11" stroke="#716D92" stroke-width={2} strokeLinecap="round" />
                                    </svg>
                                </i>
                            Plans
                        </a>
                        </li>
                              {/* TODO: Yayına atılacağı için kapatıldı. */}
                    {/* <li>
                        <NavLink exact to={STATISTICS_PAGE} className={`nav-item statistic`}>
                            <i>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.10557 17.5528C4.85858 18.0468 5.05881 18.6474 5.55279 18.8944C6.04676 19.1414 6.64744 18.9412 6.89443 18.4472L5.10557 17.5528ZM10 10L10.5547 9.16795C10.316 9.00884 10.0206 8.95988 9.74333 9.0335C9.46611 9.10712 9.23385 9.29624 9.10557 9.55279L10 10ZM16 14L15.4453 14.8321C15.7159 15.0124 16.0573 15.05 16.3606 14.9327C16.6639 14.8155 16.8913 14.558 16.9701 14.2425L16 14ZM18.9701 6.24254C19.1041 5.70674 18.7783 5.16381 18.2425 5.02986C17.7067 4.89591 17.1638 5.22167 17.0299 5.75746L18.9701 6.24254ZM6.89443 18.4472L10.8944 10.4472L9.10557 9.55279L5.10557 17.5528L6.89443 18.4472ZM9.4453 10.8321L15.4453 14.8321L16.5547 13.1679L10.5547 9.16795L9.4453 10.8321ZM16.9701 14.2425L18.9701 6.24254L17.0299 5.75746L15.0299 13.7575L16.9701 14.2425Z" fill="#3430FF" />
                                    <circle cx="6" cy="18" r="2" fill="#3430FF" />
                                    <circle cx="10" cy="10" r="2" fill="#3430FF" />
                                    <circle cx="16" cy="14" r="2" fill="#3430FF" />
                                    <circle cx="18" cy="6" r="2" fill="#3430FF" />
                                </svg>
                            </i>
                            Statistics
                        </NavLink>
                    </li> */}
                    </ul>
                </nav>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.userReducer.user
})

export default connect(mapStateToProps)(NavComponent);
