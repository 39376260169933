import React, { Component } from 'react';
import { Field, Form } from "react-final-form";
import DateButtonComponent from '../buttons/dateButtonComponent';
import SelectBoxComponent from '../formFields/selectBoxComponent';
import TextInputComponent from '../formFields/textInputComponent';
import { getDomainsAction } from '../../actions/domainActions';
import { connect } from 'react-redux';

class StatisticChoosingFormComponent extends Component {

    options = []
    initVal = {}
    stop = 0


    componentDidUpdate() {
        if(this.stop !== 1 && this.props.domains && this.props.domains.length > 0) {
            this.test()
            this.stop = 1

        }
    }

    test = () => {
        this.props.domains && this.props.domains.map((item,key)=>this.options.push({value:item.id,text:item.domain_name}))
        this.options.map((item,inx)=>item.value === parseInt(this.props.domainsId) ? this.initVal={website:item.value} : null)
        this.forceUpdate()
    }

    validate = (values) => {
        this.props.chanceDomainId(values.website)
    }

    submit = (values) => {

    }

    render() {
        // console.log(this.props.domains && this.props.domains);
        return (
            <div className='statistic-select-container'>
                <Form onSubmit={this.submit}
                    validate={this.validate}
                    initialValues={this.initVal }
                    render={({ handleSubmit }) => (
                        <form>
                            <div className="left-block">
                                <Field
                                    name={"website"}
                                    component={SelectBoxComponent}
                                    options={this.options}
                                    placeholder="Select Website"
                                />
                            </div>
                            <div className="right-block">
                                 {/* <Field
                                    name={"startDate"}
                                    type="date"
                                    component={TextInputComponent}
                                    id="date"
                                /> */}
{/*                                 
                                <DateButtonComponent text="Start date" onClick={() => document.getElementById("date").datePicker("show")}/>
                                <DateButtonComponent text="End date" /> */}
                            </div>
                        </form>
                    )}>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    domains: state.domainReducer.domains || []

})

export default connect(mapStateToProps)(StatisticChoosingFormComponent);