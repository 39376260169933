import React, {Component} from 'react';

class ModalComponent extends Component {
   render() {
      return (
          <div className="lightBox-root">
             <div className="lightBox-wrapper">
                <div className="lightBox-container">
                   <div className="head-item">
                      <h2 className="light-box-title">Change Password</h2>
                      <div className="close-icon" onClick={() => this.lightBox("close")}>
                         <i>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                               <path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5"
                                     stroke="black" stroke-width="2" strokeLinecap="round"
                                     strokeLinejoin="round"/>
                            </svg>

                         </i>
                      </div>
                   </div>
                   <div className="input-item">
                      <label htmlFor="" className="text-title-item">Site Name</label>
                      <input type="text"/>
                      <p className="text-desc-item">Lorem ipsum dolor sit amet, consectetur adipiscing
                         elit.</p>
                   </div>
                   <div className="langues-container">
                      <div className="langues-block">
                         <h2>Languages</h2>
                         <div className="input-item">
                            <label htmlFor="" className="text-title-item">English*</label>
                            <input type="text" placeholder="English"/>
                         </div>
                         <div className="input-item">
                            <label htmlFor="" className="text-title-item">Turkish*</label>
                            <input type="text" placeholder="Turkish"/>
                         </div>

                      </div>

                   </div>
                   <div className="btn-block">
                      <div className="btn-item cancel" onClick={() => this.lightBox("close")}>
                         <span>Cancel</span>
                      </div>
                      <div className="btn-item">
                         <span>Add Category</span>
                      </div>
                   </div>
                </div>
             </div>
          </div>
      );
   }
}

export default ModalComponent;
