import React, { Component } from 'react';
import AddButtonComponent from '../buttons/addButtonComponent';
import PolicyAudienceItemComponent from './policyAudienceItemComponent';
import PolicyAddAudienceLbxComponent from './policyAddAudienceLbxComponent';

class PolicyAudienceComponent extends Component {
    state = {
        type: "",
        sorted: false,
        isNewAudienceOpen: false
    }
    sorted = (item) => {
        this.setState({
            type: item,
            sorted: this.state.type === item ? !this.state.sorted : false
        })
    }

    toggleNewAudience = () => {
        this.setState({isNewAudienceOpen: !this.state.isNewAudienceOpen})
    }

    sortAudiences = (a, b) => {
        if (this.state.type === "regions") {
            return this.state.sorted === false ? (a.region_name > b.region_name ? 1 : b.region_name > a.region_name ? -1 : 0) : (a.region_name > b.region_name ? -1 : b.region_name > a.region_name ? 1 : 0)
        }
        if (this.state.type === "id") {
            return this.state.sorted === false ? (a.id > b.id ? 1 : b.id > a.id ? -1 : 0) : (a.id > b.id ? -1 : b.id > a.id ? 1 : 0)
        }
        if (this.state.type === "template") {
            return this.state.sorted === false ? (a.policy_id > b.policy_id ? 1 : b.policy_id > a.policy_id ? -1 : 0) : (a.policy_id > b.policy_id ? -1 : b.policy_id > a.policy_id ? 1 : 0)
        }
    }

    render() {
        let {domainArr, policyArr, getPolicies} = this.props;
        return (
            <div className='table-container'>
                <div className="table-head-block">
                    <div className="row-item">
                        <div className={`row-td region-td ${this.state.type === "regions" && this.state.sorted === true ? "up" : ""}`}>
                            <span onClick={() => { this.sorted("regions"); }}>Regions</span>

                        </div>
                        <div className={`row-td id-td ${this.state.sorted === true && this.state.type === "id" ? "up" : ""}`}>
                            <span onClick={() => this.sorted("id")}>ID</span>

                        </div>
                        <div className={`row-td template-td ${this.state.sorted === true && this.state.type === "template" ? "up" : ""}`}>
                            <span onClick={() => this.sorted("template")}>Template</span>
                        </div>
                        <div className="row-td button-td" onClick={() => {this.toggleNewAudience()}}>
                            <AddButtonComponent buttonName="New audience" />
                        </div>
                    </div>
                </div>
                <div className="table-block">
                        <div>
                            <PolicyAudienceItemComponent domainArr={domainArr} policyArr={policyArr} getPolicies={getPolicies} sortAudiences={this.sortAudiences}/>
                        </div>
                </div>
                {
                    this.state.isNewAudienceOpen &&
                    <PolicyAddAudienceLbxComponent toggleNewAudience={this.toggleNewAudience} isNewAudienceOpen={this.state.isNewAudienceOpen} domainArr={domainArr} policyArr={policyArr} getPolicies={getPolicies}/>
                }
            </div>
        );
    }
}

export default PolicyAudienceComponent;