import {RECEIVE_CATEGORY_LIST} from "../constants/actionTypes";

const initialStore = {
    categoryList:null
};

const CategoryReducer = (state = initialStore, action) => {
    switch (action.type) {
        case RECEIVE_CATEGORY_LIST:
            return {
                categoryList:action.payload
            };
        default :
            return state;
    }
};

export default CategoryReducer;