import React, { Component } from 'react';
import { ASSETS } from '../../constants/Paths';
import { Chart } from "react-google-charts";



class StatisticChartComponent extends Component {
     data = [
        ["Task", "Hours per Day"],
        ["Total", 50],
        ["Social", 20],
        ["Analistic", 10],
        ["Requered", 10],
    
    ];
    
   
    
     options = {
        pieSliceText: 'none',
        legend: 'none',
        pieHole: 0.5,
        slices: {
            0: { color: "#"+this.props.foeignColor },
            1: { color: "#"+this.props.socialColor },
            2: { color: "#"+this.props.analistColor },
            3: { color: "#"+this.props.requareColor }


        },
        chartArea:{left:0,top:0,width:'100%',height:'100%'},
        enableInteractivity: false,
    };
    render() {

        return (
            <div className='statistic-chart-item'>

                <Chart
                    chartType="PieChart"
                    data={this.props.data}
                    options={this.options}
                    width={"182px"}
                    height={"182px"}
                />
            </div>
        );
    }
}

export default StatisticChartComponent;