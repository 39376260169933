import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {LOGIN_PAGE, REGISTER_PAGE} from "../constants/routePaths";
import RegisterFormComponent from "../components/forms/registerFormComponent";
import CoverComponent from "../components/common/coverComponent";
import LogoComponent from "../components/common/logoComponent";



class Faz2registerPage extends Component {
    state = {

    }







    render() {


        return (
            <div className="login-root register-root">
                <div className="login-wrapper">
                    <div className="login-left-wrapper">
                        <LogoComponent/>
                        <div className="login-content-container">
                            <div className="login-container">
                                <div className="title-block">
                                    <NavLink exact to={LOGIN_PAGE} className={`title-item`}>
                                        <div>
                                            <h3>Login</h3>
                                        </div>
                                    </NavLink>
                                    <div className="title-item active">
                                        <h3>Register</h3>
                                    </div>
                                </div>
                                <RegisterFormComponent/>
                                <div className="bottom-btn-block">
                                    <div className="text-item">
                                        <span>I have an account</span>
                                    </div>
                                    <NavLink exact to={LOGIN_PAGE} className={`btn-item white-btn`}>
                                        <span>Login</span>
                                    </NavLink>

                                </div>

                            </div>
                        </div>
                    </div>
                    <CoverComponent/>



                </div>

            </div>



        );
    }
}

export default Faz2registerPage;
