import React, { Component } from 'react';

class LanguageTextInputComponent extends Component {
    render() {
        const {input, placeholder, meta: { submitFailed, error, touched } } = this.props;
        return (
            <input {...input} placeholder={placeholder} />
        )
    }
};

export default LanguageTextInputComponent;
