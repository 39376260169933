import React, {Component} from 'react';
import {Field, Form} from "react-final-form";
import TextInputComponent from "../formFields/textInputComponent";
import RecaptchaFieldComponent from "../formFields/recaptchaFieldComponent";
import {signinAction} from "../../actions";
import {connect} from "react-redux";
import {DASHBOARD_PAGE,LOGIN_PAGE} from "../../constants/routePaths";

import {withRouter} from "react-router-dom";
import LoaderComponent from "../loaderComponent";
import  {getRegisterUser,saveRegisterUser} from "../../services/webServices";
import RegisterInvSuccesComponent from '../registerInvSuccesComponent';

const validate = (values) => {
    const errors = {};
  
    if (!values || !values.password || values.password === ""|| values.password < 6 ||  !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/) {
        errors.password = "Şifre giriniz";
    }
    if (!values || !values.password_again || values.password_again === "") {
        errors.password_again = "Şifre giriniz";
    }
    if (!values || !values.name || values.name === "") {
        errors.name = "Ad giriniz";
    }
    if (!values || !values.surname || values.surname === "") {
        errors.surname = "Soyad giriniz";
    }
    if (!values.recaptcha_code || values.recaptcha_code.trim().length === 0) {
        errors.recaptcha_code = true
    }
    console.log(errors)
    return errors;
};

class RegisterinvFormComponent extends Component {
    state = {
        isLoading: false,
        success:false
    }
   componentDidMount(){
        getRegisterUser({user_id:this.props.match.params.userId,token:this.props.match.params.token}).then((result)=>{
            if(result.data.success){

            }else{
                this.props.history.replace(LOGIN_PAGE)
            }
        })
        

   }
   saveRegister = () =>{
    this.setState({
        success:true
    });
    
    
}
    submit = (values) => {
        this.setState({
            isLoading: true
        })
        saveRegisterUser({user_id:this.props.match.params.userId,token:this.props.match.params.token,userInfo:{name:values.name,surname:values.surname,password:values.password}}).then((result)=>{
            if(result.data.success){
               this.saveRegister()
            }else{
                this.setState({
                    error:true
                })
                this.hideErrorMsg();
            }
        }).catch((err)=>{
            this.setState({
                error:true
            })
            this.hideErrorMsg();
        })
        this.props.dispatch(signinAction(values, (success) => {
            if (success) {
                this.setState({
                    isLoading: false
                })
                this.props.history.push(DASHBOARD_PAGE)
            } else {
                this.setState({
                    isLoading: false
                })
            }
        }))
    }
    hideErrorMsg = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(()=>{
            this.setState({
                error:false
            })
        },2000)
    }
    render() {
        let {isLoading , success} = this.state;
        return (
            <div>
                {
                    isLoading && 
                    <LoaderComponent/>                 
                }
                {
                    success === true ? 
                    <RegisterInvSuccesComponent/>
                    :
                    <Form onSubmit={this.submit}
                    validate={validate}
                    render={({handleSubmit}) => (
                        <form onSubmit={handleSubmit}>
                            <div className="input-block">
                                <Field
                                    name={"name"}
                                    type="text"
                                    label="Name*"
                                    component={TextInputComponent}
                                    err_txt="Please enter name"
                                />

                                <Field
                                    name={"surname"}
                                    type="text"
                                    label="Surname*"
                                    component={TextInputComponent}
                                    err_txt="Please enter surname"
                                />
                            </div>
                            <div className="input-block">
                                <Field
                                    name={"password"}
                                    type="password"
                                    label="Password*"
                                    component={TextInputComponent}
                                    err_txt="Please enter password"
                                />
                                {/*TODO: password again için eşleşme kontrolü yapılmalı.*/}
                                <Field
                                    name={"password_again"}
                                    type="password"
                                    label="Password Again*"
                                    component={TextInputComponent}
                                    err_txt="Passwords do not match."

                                />
                            </div>
                            <Field
                                name="recaptcha_code"
                                component={RecaptchaFieldComponent}
                            />
                            <div className="btn-block">
                                <button type="submit" className="btn-item">
                                    Register
                                </button>
                            </div>
                        </form>
                    )}>

              </Form>
                }
          
             
            </div>
        );
    }
}

export default withRouter(connect()(RegisterinvFormComponent));
