import React, { Component } from 'react'

function SucsessComponent(props) {
    if (!props.warn) {
        return null;
    }

    return (
        <div className={` alert-box-container ${props.class && props.class}`} >
            <div className="alert-box-block">
                <div className="img-item">
                    <i>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#E1FCDE" />
                            <circle cx="16" cy="16" r="8" stroke="#2ACF1C" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13 17L15 19L20 14" stroke="#2ACF1C" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                    </i>
                </div>
                <div className="text-item">
                    <h2>Succesfull</h2>
                    <span>{props.message || "Policy succesfuly added."}</span>
                </div>

            </div>

        </div>
    );
}
// class SucsessComponent extends Component {
//     render() {
//         return (
//             <div className="alert-box-container ">
//                 <div className="alert-box-block">
//                     <div className="img-item">
//                         <i>
//                             <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                 <circle cx="16" cy="16" r="16" fill="#E1FCDE" />
//                                 <circle cx="16" cy="16" r="8" stroke="#2ACF1C" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
//                                 <path d="M13 17L15 19L20 14" stroke="#2ACF1C" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
//                             </svg>

//                         </i>
//                     </div>
//                     <div className="text-item">
//                         <h2>Succesfull</h2>
//                         <span>Policy succesfuly added.</span>
//                     </div>

//                 </div>

//             </div>

//         )
//     }
// }

export default SucsessComponent;
