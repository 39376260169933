import React, { Component } from "react";
import { Field, Form } from "react-final-form";
import { WEB_SITE_DETAIL_WITHOUT_PARAM } from "../../constants/routePaths";
import { createNewDomain } from "../../services/webServices";
import LanguageTextInputComponent from "../formFields/languageTextInputComponent";
import {required} from "../../helper"
import ErrorComponent from "../layout/errorComponent";

class AddNewWebsiteComponent extends Component {
    state={
        error:false
    }
    submit = (values) => {
        createNewDomain(values).then((result)=>{
            
            if(result.data.success){
                this.props.history.push(WEB_SITE_DETAIL_WITHOUT_PARAM+result.data.success+"/site-info")
            }else{
                this.setState({
                    error:true
                })
                clearTimeout(this.timer)
                this.timer = setTimeout(()=>{
                    this.setState({
                        error:false
                    })
                },2000)
            }
        }).catch((err)=>{
            console.log("err");
        })
    }
    render() {
        return (
            <Form
                onSubmit={this.submit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="lightBox-root add-site-root">
                        <div className="lightBox-wrapper">
                            <div className="lightBox-container">
                                <div className="head-item">
                                    <h2 className="light-box-title">Add Site</h2>
                                    <div className="close-icon" onClick={() => this.props.lightBox("close")}>
                                        <i>
                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5"
                                                    stroke="black" stroke-width="2" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                            </svg>

                                        </i>
                                    </div>
                                </div>

                                <div className="langues-container">
                                    <div className="langues-block">

                                        <div className="input-item">
                                            <label htmlFor="" className="text-title-item">Sitename</label>
                                            <Field
                                                name={"name"}
                                                type="text"
                                                component={LanguageTextInputComponent}
                                                validate={required}
                                            />
                                        </div>
                                        <div className="input-item">
                                            <label htmlFor="" className="text-title-item">Url</label>
                                            <Field
                                                name={"url"}
                                                type="text"
                                                component={LanguageTextInputComponent}
                                                validate={required}
                                            />
                                        </div>
                                        {/* <div className="input-item">

                                            <label htmlFor="" className="text-title-item">Cookie Policy Url</label>
                                            <Field
                                                name={"cookie_policy_link"}
                                                type="text"
                                                component={LanguageTextInputComponent}
                                                validate={required} />
                                        </div> */}

                                    </div>

                                </div>
                                <div className="btn-block">
                                    <div className="btn-item cancel" onClick={() => this.props.lightBox("close")}>
                                        <span>Cancel</span>
                                    </div>
                                    <button type="submit" className="btn-item">
                                        <span>Add Site and continue</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <ErrorComponent warn={this.state.error} />
                    </form>)}
            />
        )
    }
};

export default AddNewWebsiteComponent;