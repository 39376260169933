import React, { Component } from 'react';

class TextAreaInputComponent extends Component {
    render() {
        const {forgotPass,input,messageText, label, disabled, placeholder, type, err_txt, meta: { submitFailed, error, touched } } = this.props;
        return (
            <textarea placeholder={placeholder} rows="4" {...input} ></textarea>
        )
    }
};

export default TextAreaInputComponent;
