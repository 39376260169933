import React, { Component } from 'react';

class SccriptAsideMenu extends Component {
    render() {
        return (
            <div className="scripts-detail-aside-menu">
                        <div className={`aside-item ${this.props.openBtn === "general" ? "active":""}`}  onClick={()=>{this.props.echangeBtn("general")}}>
                            <span>General </span>
                        </div>
                        <div className={`aside-item ${this.props.openBtn === "hide" ? "active":""}`} onClick={()=>{this.props.echangeBtn("hide")}}>
                            <span>Hide Button </span>
                        </div>

                        <div className={`aside-item ${this.props.openBtn === "accept" ? "active":""}`} onClick={()=>{this.props.echangeBtn("accept")}}>
                            <span>Accept All Button </span>
                        </div>
                        <div className={`aside-item ${this.props.openBtn === "reject" ? "active":""}`} onClick={()=>{this.props.echangeBtn("reject")}}>
                            <span>Reject All Button </span>
                        </div>
                        <div className={`aside-item ${this.props.openBtn === "cookieSet" ? "active":""}`} onClick={()=>{this.props.echangeBtn("cookieSet")}}>
                            <span>Settings Button </span>
                        </div>
                        <div className={`aside-item ${this.props.openBtn === "save" ? "active":""}`} onClick={()=>{this.props.echangeBtn("save")}}>
                            <span>Save and Close Button </span>
                        </div>
                    </div>
        );
    }
}

export default SccriptAsideMenu;
