import React, {Component, Fragment} from "react";
import { WEB_SITE_SITE_INFO } from "../../constants/routePaths";
import AddButtonComponent from "../buttons/addButtonComponent";
import BreadcrumbComponent from "../layout/breadcrumbComponent";




class CompanyListComponent extends Component {
    state = {
        lightBox: "close"
    }
    lightBox = (lightBox) => {
        this.setState({
            lightBox: lightBox
        })

    }
    render() {



        return (
            <div className="content-root company-root">
             <BreadcrumbComponent  breadName="Company List"/>
                <div className="content-wrapper">

                    <div className={`content-block ${this.state.lightBox==="open"?"addCompany":""}`}>
                        <div className="table-container">
                            <div className="table-head-block">
                                <div className="row-item">
                                    <div className="row-td name-td">
                                        <span>Company Name</span>
                                        <div className="search-block">
                                            <div className="input-item">
                                                <input type="search" placeholder="Search" />
                                                <i className="icon-item">
                                                    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="7.5" cy="7.5" r="4.5" stroke="#A4A1C0" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M11 11L13 13" stroke="#A4A1C0" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-td number-td">
                                        <span>Unique Comp. Code</span>
                                    </div>
                                    <div className="row-td status-td">
                                        <span>Owner Email</span>
                                    </div>
                                    <div className="row-td button-td"  onClick={()=> this.lightBox("open")}>
                                        <AddButtonComponent buttonName = "New Company"/>
                                    </div>
                                </div>
                            </div>
                            <div className="table-block">


                                <div className="row-item">
                                    <div className="row-td name-td">
                                        <span>Gülenayva</span>
                                    </div>
                                    <div className="row-td number-td">
                                        <span>gulenayva</span>
                                    </div>
                                    <div className="row-td status-td">
                                        <span>ahmet@gulenayva.com</span>
                                        <i>
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 1L0.999999 1L1 9C4.12419 9 5.87581 9 9 9L9 6" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.70711 2.70711C9.09763 2.31658 9.09763 1.68342 8.70711 1.29289C8.31658 0.902369 7.68342 0.902369 7.29289 1.29289L8.70711 2.70711ZM7.29289 1.29289L2.29289 6.29289L3.70711 7.70711L8.70711 2.70711L7.29289 1.29289Z" fill="black"/>
    <path d="M8.73588 2.32167L7.67833 1.26412L8.86808 1.13192L8.73588 2.32167Z" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
                                        </i>
                                    </div>
                                    <div className="row-td button-td">
                                        <div className="create-btn" onClick={() => {
                                            /*this.props.history.push(WEB_SITE_DETAIL_WITHOUT_PARAM+"1")*/
                                            this.props.history.push(WEB_SITE_SITE_INFO)
                                        }}>
                                            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width={32} height={32} fill="white" />
                                                <path d="M14 18L17 17L23.5858 10.4142C23.851 10.149 24 9.78929 24 9.41421C24 8.63317 23.3668 8 22.5858 8C22.2107 8 21.851 8.149 21.5858 8.41421L15 15L14 18Z" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16 9H11C9.89543 9 9 9.89543 9 11V21C9 22.1046 9.89543 23 11 23H21C22.1046 23 23 22.1046 23 21V16" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>

                                    </div>
                                </div>
                                <div className="row-item">
                                    <div className="row-td name-td">
                                        <span>Honda</span>
                                    </div>
                                    <div className="row-td number-td">
                                        <span>honda</span>
                                    </div>
                                    <div className="row-td status-td">
                                        <span>ahmet@gulenayva.com</span>
                                        <i>
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 1L0.999999 1L1 9C4.12419 9 5.87581 9 9 9L9 6" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.70711 2.70711C9.09763 2.31658 9.09763 1.68342 8.70711 1.29289C8.31658 0.902369 7.68342 0.902369 7.29289 1.29289L8.70711 2.70711ZM7.29289 1.29289L2.29289 6.29289L3.70711 7.70711L8.70711 2.70711L7.29289 1.29289Z" fill="black"/>
    <path d="M8.73588 2.32167L7.67833 1.26412L8.86808 1.13192L8.73588 2.32167Z" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
                                        </i>
                                    </div>
                                    <div className="row-td button-td">
                                        <div className="create-btn" onClick={() => {
                                            /*this.props.history.push(WEB_SITE_DETAIL_WITHOUT_PARAM+"1")*/
                                            this.props.history.push(WEB_SITE_SITE_INFO)
                                        }}>
                                            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width={32} height={32} fill="white" />
                                                <path d="M14 18L17 17L23.5858 10.4142C23.851 10.149 24 9.78929 24 9.41421C24 8.63317 23.3668 8 22.5858 8C22.2107 8 21.851 8.149 21.5858 8.41421L15 15L14 18Z" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16 9H11C9.89543 9 9 9.89543 9 11V21C9 22.1046 9.89543 23 11 23H21C22.1046 23 23 22.1046 23 21V16" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>

                                    </div>
                                </div>
                                <div className="row-item">
                                    <div className="row-td name-td">
                                        <span>Vitra</span>
                                    </div>
                                    <div className="row-td number-td">
                                        <span>vitra</span>
                                    </div>
                                    <div className="row-td status-td">
                                        <span>ahmet@gulenayva.com</span>
                                        <i>
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 1L0.999999 1L1 9C4.12419 9 5.87581 9 9 9L9 6" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.70711 2.70711C9.09763 2.31658 9.09763 1.68342 8.70711 1.29289C8.31658 0.902369 7.68342 0.902369 7.29289 1.29289L8.70711 2.70711ZM7.29289 1.29289L2.29289 6.29289L3.70711 7.70711L8.70711 2.70711L7.29289 1.29289Z" fill="black"/>
    <path d="M8.73588 2.32167L7.67833 1.26412L8.86808 1.13192L8.73588 2.32167Z" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
                                        </i>
                                    </div>
                                    <div className="row-td button-td">
                                        <div className="create-btn" onClick={() => {
                                            /*this.props.history.push(WEB_SITE_DETAIL_WITHOUT_PARAM+"1")*/
                                            this.props.history.push(WEB_SITE_SITE_INFO)
                                        }}>
                                            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width={32} height={32} fill="white" />
                                                <path d="M14 18L17 17L23.5858 10.4142C23.851 10.149 24 9.78929 24 9.41421C24 8.63317 23.3668 8 22.5858 8C22.2107 8 21.851 8.149 21.5858 8.41421L15 15L14 18Z" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16 9H11C9.89543 9 9 9.89543 9 11V21C9 22.1046 9.89543 23 11 23H21C22.1046 23 23 22.1046 23 21V16" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lightBox-root user">
                            <div className="lightBox-wrapper">
                                <div className="lightBox-container">
                                    <div className="head-item">
                                        <h2 className="light-box-title">Add Company</h2>
                                        <div className="close-icon" onClick={()=> this.lightBox("close")}>
                                            <i>
                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

                                            </i>
                                        </div>
                                    </div>
                                    <div className="input-item">
                                    <label htmlFor="" className="text-title-item">Company Name</label>
                                    <input type="text"/>

                                    </div>
                                    <div className="user-block">
                                        <div className="input-item">
                                        <label htmlFor="" className="text-title-item">Unique Company Name</label>
                                        <input type="text" placeholder="Unique Company Name"/>

                                        </div>
                                        <div className="input-item">
                                        <label htmlFor="" className="text-title-item">Owner Account Email</label>
                                        <input type="text" placeholder="Owner@owner.com"/>

                                        </div>

                                    </div>
                                    <div className="btn-block">
                                        <div className="btn-item cancel" onClick={()=> this.lightBox("close")}>
                                            <span>Cancel</span>
                                        </div>
                                        <div className="btn-item">
                                            <span>Add Company</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default CompanyListComponent;
