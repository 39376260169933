import {
    RECEIVE_USER_DOMAINS,
    RECEIVE_DOMAIN_DETAIL,
    CHANGE_COOKIE_CATEGORY,
    CHANGE_DOMAIN_SANDBOX_MODE,
    SEARCH_DOMAIN_BY_NAME, RECEIVE_SITE_INFO,
    RECEIVE_PRE_SITE_INFO,
    RECEIVE_COOKIE_LIST,
    DELETE_COOKIE_LIST,
    RECEIVE_UNCATEGORIZED_COOKIE_LIST,
    DELETE_UNCATEGORIZED_COOKIE_LIST
} from "../constants/actionTypes";

const initialStore = {
    domains: [],
    domainDetails: {},
    filteredDomains: []
};

const domainReducer = (state = initialStore, action) => {
    switch (action.type) {
        case RECEIVE_USER_DOMAINS:
            return {
                ...state,
                filteredDomains: action.payload,
                domains: action.payload
            };
        case RECEIVE_DOMAIN_DETAIL:
            return {
                ...state,
                domainDetails: {
                    ...state.domainDetails,
                    ...action.payload
                }
            };
        case RECEIVE_SITE_INFO:
            return {
                ...state,
                siteInfo: {
                    ...state.siteInfo,
                    ...action.payload
                }
            };
        // case CHANGE_COOKIE_CATEGORY:
        //     let oldCookies = state.domainDetails[action.domainId].cookies;
        //     let changeCookieInx = oldCookies.findIndex(item => item.cookie_id == action.cookieId);
        //     return {
        //         ...state,
        //         domainDetails: {
        //             ...state.domainDetails,
        //             [action.domainId]: {
        //                 ...state.domainDetails[action.domainId],
        //                 cookies: [
        //                     ...oldCookies.slice(0, changeCookieInx),
        //                     {
        //                         ...state.domainDetails[action.domainId].cookies[changeCookieInx],
        //                         cookie: {
        //                             ...state.domainDetails[action.domainId].cookies[changeCookieInx].cookie,
        //                             cookie_category_id: action.newCategory.id,
        //                             cookie_category: action.newCategory
        //                         }
        //                     },
        //                     ...oldCookies.slice(changeCookieInx + 1)
        //                 ]
        //             }
        //         }
        //     };
        case RECEIVE_PRE_SITE_INFO:
            return {
                ...state,
                preSiteInfo: {
                    ...state.preSiteInfo,
                    ...action.payload
                }
            };
        case CHANGE_DOMAIN_SANDBOX_MODE:
            return {
                ...state,
                domainDetails: {
                    ...state.domainDetails,
                    [action.domainId]: {
                        ...state.domainDetails[action.domainId],
                        is_sandbox: action.is_sandbox
                    }
                }
            };
        case SEARCH_DOMAIN_BY_NAME:
            return {
                ...state,
                filteredDomains: action.payload
            };

        case RECEIVE_COOKIE_LIST:
            return {
                ...state,
                cookies: action.payload
            };
        case DELETE_COOKIE_LIST:
            let index = state.cookies && state.cookies.findIndex((x) => x.id === action.payload.id)   
            if(index === -1) {
                return{
                    ...state,
                    cookies: state.cookies
                }
            }else{
                return {
                    ...state,
                    cookies: [...state.cookies.slice(0, index), ...state.cookies.slice(index + 1)]
                };
            }
            case RECEIVE_UNCATEGORIZED_COOKIE_LIST:
                return {
                    ...state,
                    unCategorizedCookies: action.payload
                };
            case DELETE_UNCATEGORIZED_COOKIE_LIST:
                let indexUn = state.unCategorizedCookies && state.unCategorizedCookies.findIndex((x) => x.id === action.payload.id)   
                if(indexUn === -1) {
                    return{
                        ...state,
                        unCategorizedCookies: state.unCategorizedCookies
                    }
                }else{
                    return {
                        ...state,
                        unCategorizedCookies: [...state.unCategorizedCookies.slice(0, indexUn), ...state.unCategorizedCookies.slice(indexUn + 1)]
                    };
                }
        default:
            return state;
    }
};

export default domainReducer;
