import {signin, activeUser, logout} from "../services/webServices"
import {RECEIVE_SIGNED_USER} from "../constants/actionTypes";

const reciveSignInUser = (payload) => ({
    type: RECEIVE_SIGNED_USER,
    payload
})

export const signinAction = (params,cb) => dispatch => {

    signin(params).then((res)=>{
        localStorage.setItem("failToken",res.data)
        if(res.data.success){
            dispatch(reciveSignInUser(res.data.success));
            localStorage.setItem("token",res.data.success.accesstoken)
            cb(true);
        }else{
            cb(false);
        }
    }).catch((err)=>{
        cb(false);
        console.log("err",err)
    })
}

export const activeUserAction = (params) => dispatch => {
    activeUser(params).then((res)=>{
        if(res.data.success){
            dispatch(reciveSignInUser(res.data.success));
        }else{
            dispatch(reciveSignInUser(false));
            localStorage.setItem("token",null)
        }
    }).catch((err)=>{
        dispatch(reciveSignInUser(false));
        localStorage.setItem("token",null)
        console.log("err",err)
    })
}

export const logoutAction = (cb) => dispatch => {
    logout().then((res)=>{
        dispatch(reciveSignInUser(false));
        cb&&cb(true)
    }).catch((err)=>{
        dispatch(reciveSignInUser(false));
        localStorage.setItem("token",null)
        console.log("err",err)
        cb&&cb(false)
    })
}
