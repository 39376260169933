import React, {Component} from 'react';

class PasswordRequirementsComponent extends Component {

    render() {
        return(
            <div className="pass-prop-menu">
                <div className="head-block">
                    <p>Password Requirements:</p>
                </div>
                <div className="content-block">
                    <div className="content-item tic">
                        <div className="tic-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none">
                                <path d="M1 3.12114L2.91757 5L7 1" stroke="black" stroke-width="2" strokeLinecap="round"/>
                            </svg>
                        </div>
                        <div className="fail-item">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="8" height="8" rx="4" stroke="#8C9196" stroke-width="2"/>
                            </svg>
                        </div>
                        <div className="text-item">
                            <span>Minimum 8 character</span>
                        </div>
                    </div>
                    <div className="content-item tic">
                        <div className="tick-item">
                            <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.12114L2.91757 5L7 1" stroke="black" stroke-width="2" strokeLinecap="round"/>
                            </svg>
                        </div>
                        <div className="fail-item">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="8" height="8" rx="4" stroke="#8C9196" stroke-width="2"/>
                            </svg>
                        </div>
                        <div className="text-item">
                            <span>1 uppercase letter </span>
                        </div>
                    </div>
                    <div className="content-item fail">
                        <div className="tic-item">
                            <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.12114L2.91757 5L7 1" stroke="black" stroke-width="2" strokeLinecap="round"/>
                            </svg>
                        </div>
                        <div className="fail-item">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="8" height="8" rx="4" stroke="#8C9196" stroke-width="2"/>
                            </svg>
                        </div>
                        <div className="text-item">
                            <span>1 special character  (-, !, /, +, &)</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PasswordRequirementsComponent;
