import React, { Component } from "react";
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";
import { categories } from "../../constants/const";
import { POLICIES_PAGE } from "../../constants/routePaths";
import { required } from "../../helper";
import { addNewPoliciyToAccount } from "../../services/webServices";
import CheckboxComponent from "../formFields/checkboxComponent";
import LanguageTextInputComponent from "../formFields/languageTextInputComponent";
import MultiCheckboxComponent from "../formFields/multiCheckboxComponent";

import ErrorComponent from "../layout/errorComponent";
import RadioInputComponent from "../formFields/radioInputComponent";

import DayTextInputComponent from "../formFields/dayTextInputComponent";

class PolicyFormComponent extends Component {
  state = {
    isloaderShowing: false,
    success: false,
    error: false,
  };
  submit = (values) => {
    this.setState({
      isloaderShowing: true,
    });
    values.enable_categories = values.enable_categories
      ? values.enable_categories
      : [categories[0].id.toString()];
    values.select_categories = values.select_categories
      ? values.select_categories
      : [categories[0].id.toString()];
    addNewPoliciyToAccount({
      ...values,
      id: this.props.detail ? this.props.detail.id : null,
    })
      .then((result) => {
        if (result.data.success) {
          this.props.history.push(POLICIES_PAGE);
        } else {
          this.setState({
            error: true,
            isloaderShowing: false,
          });
          clearTimeout(this.timer);
          setTimeout(() => {
            this.setState({
              error: false,
            });
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    let { detail } = this.props;
    return (
      <Form
        onSubmit={this.submit}
        initialValues={{
          name: detail ? detail.name : null,
          enable_categories: detail
            ? detail.enable_categories.split(",")
            : [categories[0].id.toString()],
          is_reject_showing: detail ? detail.is_reject_showing : true,
          is_close_showing: detail ? detail.is_close_showing : true,
          select_categories: detail
            ? detail.select_categories.split(",")
            : [categories[0].id.toString()],
          button_direction: detail ? detail.button_direction : "left_bottom",
          abroad_server_showing: detail ? detail.abroad_server_showing : false,
          button_scroll_animation: detail
            ? detail.button_scroll_animation
            : true,
          allow_unknow_cookies: detail ? detail.allow_unknow_cookies : true,
          button_size: detail ? detail.button_size : true,
          expired_day: detail ? detail.expired_day : 360,
          reset_on_rejectall: detail ? detail.reset_on_rejectall : false,
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="policy-add-block">
              <div className="form-item">
                <div className="input-item">
                  <label htmlFor="" className="text-title-item">
                    Policy Template Name
                  </label>
                  <Field
                    name={"name"}
                    type="text"
                    component={LanguageTextInputComponent}
                    validate={required}
                  />
                </div>
              </div>
              <div className="form-item ">
                <h3>General Settings</h3>

                <h2>
                  Reset User Selection On (days)
                  <div className="tips-item form">
                    <i>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="14"
                          height="14"
                          rx="7"
                          fill="black"
                          fillOpacity="0.7"
                        />
                        <path
                          d="M5.8892 8.86222H7.07173V8.76989C7.07884 8.01705 7.32386 7.67259 7.9027 7.31747C8.55611 6.92685 8.96449 6.39418 8.96449 5.58097C8.96449 4.38068 8.03054 3.62784 6.67401 3.62784C5.43111 3.62784 4.42259 4.31676 4.38707 5.66619H5.65128C5.68324 4.99858 6.16619 4.67898 6.6669 4.67898C7.21023 4.67898 7.64702 5.04119 7.64702 5.59872C7.64702 6.09588 7.31676 6.43679 6.88707 6.70668C6.25852 7.0973 5.89631 7.49148 5.8892 8.76989V8.86222ZM6.51065 11.0781C6.92969 11.0781 7.28835 10.7301 7.2919 10.2969C7.28835 9.87074 6.92969 9.52273 6.51065 9.52273C6.07741 9.52273 5.72585 9.87074 5.7294 10.2969C5.72585 10.7301 6.07741 11.0781 6.51065 11.0781Z"
                          fill="white"
                        />
                      </svg>
                    </i>
                    <p>Reset User Selection On (days).</p>
                  </div>
                </h2>
                <Field name={"expired_day"} component={DayTextInputComponent} />

                <h2>
                  Enable cookies on load
                  <div className="tips-item form">
                    <i>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="14"
                          height="14"
                          rx="7"
                          fill="black"
                          fillOpacity="0.7"
                        />
                        <path
                          d="M5.8892 8.86222H7.07173V8.76989C7.07884 8.01705 7.32386 7.67259 7.9027 7.31747C8.55611 6.92685 8.96449 6.39418 8.96449 5.58097C8.96449 4.38068 8.03054 3.62784 6.67401 3.62784C5.43111 3.62784 4.42259 4.31676 4.38707 5.66619H5.65128C5.68324 4.99858 6.16619 4.67898 6.6669 4.67898C7.21023 4.67898 7.64702 5.04119 7.64702 5.59872C7.64702 6.09588 7.31676 6.43679 6.88707 6.70668C6.25852 7.0973 5.89631 7.49148 5.8892 8.76989V8.86222ZM6.51065 11.0781C6.92969 11.0781 7.28835 10.7301 7.2919 10.2969C7.28835 9.87074 6.92969 9.52273 6.51065 9.52273C6.07741 9.52273 5.72585 9.87074 5.7294 10.2969C5.72585 10.7301 6.07741 11.0781 6.51065 11.0781Z"
                          fill="white"
                        />
                      </svg>
                    </i>
                    <p>
                      On first load, categories that will be allowed after tool
                      loaded.
                    </p>
                  </div>
                </h2>
                <Field
                  name={"enable_categories"}
                  type="text"
                  //initialValue={[categories[0].id.toString()]}
                  options={categories}
                  component={MultiCheckboxComponent}
                />
                <h2>
                  “This cookie source can store data to abroad server.” button
                  <div className="tips-item form">
                    <i>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="14"
                          height="14"
                          rx="7"
                          fill="black"
                          fillOpacity="0.7"
                        />
                        <path
                          d="M5.8892 8.86222H7.07173V8.76989C7.07884 8.01705 7.32386 7.67259 7.9027 7.31747C8.55611 6.92685 8.96449 6.39418 8.96449 5.58097C8.96449 4.38068 8.03054 3.62784 6.67401 3.62784C5.43111 3.62784 4.42259 4.31676 4.38707 5.66619H5.65128C5.68324 4.99858 6.16619 4.67898 6.6669 4.67898C7.21023 4.67898 7.64702 5.04119 7.64702 5.59872C7.64702 6.09588 7.31676 6.43679 6.88707 6.70668C6.25852 7.0973 5.89631 7.49148 5.8892 8.76989V8.86222ZM6.51065 11.0781C6.92969 11.0781 7.28835 10.7301 7.2919 10.2969C7.28835 9.87074 6.92969 9.52273 6.51065 9.52273C6.07741 9.52273 5.72585 9.87074 5.7294 10.2969C5.72585 10.7301 6.07741 11.0781 6.51065 11.0781Z"
                          fill="white"
                        />
                      </svg>
                    </i>
                    <p className="tips-form-item">
                      If you stores your client data in abroad server and you
                      want to leave it to user preference. You can show this
                      option.
                    </p>
                  </div>
                </h2>
                <div className="radio-item">
                  <Field
                    name={"abroad_server_showing"}
                    type="text"
                    options={[
                      { value: true, name: "Show" },
                      { value: false, name: "Hide" },
                    ]}
                    component={RadioInputComponent}
                  />
                </div>
                <h2>
                  “This cookie source can store data to abroad server.” button
                  <div className="tips-item form">
                    <i>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="14"
                          height="14"
                          rx="7"
                          fill="black"
                          fillOpacity="0.7"
                        />
                        <path
                          d="M5.8892 8.86222H7.07173V8.76989C7.07884 8.01705 7.32386 7.67259 7.9027 7.31747C8.55611 6.92685 8.96449 6.39418 8.96449 5.58097C8.96449 4.38068 8.03054 3.62784 6.67401 3.62784C5.43111 3.62784 4.42259 4.31676 4.38707 5.66619H5.65128C5.68324 4.99858 6.16619 4.67898 6.6669 4.67898C7.21023 4.67898 7.64702 5.04119 7.64702 5.59872C7.64702 6.09588 7.31676 6.43679 6.88707 6.70668C6.25852 7.0973 5.89631 7.49148 5.8892 8.76989V8.86222ZM6.51065 11.0781C6.92969 11.0781 7.28835 10.7301 7.2919 10.2969C7.28835 9.87074 6.92969 9.52273 6.51065 9.52273C6.07741 9.52273 5.72585 9.87074 5.7294 10.2969C5.72585 10.7301 6.07741 11.0781 6.51065 11.0781Z"
                          fill="white"
                        />
                      </svg>
                    </i>
                    <p className="tips-form-item">
                      If you stores your client data in abroad server and you
                      want to leave it to user preference. You can show this
                      option.
                    </p>
                  </div>
                </h2>
                <div className="radio-item">
                  <Field
                    name={"abroad_server_showing"}
                    type="text"
                    options={[
                      { value: true, name: "Show" },
                      { value: false, name: "Hide" },
                    ]}
                    component={RadioInputComponent}
                  />
                </div>
                <h2>
                  Ask again when the user rejects all
                  <div className="tips-item form">
                    <i>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="14"
                          height="14"
                          rx="7"
                          fill="black"
                          fillOpacity="0.7"
                        />
                        <path
                          d="M5.8892 8.86222H7.07173V8.76989C7.07884 8.01705 7.32386 7.67259 7.9027 7.31747C8.55611 6.92685 8.96449 6.39418 8.96449 5.58097C8.96449 4.38068 8.03054 3.62784 6.67401 3.62784C5.43111 3.62784 4.42259 4.31676 4.38707 5.66619H5.65128C5.68324 4.99858 6.16619 4.67898 6.6669 4.67898C7.21023 4.67898 7.64702 5.04119 7.64702 5.59872C7.64702 6.09588 7.31676 6.43679 6.88707 6.70668C6.25852 7.0973 5.89631 7.49148 5.8892 8.76989V8.86222ZM6.51065 11.0781C6.92969 11.0781 7.28835 10.7301 7.2919 10.2969C7.28835 9.87074 6.92969 9.52273 6.51065 9.52273C6.07741 9.52273 5.72585 9.87074 5.7294 10.2969C5.72585 10.7301 6.07741 11.0781 6.51065 11.0781Z"
                          fill="white"
                        />
                      </svg>
                    </i>
                    <p className="tips-form-item">
                      When the user select reject all, ask the user for their
                      preferences again when they revisit the site.
                    </p>
                  </div>
                </h2>
                <div className="radio-item">
                  <Field
                    name={"reset_on_rejectall"}
                    type="text"
                    options={[
                      { value: true, name: "Ask everytime" },
                      { value: false, name: "Do Not Ask" },
                    ]}
                    component={RadioInputComponent}
                  />
                </div>
              </div>
              <div className="form-item ">
                <h3>Banner Settings</h3>
                <h2>You can hide or show “Reject All” button </h2>
                <div className="check-box-block none">
                  <Field
                    name={"is_reject_showing"}
                    placeholder="Show reject button"
                    component={CheckboxComponent}
                  />
                  <Field
                    name={"is_close_showing"}
                    placeholder="Show close button"
                    component={CheckboxComponent}
                  />
                </div>
              </div>
              <div className="form-item ">
                <h3>Lightbox Settings</h3>
                <h2>
                  Select cookie categories checked on load
                  <div className="tips-item form">
                    <i>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="14"
                          height="14"
                          rx="7"
                          fill="black"
                          fillOpacity="0.7"
                        />
                        <path
                          d="M5.8892 8.86222H7.07173V8.76989C7.07884 8.01705 7.32386 7.67259 7.9027 7.31747C8.55611 6.92685 8.96449 6.39418 8.96449 5.58097C8.96449 4.38068 8.03054 3.62784 6.67401 3.62784C5.43111 3.62784 4.42259 4.31676 4.38707 5.66619H5.65128C5.68324 4.99858 6.16619 4.67898 6.6669 4.67898C7.21023 4.67898 7.64702 5.04119 7.64702 5.59872C7.64702 6.09588 7.31676 6.43679 6.88707 6.70668C6.25852 7.0973 5.89631 7.49148 5.8892 8.76989V8.86222ZM6.51065 11.0781C6.92969 11.0781 7.28835 10.7301 7.2919 10.2969C7.28835 9.87074 6.92969 9.52273 6.51065 9.52273C6.07741 9.52273 5.72585 9.87074 5.7294 10.2969C5.72585 10.7301 6.07741 11.0781 6.51065 11.0781Z"
                          fill="white"
                        />
                      </svg>
                    </i>
                    <p>
                      On first load, categories that will be check by default.
                    </p>
                  </div>
                </h2>
                <Field
                  name={"select_categories"}
                  type="text"
                  //initialValue={[categories[0].id.toString()]}
                  options={categories}
                  component={MultiCheckboxComponent}
                />
              </div>
              <div className="form-item ">
                <h3>Sticky settings</h3>
                <h2>
                  Button Direction
                  <div className="tips-item form">
                    <i>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="14"
                          height="14"
                          rx="7"
                          fill="black"
                          fillOpacity="0.7"
                        />
                        <path
                          d="M5.8892 8.86222H7.07173V8.76989C7.07884 8.01705 7.32386 7.67259 7.9027 7.31747C8.55611 6.92685 8.96449 6.39418 8.96449 5.58097C8.96449 4.38068 8.03054 3.62784 6.67401 3.62784C5.43111 3.62784 4.42259 4.31676 4.38707 5.66619H5.65128C5.68324 4.99858 6.16619 4.67898 6.6669 4.67898C7.21023 4.67898 7.64702 5.04119 7.64702 5.59872C7.64702 6.09588 7.31676 6.43679 6.88707 6.70668C6.25852 7.0973 5.89631 7.49148 5.8892 8.76989V8.86222ZM6.51065 11.0781C6.92969 11.0781 7.28835 10.7301 7.2919 10.2969C7.28835 9.87074 6.92969 9.52273 6.51065 9.52273C6.07741 9.52273 5.72585 9.87074 5.7294 10.2969C5.72585 10.7301 6.07741 11.0781 6.51065 11.0781Z"
                          fill="white"
                        />
                      </svg>
                    </i>
                    <p>
                      Where the button will appear, the user can change their
                      settings
                    </p>
                  </div>
                </h2>
                <div className="radio-item">
                  <Field
                    name={"button_direction"}
                    type="text"
                    options={[
                      { value: "left_bottom", name: "Left bottom" },
                      { value: "right_bottom", name: "Right bottom" },
                      { value: "left_top", name: "Left top" },
                      { value: "right_top", name: "Right top" },
                      { value: "none", name: "None" },
                    ]}
                    component={RadioInputComponent}
                  />
                </div>
              </div>
              <div className="form-item ">
                <h3>Scroll Show Animation</h3>
                <h2>
                  Scroll Animation
                  <div className="tips-item form">
                    <i>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="14"
                          height="14"
                          rx="7"
                          fill="black"
                          fillOpacity="0.7"
                        />
                        <path
                          d="M5.8892 8.86222H7.07173V8.76989C7.07884 8.01705 7.32386 7.67259 7.9027 7.31747C8.55611 6.92685 8.96449 6.39418 8.96449 5.58097C8.96449 4.38068 8.03054 3.62784 6.67401 3.62784C5.43111 3.62784 4.42259 4.31676 4.38707 5.66619H5.65128C5.68324 4.99858 6.16619 4.67898 6.6669 4.67898C7.21023 4.67898 7.64702 5.04119 7.64702 5.59872C7.64702 6.09588 7.31676 6.43679 6.88707 6.70668C6.25852 7.0973 5.89631 7.49148 5.8892 8.76989V8.86222ZM6.51065 11.0781C6.92969 11.0781 7.28835 10.7301 7.2919 10.2969C7.28835 9.87074 6.92969 9.52273 6.51065 9.52273C6.07741 9.52273 5.72585 9.87074 5.7294 10.2969C5.72585 10.7301 6.07741 11.0781 6.51065 11.0781Z"
                          fill="white"
                        />
                      </svg>
                    </i>
                    <p>
                      You can hide the button which the user can change their
                      settings on scroll.
                    </p>
                  </div>
                </h2>
                <div className="radio-item">
                  <Field
                    name={"button_scroll_animation"}
                    type="text"
                    options={[
                      { value: 1, name: "Active" },
                      { value: 0, name: "Passive" },
                    ]}
                    component={RadioInputComponent}
                  />
                </div>
              </div>
              <div className="form-item ">
                <h3>Button Size</h3>
                <h2>
                  Button Size
                  <div className="tips-item form">
                    <i>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="14"
                          height="14"
                          rx="7"
                          fill="black"
                          fillOpacity="0.7"
                        />
                        <path
                          d="M5.8892 8.86222H7.07173V8.76989C7.07884 8.01705 7.32386 7.67259 7.9027 7.31747C8.55611 6.92685 8.96449 6.39418 8.96449 5.58097C8.96449 4.38068 8.03054 3.62784 6.67401 3.62784C5.43111 3.62784 4.42259 4.31676 4.38707 5.66619H5.65128C5.68324 4.99858 6.16619 4.67898 6.6669 4.67898C7.21023 4.67898 7.64702 5.04119 7.64702 5.59872C7.64702 6.09588 7.31676 6.43679 6.88707 6.70668C6.25852 7.0973 5.89631 7.49148 5.8892 8.76989V8.86222ZM6.51065 11.0781C6.92969 11.0781 7.28835 10.7301 7.2919 10.2969C7.28835 9.87074 6.92969 9.52273 6.51065 9.52273C6.07741 9.52273 5.72585 9.87074 5.7294 10.2969C5.72585 10.7301 6.07741 11.0781 6.51065 11.0781Z"
                          fill="white"
                        />
                      </svg>
                    </i>
                    <p>Button on size width and height</p>
                  </div>
                </h2>
                <div className="radio-item">
                  <Field
                    name={"button_size"}
                    type="text"
                    options={[
                      { value: 1, name: "Big Button" },
                      { value: 0, name: "Small Button" },
                    ]}
                    component={RadioInputComponent}
                  />
                </div>

                <div
                  className={`check-box-prew-button ${
                    detail && detail.button_size && detail.button_size === true
                      ? "cct-big-btn"
                      : "cct-small-btn"
                  }`}
                >
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M36.9992 26.1542C36.9165 33.8151 30.6805 40 23 40C15.268 40 9 33.732 9 26C9 18.3157 15.1909 12.0774 22.8571 12.0007C22.9587 12.1853 23.0809 12.5134 23.2508 13.1092C23.2771 13.2015 23.305 13.3022 23.3348 13.4094C23.4909 13.9722 23.697 14.7153 23.9815 15.3678C24.3327 16.1731 24.9345 17.1726 26.068 17.7696C27.2362 18.3849 28.6131 18.5567 29.5095 18.6685C29.6662 18.6881 29.8082 18.7058 29.9319 18.7237C30.9934 18.8777 31.2797 19.0516 31.4395 19.2509C32.1995 20.199 32.4636 20.9115 32.9957 22.3476C33.0373 22.4597 33.0804 22.5762 33.1256 22.6976C33.4799 23.6496 34.1572 24.2777 34.7 24.6836C35.1379 25.0111 35.6499 25.3059 36.0279 25.5235C36.1019 25.566 36.1708 25.6057 36.2329 25.642C36.6519 25.8871 36.8745 26.0399 36.9992 26.1542Z"
                      stroke="white"
                      stroke-width="4"
                    ></path>
                    <circle cx="17" cy="24" r="4" fill="white"></circle>
                    <circle cx="21" cy="32" r="2" fill="white"></circle>
                    <circle cx="29" cy="28" r="2" fill="white"></circle>
                    <circle cx="39" cy="18" r="2" fill="white"></circle>
                    <circle cx="33" cy="10" r="4" fill="white"></circle>
                  </svg>
                </div>
              </div>
            </div>
            <div className="btn-block">
              <Link exact to={POLICIES_PAGE}>
                <div className="btn-item cancel">
                  <span>Cancel</span>
                </div>
              </Link>

              <button type="submit" className="btn-item ">
                <span>Save</span>
              </button>
            </div>
            <ErrorComponent warn={this.state.error} />
          </form>
        )}
      />
    );
  }
}

export default PolicyFormComponent;
