import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDomainsAction } from '../../actions/domainActions';
import { WEB_SITES } from '../../constants/routePaths';
import { getStatistics } from '../../services/webServices';
import StatisticChoosingFormComponent from '../forms/statisticChoosingFormComponent';
import BreadcrumbComponent from '../layout/breadcrumbComponent';
import StatisticDetailComponent from '../statistic/statisticDetailComponent';
import StatisticPercentageComponent from '../statistic/statisticPercentageComponent';
import StatisticValueComponent from '../statistic/statisticValueComponent';

class WebsiteStatisticsComponent extends Component {
    state=({
        statisticData:[],
        
    })
    doId;
    constructor(props) {
        super(props);
        props.dispatch(getDomainsAction())
     }
    socialColor = Math.floor(Math.random()*16777215).toString(16);
    analistColor = Math.floor(Math.random()*16777215).toString(16);
    requareColor = Math.floor(Math.random()*16777215).toString(16);
    foeignColor = Math.floor(Math.random()*16777215).toString(16);
    apccetTotalValue =  0
    declineTotalValue = 0
    allCookieT = 0
    tottalApccet = {};
    apccetCount = {};
    data=[];
    chanceDomainId=(id)=>{   
        this.doId = id  
        getStatistics(this.doId).then(result => {
            if (result.data.success) {
                this.setState({
                    statisticData: result.data.success.statistics
                })
                this.calculateFunc(this.state.statisticData)
            }
        }).catch((err) => {
            console.log(err);
        })
    }


    calculateFunc=(data)=>{
        let calculateArr = data.map((item,inx)=> this.tottalApccet[item.category_name]=Math.ceil((item.accept_count*100)/(item.accept_count+item.decline_count)))
        this.apccetTotalValue =  data.map((item,inx)=> item.accept_count).reduce((a, b) => a + b, 0);
        this.declineTotalValue = data.map((item,inx)=> item.decline_count).reduce((a, b) => a + b, 0) ;
        this.allCookieT = Math.ceil((this.apccetTotalValue*100)/(this.declineTotalValue+this.apccetTotalValue));
        let appcetCount = data.map((item,inx)=> this.apccetCount[item.category_name]=item.accept_count)
        this.data = [ 
            ["Task", "Hours per Day"],
            ["Total", this.apccetTotalValue],
            ["Social", this.tottalApccet["Social"]],
            ["Analistic", this.tottalApccet["Analytics"]],
            ["Requered", this.tottalApccet["Kalıcı Çerezler"]],
        
        ];
        // console.log(calculateArr);
        this.forceUpdate()
        // return calculateArr
    }

    componentDidMount(){
        this.doId = 1;
        getStatistics(this.doId).then(result => {
            if (result.data.success) {
                this.setState({
                    statisticData: result.data.success.statistics
                })
                this.calculateFunc(this.state.statisticData)
            }
        }).catch((err) => {
            console.log(err);
        })
    }

//    componentDidUpdate(){
//     this.calculateFunc(this.state.statisticData)

//    }

    render() {
        return (
            <div className='content-root statistic-root'>
                {
                    this.props.siteInfoData &&
                    <BreadcrumbComponent breadName="Websites" breadLink={WEB_SITES} subBreadName={this.props.siteInfoData.domain_name} />
                }
                <div className="content-wrapper">
                    <div className="content-container">
                        <StatisticChoosingFormComponent domainsId={this.doId} chanceDomainId={this.chanceDomainId}/>
                        <StatisticValueComponent statisticData ={this.state.statisticData} socialColor={this.socialColor} analistColor={this.analistColor} requareColor={this.requareColor} foeignColor={this.foeignColor}   />
                        {/* <StatisticDetailComponent/> */}
                        <StatisticPercentageComponent apccetCount={this.apccetCount} data={this.data}  tottalApccet={this.tottalApccet} allCookieT={this.allCookieT} apccetTotalValue={this.apccetTotalValue} statisticData ={this.state.statisticData} socialColor={this.socialColor} analistColor={this.analistColor} requareColor={this.requareColor} foeignColor={this.foeignColor} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    siteInfoData: state.domainReducer.siteInfo && state.domainReducer.siteInfo[ownProps.match.params.id] ? state.domainReducer.siteInfo[ownProps.match.params.id] : null,
    domains: state.domainReducer.domains

})

export default connect(mapStateToProps)(WebsiteStatisticsComponent);