import React, { Component } from 'react';
//import { updateDomainCookieAction } from '../../actions/domainActions';

class InsiderBoxComponent extends Component {
    state = {
        isOpen:false,
        isOpenDot:false,
        editLigtbox:false,
        deleteLightbox: false,
        IsSuccess:false
        
    }
    dateArrar =[]
    insider =[]
    componentDidMount() {
        this.props.cookie && this.props.cookie.filter(item => item.third_party == "insider" ? this.dateArrar.push(item) : item)
        this.setTextCategory()
    }

    setTextCategory=()=>{
        this.dateArrar && this.dateArrar.forEach(item => {
            let categoryName = this.props.allCategories?.find(ctg => ctg.id === item.category_id)?.category_name
            if (item.policy_value === "true" && this.insider.filter(x=>x === categoryName).length === 0) {                       
                this.insider.push(categoryName)
            }
        }
        )
       
        this.forceUpdate()
    }
    changeCookieCategory = (categoryItem,third,policy) => {
        let categoryName = this.props.allCategories?.find(ctg => ctg.id === categoryItem.id)?.category_name
        if(this.state.isOpen && categoryItem.id!==this.props.cookie.cookie_category_id){
            let params ={
                category_id : categoryItem.id,
                third_party : third,
                policy_value : policy,
                isDeleted: false
            }
            this.props.updateValInsider(params)
            if(this.insider.filter(x=>x === categoryName).length === 0){
                this.insider.push(categoryName)
                params.isDeleted = false
            }else{
                let index = this.insider.indexOf(categoryName);
                if (index > -1) { 
                    this.insider.splice(index, 1);
                    params.isDeleted = true 
                  }
            }
            
        }
        
    }
    toggleCategoryMenu = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    render() {
        let {allCategories,cookie} = this.props

        return (
            <div className='parties-table-container'>
                <div className="parties-table-title-block">
                    <div className="title-item name-td">
                        <span>Name</span>
                    </div>
                    <div className="title-item category-td">
                        <span>Category</span>
                    </div>
                </div>
                <div className="parties-table-item-block">
                    <div className="table-item name-td">
                        <span>user_gdpr_optin </span>
                    </div>
                    <div className="table-item category-td">
                    <div className={`select-block ${this.state.isOpen?"opened":""}`} ref={this.optionRoot} onClick={this.toggleCategoryMenu}>
                    {
                            this.insider.map((item,key)=>{
                                return (
                                    <span key={key} >{item && key > 0 ? ", ":""}{item}  </span>
                                )
                            })
                         }
             
                        <ul className="category-list">
                        {
                                    allCategories && allCategories.filter(item=> item.id !== cookie.category_id ).map((categoryItem,key) =>
                                    <li onClick={()=>{this.changeCookieCategory(categoryItem,"insider","true")}} key={key} className="category-item" >
                                        {categoryItem && categoryItem.category_name ? categoryItem.category_name : "Bilinmeyen"}
                                    </li>
                                    )
                                }
               
                            
                        </ul>
                    </div>
                    </div>
                </div>
  
            </div>
        );
    }
}

export default InsiderBoxComponent;
