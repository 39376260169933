import React, { Component } from 'react';
import { connect } from 'react-redux';
import { WEB_SITES } from '../../constants/routePaths';
import BreadcrumbComponent from '../layout/breadcrumbComponent';
import AsideComponent from '../layout/asideComponent';
import AnalyticsBoxComponent from '../3rdParties/analyticsBoxComponent';
import FacebookBoxComponent from './../3rdParties/facebookBoxComponent';
import InsiderBoxComponent from './../3rdParties/insiderBoxComponent';
import { getDomainCookiesAction, thirdPartyAction } from '../../actions/domainActions';
import { updateThirdPartyPolicies } from '../../services/webServices';
import LoaderComponent from './../loaderComponent';
import SucsessComponent from '../layout/sucsessComponent';

class Website3rdPartiesComponent extends Component {
    
    state = {
        isAnalytics: false,
        isFacebook: false,
        isInsider: false,
        isFacebookCheck:false,
        isInsiderCheck:false,
        isAnalyticsCheck:false,
        //isLoader:false
        IsSuccess :false,
        IsBtn : true
    }
    analyticsArrValid = []
    facebookArrValid = []
    insidersArrValid = []

    newAnalistic = []
    newFacebook = []
    newInsider = []
    success = () => {
   
        this.setState({
            IsSuccess: true,
          

        });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
           this.setState({
            IsSuccess: false,
            IsBtn:true
           })
        }, 2000)

     }
    componentDidMount() {
       
       
        this.props.dispatch(getDomainCookiesAction(this.props.match.params.id, (res) => {
            if(res){
                this.checkValidFunc()
                this.checkboxValidFunc()
            }
            console.log("res---->",res);
        }))
        this.props.dispatch(thirdPartyAction(this.props.match.params.id, (res) => {
            console.log("res---->",res);
        }))
       
        
    }
    
    checkValidFunc = () => {
        console.log("burdaaa",this.props.thirdPolicies);
        if(this.props.thirdPolicies.filter((x)=> x.third_party === "facebook").length<0){
            this.setState({
                isFacebookCheck: false
            })
        }else{
            this.setState({
                isFacebookCheck: !this.state.isFacebookCheck
            })
        }
        if(this.props.thirdPolicies.filter((x)=> x.third_party === "analytics").length<0){
            this.setState({
                isAnalyticsCheck: false
            })
        }else{
            this.setState({
                isAnalyticsCheck: !this.state.isAnalyticsCheck 
            })
        }

        if(this.props.thirdPolicies.filter((x)=> x.third_party ==="insider").length<0){
            this.setState({
                isInsiderCheck: false
            })
        }else{
            this.setState({
                isInsiderCheck: !this.state.isInsiderCheck
            })
        }
    }
    
    setArr = () => {
        this.analyticsArrValid = this.props.thirdPolicies && this.props.thirdPolicies.filter(item => item.third_party == "analytics")
        this.facebookArrValid = this.props.thirdPolicies && this.props.thirdPolicies.filter(item => item.third_party == "facebook")
        this.insidersArrValid = this.props.thirdPolicies && this.props.thirdPolicies.filter(item => item.third_party == "insider")

    }

    updateValAnalistic = (params) => {      
        this.newAnalistic.push(params)

    }
    updateValFacebbok = (params) => {
        this.newFacebook.push(params)

    }
    updateValInsider = (params) => {
        this.newInsider.push(params)

    }

    updateThirdPartyFunc = () => {
        // this.setState({
        //     isLoader:true
        // })
        this.setState({
            IsBtn: false,            

        });
        let params = {
            analytics: this.analyticsArrValid,
            facebook: this.facebookArrValid,
            insider: this.insidersArrValid,
            analyticsCheck: this.state.isAnalyticsCheck,
            facebookCheck: this.state.isFacebookCheck,
            insiderCheck: this.state.isInsiderCheck,
            newAnalistic : this.newAnalistic,
            newFacebook : this.newFacebook,
            newInsider : this.newInsider

        }
        console.log("anallllls",this.analyticsArrValid);
        console.log("anallllls",this.newAnalistic);

        let domainId = this.props.match.params.id
        if(this.state.IsBtn ){
            updateThirdPartyPolicies({ params }, domainId).then((res) => {
         
                if (res.data.success) {
                    // this.setState({
                    //     isLoader:false
                    // })
                    this.success()
                    
                }
            }).catch((err) => {
                console.log("err", err)
            })
        }

    }

    checkboxValidFunc = () => {
        this.setArr()
        if (document ) {
         
            if (this.analyticsArrValid.length !== 0) {
                
                this.setState({
                    isAnalytics: !this.state.isAnalytics,
                    isAnalyticsCheck: true,
                })
              
            }else{
                this.setState({
                    isAnalytics: false,
                    isAnalyticsCheck:false
                })
            }


             if (this.facebookArrValid.length !== 0) {
                this.setState({
                    isFacebook: !this.state.isFacebook,
                    isFacebookCheck: true
                })
                
            }else{
                this.setState({
                    isFacebook: false,
                    isFacebookCheck:false
                })
            }


            if (this.insidersArrValid.length !== 0) {
                this.setState({
                    isInsider: !this.state.isInsider,
                    isInsiderCheck: true
                })
                
            }else{
                this.setState({
                    isInsider: false,
                    isInsiderCheck:false
                })
            }
        }
       
    }

    categoryOpenBoxFunc = (box) => {
        if (box === "analytics") {
            this.setState({
                isAnalytics: !this.state.isAnalytics,
                isAnalyticsCheck: !this.state.isAnalyticsCheck
            })
        } else if (box === "insider") {
            this.setState({
                isInsider: !this.state.isInsider,
                isInsiderCheck: !this.state.isInsiderCheck
            })
        } else if (box === "facebook") {
            this.setState({
                isFacebook: !this.state.isFacebook,
                isFacebookCheck: !this.state.isFacebookCheck
            })
        }
    }
    render() {
        let { allCategories, thirdPolicies } = this.props
        
        return (
            <div className="content-root parties-root">
                {
                    this.props.siteInfoData &&
                    <BreadcrumbComponent breadName="Websites" breadLink={WEB_SITES} subBreadName={this.props.siteInfoData.domain_name} />
                }
                <div className="content-wrapper">
                    <div className="content-container">

                        <div className="content-block">
                            <div className="text-item">
                                <h3>3rd parties</h3>
                                <p>Activate the 3rd party applications you use on your site and select the category under which you want your users to approve the cookies thrown by the applications.</p>

                            </div>

                            <div className="parties-container">
                                <div className="parties-block">
                                    {
                                        allCategories.length > 0 ? 
                                        <div className="check-box-block general">
                                        <div className="parties-select-block analytics-block">
                                            <div className={"container "} onClick={() => {

                                            }}>
                                                <input onChange={() => { this.categoryOpenBoxFunc("analytics") }} checked={this.state.isAnalyticsCheck} autoComplete="off" type="checkbox" name="Analytics" id="AnalyticsA1" required  />
                                                <label htmlFor="AnalyticsA1">Analytics</label>
                                            </div>

                                            {
                                                this.state.isAnalytics ?
                                                    <AnalyticsBoxComponent updateValAnalistic={this.updateValAnalistic}  allCategories={allCategories} cookie={thirdPolicies && thirdPolicies} setArr={this.setArr} />
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <div className="parties-select-block">
                                            <div className={"container "} onClick={() => {

                                            }}>
                                                <input onChange={() => { this.categoryOpenBoxFunc("facebook") }} checked={this.state.isFacebookCheck} autoComplete="off" type="checkbox" name="Facebook" id="FacebookF1"  required  />
                                                <label htmlFor="FacebookF1">Facebook</label>
                                            </div>

                                            {
                                                this.state.isFacebook ?
                                                    <FacebookBoxComponent updateValFacebbok={this.updateValFacebbok} allCategories={allCategories} cookie={thirdPolicies && thirdPolicies} setArr={this.setArr} />
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <div className="parties-select-block">
                                            <div className={"container "} onClick={() => {

                                            }}>
                                                <input onChange={() => { this.categoryOpenBoxFunc("insider") }} checked={this.state.isInsiderCheck} autoComplete="off" type="checkbox" name="Insider" id="InsiderI1"  required  />
                                                <label htmlFor="InsiderI1">Insider</label>
                                            </div>

                                            {
                                                this.state.isInsider ?
                                                    <InsiderBoxComponent updateValInsider={this.updateValInsider} allCategories={allCategories} cookie={thirdPolicies && thirdPolicies} setArr={this.setArr} />
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <LoaderComponent/>
                                    }
                                    
                                </div>

                            </div>
                            <div className="btn-block">

                                <div className="btn-item cancel">
                                    <span>Cancel</span>
                                </div>


                                <div className= {`btn-item ${this.state.IsBtn ? "":"disabled"}`} onClick={() => {  this.updateThirdPartyFunc() }}>
                                    <span>Save</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <SucsessComponent class={"fixed-pos"} warn={this.state.IsSuccess} message={"Güncelleme Başarılı"} />
                    <AsideComponent />
                    {/* {
                        this.state.isLoader &&
                        <LoaderComponent/>
                    }                 */}
                </div>

            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => ({
    siteInfoData: state.domainReducer.siteInfo && state.domainReducer.siteInfo[ownProps.match.params.id] ? state.domainReducer.siteInfo[ownProps.match.params.id] : null,
    allCategories: state.CategoryReducer.categoryList ? state.CategoryReducer.categoryList : [],
    thirdPolicies: state.thirdPartyReducer.third ? state.thirdPartyReducer.third : []
})

export default connect(mapStateToProps)(Website3rdPartiesComponent);
