import React, { Component } from "react";
import AsideComponent from "../layout/asideComponent";
import AddButtonComponent from "../buttons/addButtonComponent";
import { ASSETS } from "../../constants/Paths-prod";
import { WEB_SITES } from "../../constants/routePaths";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BreadcrumbComponent from "../layout/breadcrumbComponent";
import WebsiteDetailDesignComponent from "./websiteDetailDesignComponent"
import WebsiteColorDesignComponent from './websiteColorDesignComponent';

class WebsiteDesignComponent extends Component {
    state = {
        activeLanguageTab: "colors",
        editHtmlTab: "",
        privacy: false,
        detail: false,
        detailId: 0,
        btnTextColor: "",
        btnBackgroundColor: "",
        btnBorderColor: "",
        btnHoverColor: "",
        iconBackgroundColor: "",
        linkTextcolor: "",
        linkHoverColor: "",
        onMouseEnter1 : false,
        onMouseEnter2 : false,
        onMouseEnter3 : false,
        onMouseEnter4 : false,
    }
    cookieScriptCopied = false;
    exampleScriptCopied = false;

    changeColorTemplate = (color) => {
        this.setState({
            btnTextColor: color["--cctmc2"],
            btnBackgroundColor: color["--cctmc"],
            btnBorderColor: color["--cctbg"],
            btnHoverColor: color["--cctmchover"],
            iconBackgroundColor: color["--cctIcon"],
            linkTextcolor: color["--cctLink"],
            linkHoverColor: color["--cctLinkHover"],
        });
    }

    handleMouseEnter = (id) => {
        this.setState({
            onMouseEnter1: id === 1,
            onMouseEnter2: id === 2,
            onMouseEnter3: id === 3,
            onMouseEnter4: id === 4,
        })
    };

    handleMouseLeave = (id) => {
        this.setState({
            onMouseEnter1: false,
            onMouseEnter2: false,
            onMouseEnter3: false,
            onMouseEnter4: false,
        })
    };
    
    detailOpen = (id) => {
        this.setState({
            detail: !this.state.detail,
            detailId: this.state.detailId = id
        })

    }
    privacyOpen = () => {
        this.setState({
            privacy: !this.state.privacy
        })

    }

    copyToClipBoard = (copyTextValue, copiedScriptKey) => {
        var dummy = document.createElement('input'),
            text = copyTextValue;
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        this[copiedScriptKey] = true;
        this.forceUpdate()
        this[`timeout${copiedScriptKey}`] = setTimeout(() => {
            this[copiedScriptKey] = false;
            this.forceUpdate()
        }, 1000)
    }

    componentWillUnmount() {
        clearTimeout(this[`timeoutcookieScriptCopied`])
        clearTimeout(this[`timeoutexampleScriptCopied`])
    }
    activeWindov = (activeWindow) => {
        this.setState({
            activeWindow
        })
    }
    changeActiveLanguageTab = (activeLanguageTab) => {
        this.setState({
            activeLanguageTab
        })
    }
    editHtmlTab = (editHtmlTab) => {
        this.setState({
            editHtmlTab
        })

    }
    privacyArr = [
        {
            "id": 1,
            "title": "Statistics",
            "detail": "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in."
        },
        {
            "id": 2,
            "title": "Preferences",
            "detail": "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in."
        },
        {
            "id": 3,
            "title": "Marketing",
            "detail": "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in."
        },
        {
            "id": 4,
            "title": "Statistics",
            "detail": "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in."
        },
        {
            "id": 5,
            "title": "Necessary",
            "detail": "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in."
        },
        {
            "id": 6,
            "title": "Statistics",
            "detail": "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in."
        },
        {
            "id": 7,
            "title": "Statistics",
            "detail": "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in."
        }
    ]
    
    render() {

        const btnColors = {
            color : this.state.btnTextColor,
            background : this.state.btnBackground,
        
        }
          
        const codeText1 =
            `
        <!DOCTYPE html>
        <html lang="en-US">
        <head>
        <title>HTML Examples</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta name="Keywords" content="HTML, Python, CSS, SQL, JavaScript, 
        How to, PHP, Java, C++, jQuery, Bootstrap, C#, Colors, W3.CSS, XML, MySQL, Icons, NodeJS, React, Graphics,
         Angular, R, AI, Git, Data Science, Code Game, Tutorials, Programming, Web Development, Training, Learning, 
         Quiz, Courses, Lessons, References, Examples, Source code, Demos, Tips">
        <meta name="Description" content="Well organized and easy to understand Web building tutorials with l
        ots of examples of how to use HTML, CSS, JavaScript, SQL, Python, PHP, Bootstrap, Java, XML and more.">
        <meta property="og:image" content="https://www.w3schools.com/images/w3schools_logo_436_2.png">
        <meta property="og:image:type" content="image/png">
        <meta property="og:image:width" content="436">
        <meta property="og:image:height" content="228">
        <meta property="og:description" content="W3Schools offers free online tutorials, references and exercises 
        in all the major languages of the web. Covering popular subjects like HTML, CSS, JavaScript, Python, SQL, 
        Java, and many, many more.">
        <link rel="icon" href="/favicon.ico" type="image/x-icon">
        <link rel="preload" href="/lib/fonts/fontawesome.woff2?14663396" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/source-code-pro-v14-latin-regular.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/roboto-mono-v13-latin-500.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/source-sans-pro-v14-latin-700.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/source-sans-pro-v14-latin-600.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/freckle-face-v9-latin-regular.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="stylesheet" href="/lib/w3schools30.css">
        
        <script>
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        ga('create', 'UA-3855518-1', 'auto');
        <!DOCTYPE html>
        <html lang="en-US">
        <head>
        <title>HTML Examples</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta name="Keywords" content="HTML, Python, CSS, SQL, JavaScript, 
        How to, PHP, Java, C++, jQuery, Bootstrap, C#, Colors, W3.CSS, XML, MySQL, Icons, NodeJS, React, Graphics,
         Angular, R, AI, Git, Data Science, Code Game, Tutorials, Programming, Web Development, Training, Learning, 
         Quiz, Courses, Lessons, References, Examples, Source code, Demos, Tips">
        <meta name="Description" content="Well organized and easy to understand Web building tutorials with l
        ots of examples of how to use HTML, CSS, JavaScript, SQL, Python, PHP, Bootstrap, Java, XML and more.">
        <meta property="og:image" content="https://www.w3schools.com/images/w3schools_logo_436_2.png">
        <meta property="og:image:type" content="image/png">
        <meta property="og:image:width" content="436">
        <meta property="og:image:height" content="228">
        <meta property="og:description" content="W3Schools offers free online tutorials, references and exercises 
        in all the major languages of the web. Covering popular subjects like HTML, CSS, JavaScript, Python, SQL, 
        Java, and many, many more.">
        <link rel="icon" href="/favicon.ico" type="image/x-icon">
        <link rel="preload" href="/lib/fonts/fontawesome.woff2?14663396" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/source-code-pro-v14-latin-regular.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/roboto-mono-v13-latin-500.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/source-sans-pro-v14-latin-700.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/source-sans-pro-v14-latin-600.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/freckle-face-v9-latin-regular.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="stylesheet" href="/lib/w3schools30.css">
        
        <script>
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        ga('create', 'UA-3855518-1', 'auto');
        <title>HTML Examples</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta name="Keywords" content="HTML, Python, CSS, SQL, JavaScript, 
        How to, PHP, Java, C++, jQuery, Bootstrap, C#, Colors, W3.CSS, XML, MySQL, Icons, NodeJS, React, Graphics,
         Angular, R, AI, Git, Data Science, Code Game, Tutorials, Programming, Web Development, Training, Learning, 
         Quiz, Courses, Lessons, References, Examples, Source code, Demos, Tips">
        <meta name="Description" content="Well organized and easy to understand Web building tutorials with l
        ots of examples of how to use HTML, CSS, JavaScript, SQL, Python, PHP, Bootstrap, Java, XML and more.">
        <meta property="og:image" content="https://www.w3schools.com/images/w3schools_logo_436_2.png">
        <meta property="og:image:type" content="image/png">
        <meta property="og:image:width" content="436">
        <meta property="og:image:height" content="228">
        <meta property="og:description" content="W3Schools offers free online tutorials, references and exercises 
        in all the major languages of the web. Covering popular subjects like HTML, CSS, JavaScript, Python, SQL, 
        Java, and many, many more.">
        <link rel="icon" href="/favicon.ico" type="image/x-icon">
        <link rel="preload" href="/lib/fonts/fontawesome.woff2?14663396" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/source-code-pro-v14-latin-regular.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/roboto-mono-v13-latin-500.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/source-sans-pro-v14-latin-700.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/source-sans-pro-v14-latin-600.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="preload" href="/lib/fonts/freckle-face-v9-latin-regular.woff2" as="font" type="font/woff2" crossorigin> 
        <link rel="stylesheet" href="/lib/w3schools30.css">
        
        <script>
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        ga('create', 'UA-3855518-1', 'auto');
        `;
        return (
            <div className="content-root website-desing-root">
                {
                    this.props.siteInfoData &&
                    <BreadcrumbComponent breadName="Websites" breadLink={WEB_SITES} subBreadName={this.props.siteInfoData.domain_name} />
                }
           
                <div className="content-wrapper">
                    <div className="content-container">
                        <div className="tab-btn-block">
                            {/* TODO --> active classı seçili olan sayfayı gösterir */} 
                            <div className={`tab-btn-item ${this.state.activeLanguageTab === "colors" ? "active" : ""}`} onClick={() => {
                                this.changeActiveLanguageTab("colors")
                            }}>
                                <span>Colors</span>

                            </div>
                            <div className={`tab-btn-item ${this.state.activeLanguageTab === "banner" ? "active" : ""}`} onClick={() => {
                                this.changeActiveLanguageTab("banner")
                            }}>
                                <span>Banner view</span>
                            </div>
                            <div className={`tab-btn-item ${this.state.activeLanguageTab === "preferences" ? "active" : ""}`} onClick={() => {
                                this.changeActiveLanguageTab("preferences")
                            }}>
                                <span>Preferences view</span>

                            </div>
                            {/* <div className={`tab-btn-item ${this.state.activeLanguageTab === "cookie" ? "active" : ""}`} onClick={() => {
                                this.changeActiveLanguageTab("cookie")
                            }}>
                                <span>Cookie List view</span>

                            </div> */}
                           
                        </div>
                        <div className={`content-block  ${this.state.activeLanguageTab === "preferences" ? "preferences" : this.state.activeLanguageTab === "cookie" ? "cookie" : (this.state.activeLanguageTab === "colors"?"":"banner") }`}>

                            {/* <div className="lang-select-block">
                                <div className="lang-title-item">
                                    <span className="text-title-item">Preview Language</span>

                                </div>
                                <div className="select-container">
                                    <div className="select-block">
                                        <span>Turkish</span>
                                        <ul>

                                            <li></li>

                                        </ul>

                                    </div>
                                    <div className={`btn-item edit-btn  ${this.state.editHtmlTab === "edit" ? "edit" : ""}`} onClick={() => this.editHtmlTab("edit")} >
                                        <span>Edit HTML</span>
                                        <i>
                                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 11L9 10L15.5858 3.41421C15.851 3.149 16 2.78929 16 2.41421C16 1.63317 15.3668 1 14.5858 1C14.2107 1 13.851 1.149 13.5858 1.41421L7 8L6 11Z" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8 2H3C1.89543 2 1 2.89543 1 4V14C1 15.1046 1.89543 16 3 16H13C14.1046 16 15 15.1046 15 14V9" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </i>
                                    </div>
                                </div>


                            </div> */}

                            <div className={`banner-wiev-container  ${this.state.editHtmlTab === "edit" ? "edit" : ""}`}>
                                <div className="banner-preview-container">
                                    <div className="img-block">
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}img/banner-view.webp 1x ${ASSETS}img/banner-view_2x.webp 2x`} type="image/webp" />
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}img/banner-view.png 1x ${ASSETS}img/banner-view_2x.png 2x`} type="image/png" />
                                            <img src={`${ASSETS}img/banner-view.png`} alt="banner-view" />

                                        </picture>
                                    </div>
                                    <div className="cookie-block">
                                        <p>Tanımlama bilgilerini; sitemizin doğru şekilde çalışmasını sağlamak, içerikleri ve reklamları kişiselleştirmek, sosyal medya özellikleri sunmak ve site trafiğimizi analiz etmek için kullanıyoruz. Aynı zamanda site kullanımınızla ilgili bilgileri; sosyal medya, reklamcılık ve analiz ortaklarımızla paylaşıyoruz.</p>
                                        <div className="btn-item change-btn" onMouseEnter={() => this.handleMouseEnter(1)} onMouseLeave={() => this.handleMouseLeave(1)} style={{ background: this.state.onMouseEnter1 === true ? this.state.btnHoverColor : this.state.btnBackgroundColor, borderColor: this.state.btnBorderColor}}>
                                            <span style={{color: this.state.btnTextColor}}> Reject All</span></div>
                                        <div className="btn-item change-btn" onMouseEnter={() => this.handleMouseEnter(2)} onMouseLeave={() => this.handleMouseLeave(2)} style={{ background: this.state.onMouseEnter2 === true ? this.state.btnHoverColor : this.state.btnBackgroundColor,borderColor: this.state.btnBorderColor}}> <span style={{color: this.state.btnTextColor}}>Cookie Settings</span> </div>
                                        <div className="btn-item change-btn" onMouseEnter={() => this.handleMouseEnter(3)} onMouseLeave={() => this.handleMouseLeave(3)} style={{ background: this.state.onMouseEnter3 === true ? this.state.btnHoverColor : this.state.btnBackgroundColor,borderColor: this.state.btnBorderColor}}><span style={{color: this.state.btnTextColor}} >Accept Cookies</span></div>
                                    </div>
                                </div>

                                <div className="scripts-root">
                                    <div className="scripts-container ">
                                        <div className="scripts-item desing ">
                                            <div className={`copy-code reset-code ${this.cookieScriptCopied ? "copied" : ""}`} onClick={() => { this.copyToClipBoard(codeText1, "cookieScriptCopied") }}>
                                                <span>Reset to default</span>

                                            </div>
                                            <div className={`copy-code ${this.cookieScriptCopied ? "copied" : ""}`} onClick={() => { this.copyToClipBoard(codeText1, "cookieScriptCopied") }}>
                                                <span>Copy</span>

                                            </div>
                                            <div className="script">
                                                <pre>
                                                    <textarea onChange={()=>{}} name="codeText1" id="codeText1" cols="100" rows="300" value={codeText1}></textarea>
                                                </pre>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="btn-block">
                                        <div className="btn-item exit" onClick={() => this.editHtmlTab("exit")}>Exit without save</div>
                                        <div className="btn-item save">Save</div>

                                    </div>
                                </div>

                            </div>
                            <div className="prefernces-wiev-container">
                                <div className="banner-preview-container">
                                    <div className="img-block">
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}img/banner-view.webp 1x ${ASSETS}img/banner-view_2x.webp 2x`} type="image/webp" />
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}img/banner-view.png 1x ${ASSETS}img/banner-view_2x.png 2x`} type="image/png" />
                                            <img src={`${ASSETS}img/banner-view.png`} alt="banner-view" />

                                        </picture>
                                    </div>
                                    {/* TODO privacy-block- > detail clasıı geldiğinde detail root açılır */}
                                    <div className={`privacy-block ${this.state.privacy ? "detail" : ""}`}>
                                        <div className="close-btn" />
                                        <div className="text-item">
                                            <h2>Privacy Settings</h2>
                                            <p>İnternet sitemizden en verimli şekilde faydalanabilmeniz ve kullanıcı deneyiminizi geliştirebilmek için çerezler (cookie) kullanmaktayız. Çerez kullanılmasını tercih etmezseniz tarayıcınızın ayarlarından çerezleri silebilir ya da engelleyebilirsiniz. Ancak bunun internet sitemizi kullanımınızı etkileyebileceğini hatırlatmak isteriz. Çerezlere ilişkin daha detaylı bilgiye "<a href="" onMouseEnter={() => this.handleMouseEnter(4)} onMouseLeave={() => this.handleMouseLeave(4)}  style={{color: this.state.onMouseEnter4 === true ? this.state.linkHoverColor : this.state.linkTextcolor}}>İnternet Sitemizde Kullanılan Çerezler</a>" adlı dokümandan ulaşabilirsiniz.</p>
                                        </div>
                                        <div className="btn-container">
                                            <div className="btn-item change-btn" onClick={() => this.privacyOpen()} onMouseEnter={() => this.handleMouseEnter(1)} onMouseLeave={() => this.handleMouseLeave(1)} style={{ background: this.state.onMouseEnter1 === true ? this.state.btnHoverColor : this.state.btnBackgroundColor,borderColor: this.state.btnBorderColor}}>
                                                <span>
                                                    <svg  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path style={{stroke: this.state.btnTextColor}} d="M11 6.5L8 9.5L5 6.5" stroke="white" stroke-width="2" strokeLinecap="round"/>
                                                    </svg>
                                                </span>
                                                <span style={{color: this.state.btnTextColor}}>Hide details</span>

                                            </div>
                                            <div className="btn-block">
                                                <div className="btn-item change-btn" onMouseEnter={() => this.handleMouseEnter(2)} onMouseLeave={() => this.handleMouseLeave(2)} style={{ background: this.state.onMouseEnter2 === true ? this.state.btnHoverColor : this.state.btnBackgroundColor,borderColor: this.state.btnBorderColor}}> 
                                                    <span style={{color: this.state.btnTextColor}}>Reject All</span>
                                                </div>
                                                <div className="btn-item change-btn" onMouseEnter={() => this.handleMouseEnter(3)} onMouseLeave={() => this.handleMouseLeave(3)} style={{ background: this.state.onMouseEnter3 === true ? this.state.btnHoverColor : this.state.btnBackgroundColor,borderColor: this.state.btnBorderColor}}>
                                                    <span style={{color: this.state.btnTextColor}}>Accept Cookies</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="detail-root">
                                            <div className="detail-container">
                                                {/* TODO --> head-item a active classı geldiğinde detay yazısı göxükür */}

                                                <div className="detail-block">
                                                    {
                                                        this.privacyArr.map((obj, index) => {
                                                            return (
                                                                <div key={index} className={`head-item ${this.state.detailId === obj.id ? "active" : ""}`}>
                                                                    <div className="text-container">
                                                                        <div className="text-block" onClick={() => this.detailOpen(obj.id)} >
                                                                            <span className="push-down" ></span>
                                                                            <h2>{obj.title}</h2>

                                                                        </div>
                                                                        <div className="check-box-block">

                                                                            <input type="checkbox" />


                                                                        </div>
                                                                    </div>

                                                                    <div className="open-text-item">
                                                                        <p>{obj.detail}</p>
                                                                    </div>
                                                                </div>
                                                            )


                                                        })
                                                    }


                                                </div>
                                            </div>

                                        </div>
                                        <div className="bottom-btn-block">
                                            <div className="text-item">
                                                <p>Powered by <span>CookieSuit</span></p>
                                            </div>
                                            <div className="btn-item">
                                                <span>Save & Close</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* <div className="cookie-wiev-container">
                                <h1>Cookiess</h1>
                            </div> */}
                            {
                                this.state.activeLanguageTab === "colors" &&
                       
                                <WebsiteDetailDesignComponent domainId={this.props.match.params.id}
                                changeColorTemplate={this.changeColorTemplate}/>
                            }
                        </div>
                    </div>
                    <AsideComponent />
                </div>

            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    siteInfoData: state.domainReducer.siteInfo && state.domainReducer.siteInfo[ownProps.match.params.id] ? state.domainReducer.siteInfo[ownProps.match.params.id] : null
})

export default connect(mapStateToProps)(WebsiteDesignComponent);
