import React, { Component } from 'react';
import { ASSETS } from '../../constants/Paths';
import PolicyScriptLbxComponent from './policyScriptLbxComponent';
import PolicyEditAudienceLbxComponent from './policyEditAudienceLbxComponent';
import PolicyDeleteAudienceLbxComponent from './policyDeleteAudienceLbxComponent';

class PolicyAudienceItemComponent extends Component {
    state = {
        isScriptOpen: false,
        isEditOpen: false,
        isDeleteOpen: false,
        editKey: "",
        scriptKey: "",
        deleteKey: ""
    }

    toggleScriptLbx = (key) => {
        this.setState({
            isScriptOpen: !this.state.isScriptOpen,
            scriptKey: key
        })
    }

    toggleEdit = (key) => {
        this.setState({
            isEditOpen: !this.state.isEditOpen,
            editKey: key
        })
    }

    toggleDelete = (key) => {
        this.setState({
            isDeleteOpen: !this.state.isDeleteOpen,
            deleteKey: key
        })
    }

    render() {
        let { domainArr, policyArr, getPolicies, sortAudiences } = this.props;

        return (
            <div>
                {
                    domainArr && domainArr.sort(sortAudiences).map((item, key) =>
                        <div className="row-item" key={key}>
                            <div className="row-td region-td">
                                <span>{item.region_name}</span>
                            </div>
                            <div className="row-td id-td">
                                <span>{item.index_key}</span>
                            </div>
                            <div className="row-td template-td">
                                <span>{policyArr && this.props.policyArr.find(x=> x.id === item.policy_id).name}</span>
                            </div>
                            <button className="btn-item script" onClick={() => this.toggleScriptLbx(key)}>
                                <span>Script</span>
                                <img src={ASSETS + "img/script.svg"} alt="script tag" />
                            </button>
                            <div className='edit-item' onClick={() => this.toggleEdit(key)}>
                                <img src={ASSETS + "img/edit.svg"} alt="edit" />
                            </div>
                            <div className='delete-item' onClick={() => this.toggleDelete(key)}>
                                <img src={ASSETS + "img/delete-icon.svg"} alt="delete" />
                            </div>
                            {
                                this.state.isDeleteOpen && this.state.deleteKey === key &&
                                <PolicyDeleteAudienceLbxComponent toggleDelete={this.toggleDelete} deleteKey={this.state.deleteKey} domainArr={domainArr} getPolicies={getPolicies}/>
                            }
                            {
                                this.state.scriptKey === key &&
                                <PolicyScriptLbxComponent toggleScriptLbx={this.toggleScriptLbx} isScriptOpen={this.state.isScriptOpen} indexKey={item.index_key}/>
                            }
                            {
                                this.state.isEditOpen && this.state.editKey === key &&
                                <PolicyEditAudienceLbxComponent toggleEdit={this.toggleEdit} isEditOpen={this.state.isEditOpen} domainItem={item} getPolicies={getPolicies} policyArr={policyArr}/>
                            }
                        </div>
                    )
                }
            </div>
        );
    }
}

export default PolicyAudienceItemComponent;