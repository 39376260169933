import React, { Component } from "react";
import { connect } from "react-redux";
import { SketchPicker } from 'react-color';
import { updateDomainColorsAction } from "../../actions/domainActions";
import { getDomainColors } from "../../services/webServices";
import SucsessComponent from "../layout/sucsessComponent";


class WebsiteColorDesignComponent extends Component {
    domainColor={"--cctmc":"#1f85e3","--cctmchover":"#1B73C3","--cctmc2":"#E7F3FF","--cctbg":"#D1E9FE"};
    saved = false;
    componentDidMount(){
        getDomainColors({domainId:this.props.domainId}).then((result)=>{
            if(result.data.success){
                this.domainColor = result.data.success.domain_colors ? JSON.parse(result.data.success.domain_colors):this.domainColor
                this.forceUpdate();
            }
        })
    }
    handleChangeComplete = (key, props) => {
        this.domainColor[key] = props.hex
        this.forceUpdate();
    }
    save = () => {
        clearTimeout(this.timeout)
        this.saved = true;
        this.forceUpdate();
        this.props.dispatch(updateDomainColorsAction({
            domainId: this.props.domainId,
            domainDetail:{
                domain_colors: JSON.stringify(this.domainColor)
            }
        }));
        this.timeout = setTimeout(()=>{
            this.saved = false;
            this.forceUpdate();
        },1000)
    }
    render() {
        return (
            <div>
                <main className="scripts-root">
                    <div className="scripts-wrapper">
                        <div className="scripts-container">
                            <h2>Customize</h2>
                            <div className="color-block">
                                <div className="column-block">
                                    <div className="column-item">
                                        <p>Button Text Color</p>
                                        <SketchPicker
                                            color={this.domainColor["--cctmc2"]}
                                            onChangeComplete={(props) => this.handleChangeComplete("--cctmc2", props)}
                                        />
                                    </div>
                                    <div className="column-item">
                                        <p>Button Background Color </p>
                                        <SketchPicker
                                            color={this.domainColor["--cctmc"]}
                                            onChangeComplete={(props) => this.handleChangeComplete("--cctmc", props)}
                                        />
                                    </div>
                                    <div className="column-item">
                                        <p>Button Hover Color</p>
                                        <SketchPicker
                                            color={this.domainColor["--cctmchover"]}
                                            onChangeComplete={(props) => this.handleChangeComplete("--cctmchover", props)}
                                        />
                                    </div>
                                    <div className="column-item">
                                        <p>Button Border Color</p>
                                        <SketchPicker
                                            color={this.domainColor["--cctbg"]}
                                            onChangeComplete={(props) => this.handleChangeComplete("--cctbg", props)}
                                        />
                                    </div>
                                    <div className="column-item">
                                        <p>Icon Background color</p>
                                        <SketchPicker
                                            color={this.domainColor["--cctIcon"]}
                                            onChangeComplete={(props) => this.handleChangeComplete("--cctIcon", props)}
                                        />
                                    </div>
                                    <div className="column-item">
                                        <p>Link Text Color</p>
                                        <SketchPicker
                                            color={this.domainColor["--cctLink"]}
                                            onChangeComplete={(props) => this.handleChangeComplete("--cctLink", props)}
                                        />
                                    </div>
                                    <div className="column-item">
                                        <p>Link Text Hover Color</p>
                                        <SketchPicker
                                            color={this.domainColor["--cctLinkHover"]}
                                            onChangeComplete={(props) => this.handleChangeComplete("--cctLinkHover", props)}
                                        />
                                    </div>
                                </div>
                                <div className="bottom-block" onClick={this.save}>
                                    <div className="btn-item">
                                        <span>Save</span>
                                    </div>
                                </div>
                            </div>
                            <SucsessComponent warn={this.saved} />
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default connect()(WebsiteColorDesignComponent);
