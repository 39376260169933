import React, { Component } from "react";
import AsideComponent from "../layout/asideComponent";
import { Link } from "react-router-dom";
import { POLICIES_PAGE } from "../../constants/routePaths";
import PolicyFormComponent from "./policyFormComponent";

class PoliciyAddComponent extends Component {
    render() {
        return (
            <div className="content-root policies-add-root">
                <div className="breadcrumb-block">
                    <Link to={POLICIES_PAGE} className="breadcrumb-item right-arr">Policies</Link>
                    <span className="breadcrumb-item" >Add Policy</span>
                </div>

                <div className="content-wrapper">
                    <div className="content-block">
                        <div className="head-item">
                            <h1>Add Policy</h1>
                        </div>
                        <PolicyFormComponent history={this.props.history}/>
                    </div>
                    <AsideComponent />

                </div>
            </div>
        );
    }
}

export default PoliciyAddComponent;
