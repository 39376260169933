import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { WEB_SITES } from "../../constants/routePaths";
import { getDomainCookiesAction, getSitePreInfoAction } from "../../actions/domainActions"
import CookieListComponent from "../cookieList/cookieListComponent";
import BreadcrumbComponent from "../layout/breadcrumbComponent";

class WebsiteCookieListComponent extends Component {
    state = {
        activeWindow: "Active cookies",
        type: "",
        change: false
    }

    constructor(props) {
        super(props);
        this.getDomainCookies()
    }

    activeWindov = (activeWindow) => {
        this.setState({
            activeWindow
        })
        this.getDomainCookies()
    }
    componentDidMount() {
        this.getDomainCookies();
    }

    getDomainCookies = (a) => {
        this.props.dispatch(getDomainCookiesAction(this.props.match.params.id, (res) => {
            // console.log(">>>>>>>>>>>>>>>>>>>>",res.cookies)
            // console.log("içindeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",a)

            // // if(){
            //     if (a == "name") {
            //         console.log("içindeeeeeeeeeeeeeejjjjjjjjjjjjjeeeeeeee",res.cookies)
            //         if(this.state.change){
            //             console.log("22222222222222222222222")
            //             this.setState({ change: false });
            //             return res.cookies.sort((a, b) => a.cookie.cookie_name > b.cookie.cookie_name ? -1 : b.cookie.cookie_name > a.cookie.cookie_name ? 1 : 0) 
            //         }
            //         else{
            //             console.log("77777777777777777777777")
            //             this.setState({ change: true });
            //             return res.cookies.sort((a, b) => a.cookie.cookie_name > b.cookie.cookie_name ? 1 : b.cookie.cookie_name > a.cookie.cookie_name ? -1 : 0) 
            //         }
                    
            //     }
         
                    if (res) {
                        this.props.dispatch(getSitePreInfoAction(this.props.match.params.id))
                        this.setState({
                            ...res
                        })
            
                }
            // }
            // else{
            //     if (res) {
            //         this.props.dispatch(getSitePreInfoAction(this.props.match.params.id))
            //         this.setState({
            //             ...res
            //         })
            //     }
            // }

        }))
    }

    render() {
        return (
            <div className="content-root websites-cookie-root">
                {
                    this.props.siteInfoData &&
                    <BreadcrumbComponent breadName="Websites" breadLink={WEB_SITES} subBreadName={this.props.siteInfoData.domain_name} />
                }
                {
                    this.state.cookies &&
                    <div className="content-wrapper">
                        <div className="content-container">
                            <div className="tab-btn-block">
                                {/* TODO --> active classı seçili olan sayfayı gösterir */}
                                <div className={`tab-btn-item ${this.state.activeWindow === "Active cookies" ? "active" : ""}`} onClick={() => {
                                    this.activeWindov("Active cookies")
                                }}>
                                    <span>Active cookies</span>
                                </div>
                                <div className={`tab-btn-item ${this.state.activeWindow === "Uncategorized" ? "active" : ""}`} onClick={() => {
                                    this.activeWindov("Uncategorized")
                                }}>
                                    <span>Uncategorized</span>
                                    <p>{this.state.unCategorizedCookies.length}</p>
                                </div>

                            </div>
                            {/*  */}
                            <CookieListComponent activeWindow = {this.state.activeWindow} cookies={this.state.activeWindow === "Uncategorized" ? this.props.unCategorizedCookies : this.props.cookies} getDomainCookies={this.getDomainCookies} allCategories={this.state.allCategories} domainId={this.props.match.params.id}  type={this.state.type} />
                        </div>
                    </div>
                }

            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    siteInfoData: state.domainReducer.siteInfo && state.domainReducer.siteInfo[ownProps.match.params.id] ? state.domainReducer.siteInfo[ownProps.match.params.id] : null,
    cookies: state.domainReducer.cookies || [],
    unCategorizedCookies: state.domainReducer.unCategorizedCookies || [],
})

export default connect(mapStateToProps)(WebsiteCookieListComponent);
