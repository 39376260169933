import React, {Component} from "react";
import {ASSETS} from "../constants/Paths-prod";
import {NavLink} from "react-router-dom";
import {Field, Form} from "react-final-form";
import {required} from "../helper";
import TextInputComponent from "../components/formFields/textInputComponent";
import RecaptchaFieldComponent from "../components/formFields/recaptchaFieldComponent";
import SlideComponent from "../components/slideComponent";
import LoginFormComponent from "../components/forms/loginFormComponent";
import LoaderComponent from "../components/loaderComponent";
import CoverComponent from "../components/common/coverComponent";
import LogoComponent from "../components/common/logoComponent";

class Faz2loginPage extends Component {
   state = {
      titleSelectTab: "login",

   }

   render() {
      return (
          <div className="login-root">
             <div className="login-wrapper">
                <div className="login-left-wrapper">
                   <LogoComponent/>
                   <div className="login-content-container">
                      <div className="login-container">
                         <div className="title-block">
                            <div className="title-item active">
                               <h3>Login</h3>
                            </div>
                           
                         </div>
                         <LoginFormComponent/>

                         {/* <div className="bottom-btn-block">
                            <div className="text-item">
                               <span>I don’t have an account</span>
                            </div>
                         

                         </div> */}
                      </div>
                   </div>
                </div>

                <CoverComponent/>
             </div>
          </div>
      );
   }
}

export default Faz2loginPage;
