import React, { Component } from 'react';

class DayTextInputComponent extends Component {
    render() {
        const { input, label, disabled, placeholder, type, meta: { submitFailed, error, touched } } = this.props;
        return (
            <div className="input-item day-item">
                <input type="number" min="1" max="9999999" {...input} />
      
            </div>
        )
    }
}

export default DayTextInputComponent;
