import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import TextInputComponent from '../formFields/textInputComponent';
import RadioInputComponent from '../formFields/radioInputComponent';
import SucsessComponent from '../layout/sucsessComponent';
import { addCategory, getDomainLanguages } from '../../services/webServices';
import { connect } from 'react-redux';

const validate = (values) => {
   const errors = {};

   // if (!values || !values.cookieName || values.cookieName === "") {
   //     errors.cookieName = "Zorunlu";
   // }

   return errors;
};
class AddCategoryFormComponent extends Component {
   state = {
      isLoading: false,
      categoryOptionArr: [],
      success: false,
      langueArr: [],
      set_language_by_browser: null
   }
   componentDidMount() {
      this.getLanguages();
   }


   getLanguages = () => {
      getDomainLanguages({ domainId: this.props.domainId }).then((result) => {
         if (result.data.success) {
            this.setState({
               langueArr: result.data.success,
               set_language_by_browser: result.data.status
            })
         }
      })
   }
   submit = (values) => {
      let names = []
      let descs = []
      let categoryNameobj = {}
      let categoryDescobj = {}
      Object.entries(values).forEach(([key, value]) => {
         if (typeof key == "string") {
            if (key.includes('_Name')) {
               names.push(value)
            }
            if (key.includes('_Desc')) {
               descs.push(value)
            }
         }

      })
      this.state.langueArr.map((lang, index) => {
         categoryNameobj[lang.language.language_code.split('-')[1].toLowerCase()] = names[index]
         categoryDescobj[lang.language.language_code.split('-')[1].toLowerCase()] = descs[index]

      })
      let params = {
         domain_id: this.props.domainId,
         category_name_lang: categoryNameobj,
         info_description_lang: categoryDescobj,
         is_required: 0,
         is_active: values.status
      }
     
      addCategory(params).then((result) => {
         if (result.data.success) {

            this.success()
            this.props.getCategories()
         }

      })

   }
   success = () => {
      this.setState({
         success: true
      });
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.setState({
            success: false
         })
      }, 2000)
      this.props.getCategories()
   }
   render() {
      return (
         <div>
            {/* {
                isLoading &&
                <LoaderComponent />
            } */}
            <Form onSubmit={this.submit}
               validate={validate}
               render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                     <div className="lightBox-root category">
                        <div className="lightBox-wrapper">
                           <div className="lightBox-container">
                              <div className="head-item">
                                 <h2 className="light-box-title">Add Category</h2>
                                 <div className="close-icon" onClick={() => this.props.lightBox("close")}>
                                    <i>
                                       <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                       </svg>

                                    </i>
                                 </div>
                              </div>

                              <div className="radio-container">

                                 <div className="radio-block">
                                    <div className="radio-item">
                                       <Field
                                          name={"status"}

                                          component={RadioInputComponent}
                                          options={[{ value: 1, name: "Active" }, { value: 0, name: "Passive" }]}
                                       />
                                    </div>
                                 </div>
                              </div>
                              <div className="langues-container">
                                 <div className="langues-block">
                                    <h2>Languages</h2>
                                    {
                                       this.state.langueArr.map((lang, key) => {
                                          return (
                                             <Field
                                                name={lang.language.language_code + "_Name"}
                                                type="text"
                                                label={lang.language.language_name + " Name"}
                                                component={TextInputComponent}
                                                placeholder={lang.language.language_name + " Name"}
                                                key={key}

                                             />
                                          )
                                       })
                                    }



                                 </div>

                              </div>
                              <div className="langues-container">
                                 <div className="langues-block">
                                    <h2>Languages Desc</h2>
                                    {
                                       this.state.langueArr.map((lang, key) => {
                                          return (
                                             <Field
                                                name={lang.language.language_code + "_Desc"}
                                                type="text"
                                                label={lang.language.language_name + " Name"}
                                                component={TextInputComponent}
                                                placeholder={lang.language.language_name + " Name"}
                                                key={key}

                                             />
                                          )
                                       })
                                    }


                                 </div>

                              </div>
                              <div className="btn-block">
                                 <div className="btn-item cancel" onClick={() => this.props.lightBox("close")}>
                                    <span>Cancel</span>
                                 </div>
                                 <button type="submit" className="btn-item">
                                    Add Category
                                 </button>
                              </div>
                           </div>
                        </div>

                     </div>


                  </form>
               )}>

            </Form>
            <SucsessComponent warn={this.state.success} message={"Category Eklendi"} />
         </div>
      );
   }
}

export default connect()(AddCategoryFormComponent);
