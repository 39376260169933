import React, { Component } from 'react';

class CheckboxComponent extends Component {
    onChange = (e) => {
        this.props.input.onChange(!this.props.input.value)
    }
    render() {
        let { input, placeholder, desc } = this.props;
        return (
            <div className="container" onClick={() => {
                    this.onChange()
            }}>
                <input onChange={()=>{}} type="checkbox" checked={!!input.value ? "checked" : ""}  />
                <label htmlFor="">{placeholder}
                    <div className="tips-item form">
                        <i><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="14" height="14" rx="7" fill="black" fillOpacity="0.7" />
                            <path d="M5.8892 8.86222H7.07173V8.76989C7.07884 8.01705 7.32386 7.67259 7.9027 7.31747C8.55611 6.92685 8.96449 6.39418 8.96449 5.58097C8.96449 4.38068 8.03054 3.62784 6.67401 3.62784C5.43111 3.62784 4.42259 4.31676 4.38707 5.66619H5.65128C5.68324 4.99858 6.16619 4.67898 6.6669 4.67898C7.21023 4.67898 7.64702 5.04119 7.64702 5.59872C7.64702 6.09588 7.31676 6.43679 6.88707 6.70668C6.25852 7.0973 5.89631 7.49148 5.8892 8.76989V8.86222ZM6.51065 11.0781C6.92969 11.0781 7.28835 10.7301 7.2919 10.2969C7.28835 9.87074 6.92969 9.52273 6.51065 9.52273C6.07741 9.52273 5.72585 9.87074 5.7294 10.2969C5.72585 10.7301 6.07741 11.0781 6.51065 11.0781Z" fill="white" />
                        </svg>
                        </i>
                        <p>{desc}</p>
                    </div>
                </label>
            </div>
        )
    }
};

export default CheckboxComponent