import {WEB_SERVICE_URL} from "../constants/Paths"
import axios from "axios";

export const signin = (params) => {
    return axios.post(`${WEB_SERVICE_URL}user/signin`,"params="+JSON.stringify(params));
}

export const activeUser = () => {
    return axios.get(`${WEB_SERVICE_URL}user/activeUser?token=${localStorage.getItem("token")}`);
}

export const logout = () => {
    return axios.get(`${WEB_SERVICE_URL}user/logout?token=${localStorage.getItem("token")}`);
}



export const getDomains = () => {
    return axios.get(`${WEB_SERVICE_URL}domain/getDomains?token=${localStorage.getItem("token")}`);
}

export const getDomainDetail = (domainId) => {
    return axios.post(`${WEB_SERVICE_URL}domain/getDomainDetail?token=${localStorage.getItem("token")}`,{params:{domainId}});
}

export const getSiteInfo = (domainId) => {    
    return axios.post(`${WEB_SERVICE_URL}domain/getSiteInfo?token=${localStorage.getItem("token")}`,{params:{domainId}});
}

export const updateDomainDetailSiteInfo = (params) => {
    return axios.post(`${WEB_SERVICE_URL}domain/updateDomainDetailSiteInfo?token=${localStorage.getItem("token")}`,{params:params});
}

export const createCookie = (params) => {
    return axios.post(`${WEB_SERVICE_URL}domain/createCookie?token=${localStorage.getItem("token")}`,{...params});
}

export const getFormSelects = () => {
    return axios.get(`${WEB_SERVICE_URL}domain/getFormSelects?token=${localStorage.getItem("token")}`);
}

export const allCategories  = (domainId) => {
    return axios.get(`${WEB_SERVICE_URL}category/allCategories?token=${localStorage.getItem("token")}${domainId?"&domain_id="+domainId:""}`);
}


export const updateDomainCookie = (params) => {
    return axios.post(`${WEB_SERVICE_URL}domain/updateDomainCookie?token=${localStorage.getItem("token")}`,{params:params});
}

export const changeSandboxMode = (params) => {
    return axios.post(`${WEB_SERVICE_URL}domain/changeSandboxMode?token=${localStorage.getItem("token")}`,{params:params});
}
export const addCategory = (params) => {
    return axios.post(`${WEB_SERVICE_URL}category/addCategory?token=${localStorage.getItem("token")}`,{params:params});
}
export const updateCategory = (params) => {
    return axios.post(`${WEB_SERVICE_URL}category/updateCategory?token=${localStorage.getItem("token")}`,{params:params});
}
export const deleteCategory = (params) => {
    return axios.post(`${WEB_SERVICE_URL}category/deleteCategory?token=${localStorage.getItem("token")}`,{...params});
}
export const getDomainLanguageDetail = (domainId) => {
    return axios.post(`${WEB_SERVICE_URL}domain/getDomainLanguageDetail?token=${localStorage.getItem("token")}`,{domainId});
}

export const getDomainCookies = (domainId) => {
    return axios.post(`${WEB_SERVICE_URL}domain/getDomainCookies?token=${localStorage.getItem("token")}`,{domainId});
}
export const getStatistics = (domainId) => {
    return axios.get(`${WEB_SERVICE_URL}domain/getStatistics?token=${localStorage.getItem("token")}${domainId?"&domain_id="+domainId:""}`);
}

export const getSitePreInfo = (domainId) => {
    return axios.post(`${WEB_SERVICE_URL}domain/getSitePreInfo?token=${localStorage.getItem("token")}`,{domainId});
}

export const saveDomainCategoriesLanguageDetail = (domainId,languageDetail) => {
    return axios.post(`${WEB_SERVICE_URL}domain/saveDomainCategoriesLanguageDetail?token=${localStorage.getItem("token")}`,{domainId,languageDetail});
}

export const saveDomainTextLanguageDetail = (domainId,languageDetail) => {
    return axios.post(`${WEB_SERVICE_URL}domain/saveDomainTextLanguageDetail?token=${localStorage.getItem("token")}`,{domainId,languageDetail});
}

export const saveDomainLanguageDetails = (domainId,languageDetail) => {
    return axios.post(`${WEB_SERVICE_URL}domain/saveDomainLanguageDetails?token=${localStorage.getItem("token")}`,{domainId,languageDetail});
}

export const updateDomainColors = (params) => {
    return axios.post(`${WEB_SERVICE_URL}domain/updateDomainColors?token=${localStorage.getItem("token")}`,params);
}

export const getDomainColors = (params) => {
    return axios.post(`${WEB_SERVICE_URL}domain/getDomainColors?token=${localStorage.getItem("token")}`,params);
}

export const getAccountPolicies = (domainId) => {
    return axios.get(`${WEB_SERVICE_URL}policies/getAccountPolicies?token=${localStorage.getItem("token")}${domainId?"&domainId="+domainId:""}`);
}

export const upsertDomainPolicy = (id,domainId,region_name,policy_id,index_key) => {
    return axios.post(`${WEB_SERVICE_URL}policies/upsertDomainPolicy?token=${localStorage.getItem("token")}`,{id,domainId,region_name,policy_id,index_key})
}

export const deleteDomainPolicy = (id,domainId,policy_id) => {
    return axios.post(`${WEB_SERVICE_URL}policies/deleteDomainPolicy?token=${localStorage.getItem("token")}`,{id,domainId,policy_id})
}

export const addNewPoliciyToAccount = (params) => {
    return axios.post(`${WEB_SERVICE_URL}policies/addNewPoliciyToAccount?token=${localStorage.getItem("token")}`,params);
}

export const deleteAccountPolicy = (params) => {
    return axios.post(`${WEB_SERVICE_URL}policies/deleteAccountPolicy?token=${localStorage.getItem("token")}`,params);
}

export const getPolicyDetail = (params) => {
    return axios.post(`${WEB_SERVICE_URL}policies/getPolicyDetail?token=${localStorage.getItem("token")}`,params);
}

export const getAccountUser = () => {
    return axios.get(`${WEB_SERVICE_URL}account/getAccountUser?token=${localStorage.getItem("token")}`);
}

export const updateDomainPolicy = (policyId,domainId) => {
    return axios.post(`${WEB_SERVICE_URL}policies/updateDomainPolicy?token=${localStorage.getItem("token")}`,{policyId, domainId});
}


export const createNewDomain = (params) => {
    return axios.post(`${WEB_SERVICE_URL}domain/createNewDomain?token=${localStorage.getItem("token")}`,params);
}




export const addNewUserToAccount = (params) => {
    return axios.post(`${WEB_SERVICE_URL}account/addNewUserToAccount?token=${localStorage.getItem("token")}`,params);
}

export const deleteUserFromAccount = (params) => {
    return axios.post(`${WEB_SERVICE_URL}account/deleteUserFromAccount?token=${localStorage.getItem("token")}`,params);
}


export const getRegisterUser = (params)=>{
    return axios.post (`${WEB_SERVICE_URL}user/getRegisterUser`,params)
}

export const saveRegisterUser  = (params)=>{
    return axios.post (`${WEB_SERVICE_URL}user/saveRegisterUser`,params)
}
export const getResendInvite = (params)=>{
    return axios.post (`${WEB_SERVICE_URL}account/resendInvite?token=${localStorage.getItem("token")}`,params)
}
export const getRoles = () => {
    return axios.get(`${WEB_SERVICE_URL}account/getRoles?token=${localStorage.getItem("token")}`)
}
export const getDashboard = () => {
    return axios.get(`${WEB_SERVICE_URL}account/getDashboard?token=${localStorage.getItem("token")}`)
}
export const getDomainLanguages=(params)=>{
    return axios.post(`${WEB_SERVICE_URL}domain/getDomainLanguages?token=${localStorage.getItem("token")}`,params);
}
export const updateDefaultLanguage=(params)=>{
    return axios.post(`${WEB_SERVICE_URL}domain/updateDefaultLanguage?token=${localStorage.getItem("token")}`,params);
}
export const getAllSystemLanguages = () => {
    return axios.get(`${WEB_SERVICE_URL}account/getAllSystemLanguages?token=${localStorage.getItem("token")}`)
}
export const createLanguageForDomain=(params)=>{
    return axios.post(`${WEB_SERVICE_URL}domain/createLanguageForDomain?token=${localStorage.getItem("token")}`,params);
}

export const updateLanguageSelectByBrowser=(params)=>{
    return axios.post(`${WEB_SERVICE_URL}domain/updateLanguageSelectByBrowser?token=${localStorage.getItem("token")}`,params);
}
export const updateUser = (params)=>{
    return axios.post (`${WEB_SERVICE_URL}account/updateUser?token=${localStorage.getItem("token")}`,params)
}
export const domainCookie = (params)=>{
    return axios.post (`${WEB_SERVICE_URL}domain/domainCookie?token=${localStorage.getItem("token")}`,params)
}

export const getThirdPartyPolicies = (domainId) => {
    return axios.get(`${WEB_SERVICE_URL}policies/getThirdPartyPolicies?token=${localStorage.getItem("token")}${domainId?"&domainId="+domainId:""}`);
}

export const updateThirdPartyPolicies = (params,domainId) =>{
    return axios.post (`${WEB_SERVICE_URL}policies/updateThirdPartyPolicies?token=${localStorage.getItem("token")}${domainId?"&domainId="+domainId:""}`,params)

}