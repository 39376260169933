import React, {Component, Fragment} from "react";
import BreadcrumbComponent from "../layout/breadcrumbComponent";




class SuperUserPoliciesComponent extends Component {

    render() {

       

        return (
            <div className="content-root ">
                <BreadcrumbComponent  breadName="Policies"/>

                <div className="content-wrapper">
                    {/* TODO --> table-container a active clası geldiğinde listelenen user lar gösterili  */}
                    <div className="content-block ">
                  
                    </div>
                </div>
            </div>
        );
    }
}

export default SuperUserPoliciesComponent;
