import React, {Component} from "react";

class ButtonBlockComponent extends Component {

    render() {
        let {buttonName} = this.props;
        return (
            <div className="btn-block">
                <div className="btn-item cancel">
                    <span>Cancel</span>
                </div>
                <button type="submit" className="btn-item save">
                    <span>Save</span>
                </button>
            </div>
        );
    }
}

export default ButtonBlockComponent ;
