import React, {Component} from "react";
import {ASSETS} from "../constants/Paths-prod";
import {Field, Form} from "react-final-form";
import {required} from "../helper";
import TextInputComponent from "../components/formFields/textInputComponent";
import RecaptchaFieldComponent from "../components/formFields/recaptchaFieldComponent";
import {NavLink} from "react-router-dom";
import {LOGIN_PAGE} from "../constants/routePaths";
import SlideComponent from "../components/slideComponent";
import PasswordResetForm2Component from "../components/forms/passwordResetForm2Component";
import CoverComponent from "../components/common/coverComponent";
import LogoComponent from "../components/common/logoComponent";


class faz2resetpassword3 extends Component {

    render() {


        return (
            <div className="login-root reset-pass3-root">

                <div className="login-wrapper">
                    <div className="login-left-wrapper">
                        <LogoComponent/>
                        <div className="login-content-container">
                            <div className="login-container">
                                <div className="title-block">
                                    <div className="title-item">
                                        <h3></h3>
                                    </div>
                                </div>
                                <PasswordResetForm2Component/>
                                <div className="bottom-btn-block">
                                    <div className="text-item">
                                        <span>I have an account</span>
                                    </div>
                                    <NavLink exact to={LOGIN_PAGE} className={`btn-item white-btn`}>
                                        <span>Login</span>
                                    </NavLink>

                                </div>



                            </div>
                        </div>
                    </div>

                    <CoverComponent/>
                </div>

            </div>



        );
    }
}

export default faz2resetpassword3;
