import React, {Component} from "react";
import AddButtonComponent from "../buttons/addButtonComponent";

class AddNewComponent extends Component {

    render() {
        let {buttonName, title, text, icon} = this.props;
        return (
            <div className="add-new-container">
                <div className="img-item">
                    {icon}
                </div>
                <div className="add-new-block">
                    <div className="desc-item">
                        <h3>{title}</h3>
                        <p>{text}</p>
                    </div>
                    <AddButtonComponent link={this.props.link} buttonName = {buttonName}/>
                </div>
            </div>
        );
    }
}

export default AddNewComponent;
