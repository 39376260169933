import {
   getDomains,
   getDomainDetail,
   createCookie,
   getFormSelects,
   updateDomainCookie,
   changeSandboxMode, getSiteInfo, updateDomainDetailSiteInfo,
   getDomainLanguageDetail,
   getDomainCookies,
   getSitePreInfo,
   updateDomainColors,
   getThirdPartyPolicies
} from "../services/webServices";
import {
   RECEIVE_USER_DOMAINS,
   RECEIVE_DOMAIN_DETAIL,
   CHANGE_COOKIE_CATEGORY,
   CHANGE_DOMAIN_SANDBOX_MODE,
   SEARCH_DOMAIN_BY_NAME, RECEIVE_SITE_INFO,
   RECEIVE_PRE_SITE_INFO,
   UPDATE_DOMAIN_DETAIL,
   RECEIVE_COOKIE_LIST,
   DELETE_COOKIE_LIST,
   RECEIVE_UNCATEGORIZED_COOKIE_LIST,
   DELETE_UNCATEGORIZED_COOKIE_LIST,
   RECEIVE_CATEGORY_LIST,
   RECEIVE_THIRD_PARTY
} from "../constants/actionTypes";

const receiveUserDomains = (payload) => ({
   type: RECEIVE_USER_DOMAINS,
   payload
})

export const getDomainsAction = () => dispatch => {
   getDomains().then((res) => {
      if (res.data.success) {
         dispatch(receiveUserDomains(res.data.success))
      }
   }).catch((err) => {
      console.log("err", err)
   })
}

const receiveDomainDetail = (payload) => ({
   type: RECEIVE_DOMAIN_DETAIL,
   payload
})

export const getDomainDetailAction = (domainId) => dispatch => {
   getDomainDetail(domainId).then((res) => {
      if (res.data.success) {
         dispatch(receiveDomainDetail({[domainId]: res.data.success}))
      }
   }).catch((err) => {
      console.log("err", err)
   })
}
const receiveSiteInfo = (payload) => ({
   type: RECEIVE_SITE_INFO,
   payload
})
export const getSiteInfoAction = (domainId,cb) => dispatch => {
   getSiteInfo(domainId).then((res) => {
      if (res.data.success) {
         dispatch(receiveSiteInfo({[domainId]: res.data.success}))
         cb(res.data.success)
      }
   }).catch((err) => {
      console.log("err", err)
   })
}
export const updateDomainDetailSiteInfoAction = (params, cb) => dispatch => {
   updateDomainDetailSiteInfo(params).then((res) => {
      if (res.data.success) {
         cb(res.data.success);
      } else {
         cb(false);
      }
   }).catch((err) => {
      cb(false);
      console.log("err", err)
   })
}
export const createCookieAction = (params, cb) => dispatch => {
   createCookie(params).then((res) => {
      if (res.data.success) {
         cb(true);
      } else {
         cb(false);
      }
   }).catch((err) => {
      cb(false);
      console.log("err", err)
   })
}

export const getFormSelectsAction = (cb) => dispatch => {
   getFormSelects().then((res) => {
      if (res.data.success) {
         cb(res.data.success);
      } else {
         cb(false);
      }
   }).catch((err) => {
      cb(false);
      console.log("err", err)
   })
}

const updateDomainCookieCategory = (domainId, cookie, newCategory) => ({
   type: CHANGE_COOKIE_CATEGORY,
   domainId,
   cookieId: cookie.id,
   newCategory
})

export const updateDomainCookieAction = (params, cb) => dispatch => {
   updateDomainCookie(params).then((res) => {
      if (res.data.success) {
         dispatch(updateDomainCookieCategory(params.domainId, params.cookie, params.category))
         //dispatch(receiveDomainDetail({[params.domainId]:res.data.success}))
         cb(res.data.success);
      } else {
         cb(false);
      }
   }).catch((err) => {
      cb(false);
      console.log("err", err)
   })
}

const updateDomainSandBoxMode = (domainId, is_sandbox) => ({
   type: CHANGE_DOMAIN_SANDBOX_MODE,
   is_sandbox,
   domainId
})

export const changeSandboxModeAction = (params) => dispatch => {
   changeSandboxMode(params).then((res) => {
      if (res.data.success) {
         dispatch(updateDomainSandBoxMode(params.domainId, params.sandboxMode))
      }
   }).catch((err) => {
      console.log("err", err)
   })
}

export const searchAction = (payload) => ({
   type: SEARCH_DOMAIN_BY_NAME,
   payload
})

export const getDomainLanguageDetailAction = (params,cb) => dispatch => {
   getDomainLanguageDetail(params).then((res) => {
      if (res.data.success) {
         cb(res.data.success)
      }
   }).catch((err) => {
      console.log("err", err)
   })
}
const receiveCookieInfo = (payload) => ({
   type: RECEIVE_COOKIE_LIST,
   payload
})
const receiveUncCookieInfo = (payload) => ({
   type: RECEIVE_UNCATEGORIZED_COOKIE_LIST,
   payload
})
export const deleteUncategorizedCoolieListAction = (payload) => ({
   type: DELETE_UNCATEGORIZED_COOKIE_LIST,
   payload
});
export const deleteCoolieListAction = (payload) => ({
   type: DELETE_COOKIE_LIST,
   payload
});

export const receiveCategoryLits = (payload) => ({
   type: RECEIVE_CATEGORY_LIST,
   payload
});
export const getDomainCookiesAction = (params,cb) => dispatch => {
   getDomainCookies(params).then((res) => {
      if (res.data.success) {
         cb(res.data.success)
         dispatch(receiveCookieInfo(res.data.success.cookies))
         dispatch(receiveUncCookieInfo(res.data.success.unCategorizedCookies))
         dispatch(receiveCategoryLits(res.data.success.allCategories))

      }
   }).catch((err) => {
      console.log("err", err)
   })
} 

const receivePreSiteInfo = (payload) => ({
   type: RECEIVE_PRE_SITE_INFO,
   payload
})

export const getSitePreInfoAction = (domainId) => dispatch => {
   getSitePreInfo(domainId).then((res) => {
      if (res.data.success) {
         dispatch(receivePreSiteInfo({[domainId]: res.data.success}))
      }
   }).catch((err) => {
      console.log("err", err)
   })
} 

const updateDomain = (domainId,domainDetail) => ({
   type: UPDATE_DOMAIN_DETAIL,
   domainDetail,
   domainId
})

export const updateDomainColorsAction = (params) => dispatch => {
   updateDomainColors(params).then((res)=>{
       if(res.data.success){
           dispatch(updateDomain(params.domainId,params.domainDetail))
       }
   }).catch((err)=>{
       console.log("err",err)
   })
}

const thirdParty = (payload) => ({
   type: RECEIVE_THIRD_PARTY,
   payload
})

export const thirdPartyAction = (domain_id) => dispatch => {
   getThirdPartyPolicies(domain_id).then((res)=>{
       if(res.data.success){
           dispatch(thirdParty(res.data.success))
           return res == true
       }else{
         return res ==false
       }
   }).catch((err)=>{
       console.log("err",err)
   })
}