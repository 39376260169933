import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import TextInputComponent from '../formFields/textInputComponent';
import SelectBoxComponent from '../formFields/selectBoxComponent';
import { upsertDomainPolicy } from '../../services/webServices';

class PolicyAddAudienceLbxComponent extends Component {
    optionArr = []
    state = {
        keyIndex: ""
    }
    validate = (values) => { 
        const errors = {};

        if (!values || !values.regions || values.regions === "") {
           errors.regions = "Zorunlu";
        }

        if (!values || !values.template || values.template === "") {
            errors.template = "Zorunlu";
        }

        return errors;
    }
    submit = (values) => {
        let domainId = window.location.pathname.split("/")[2];
        upsertDomainPolicy(null, domainId, values.regions, values.template, this.state.keyIndex).then((res)=> {
            if(res.data.success){
                this.props.getPolicies();
                this.props.toggleNewAudience();
            }
        }).catch(err => console.log("ERR---", err))
    }

    componentDidMount(){
        this.setOptionArr();
        this.findIndexKey();
    }

    setOptionArr = () => {
        if(this.props.policyArr){
            this.props.policyArr.forEach(item => {
                let optionItem = {
                    value: item.id,
                    text: item.name
                }
                this.optionArr.push(optionItem)
            })
        }
    }

    findIndexKey = () => {
        let keyIndexArr = [];
        if(this.props.domainArr && this.props.domainArr.length > 0){
            this.props.domainArr.forEach(item => {
                keyIndexArr.push(item.index_key)
            })
            let max = keyIndexArr.reduce((a, b) => { return Math.max(a, b) })
            this.setState({
                keyIndex: parseInt(max) + 1
            })
        }else{
            this.setState({
                keyIndex: 1
            })
        }
    }
    
    render() {
        let { isNewAudienceOpen, toggleNewAudience } = this.props;
        return (
            <div className={`lightBox-root ${isNewAudienceOpen ? "audience" : ""}`}>
                <div className="lightBox-wrapper">
                    <Form onSubmit={this.submit}
                        validate={this.validate}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="lightBox-container">
                                    <div className="head-item">
                                        <h2 className='light-box-title'>Add Audience</h2>
                                        <div className="close-icon" onClick={toggleNewAudience}>
                                            <i>
                                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                            </i>
                                        </div>
                                    </div>
                                    <div className="form-block">
                                        <Field
                                            name={"regions"}
                                            type="text"
                                            label="Regions*"
                                            component={TextInputComponent}
                                            placeholder="Enter region(s)"
                                            isRegion={true}
                                        />
                                        <Field
                                            name={"id"}
                                            type="text"
                                            label="ID"
                                            component={TextInputComponent}
                                            placeholder={this.state.keyIndex}
                                            isID={true}
                                        />
                                        <Field
                                            name={"template"}
                                            label="Policy template*"
                                            component={SelectBoxComponent}
                                            options={this.optionArr}
                                            placeholder="Select Template"
                                        />
                                    </div>
                                    <div className="btn-block">
                                        <div className="btn-item cancel" onClick={toggleNewAudience}>
                                            <span>Cancel</span>
                                        </div>
                                        <button className="btn-item" type='submit'>
                                            <span>Add Audience</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}>
                    </Form>
                </div>
            </div>
        );
    }
}

export default PolicyAddAudienceLbxComponent;