import React, { Component } from 'react';
import { RECAPCTHA_KEY } from "../../constants/strings";
import ReCAPTCHA from "react-google-recaptcha";

class RecaptchaFieldComponent extends Component {
    recaptchaRef = React.createRef();

    onRecaptchaChange = (value) => {
        this.props.input.onChange(value);
    };

    onRecaptchaExpired = () => {
        this.props.input.onChange(null);

    };
    constructor() {
        super();
        this.key = 0;
    }
    componentDidUpdate() {
        if (this.recaptchaRef) {
            if (this.props.reCaptchaControl) {
                window.grecaptcha.reset();
                this.props.changeRecaptcha(false)
            }
        }
 }
 onKey=()=>{
    this.key=this.key+Math.random()
 }
    render() {
        const { meta: { error, submitFailed }, isFailed ,input, reCaptchaControl} = this.props;
        return (
            <div className={`recaptcha-wrapper ${error && submitFailed ? "error" : ""}`}>

                <ReCAPTCHA
                name="recaptcha_code"
                key={this.key}
                ref={this.recaptchaRef}
                sitekey={RECAPCTHA_KEY}
                onChange={this.onRecaptchaChange}
                onExpired={this.onRecaptchaExpired}
            />
                
                        {/* <ReCAPTCHA
                            name="recaptcha_code"
                            key={this.key}
                            ref={this.recaptchaRef}
                            sitekey={RECAPCTHA_KEY}
                            onChange={this.onRecaptchaChange}
                            onExpired={this.onRecaptchaExpired} 
                        />*/}
                {/* <ReCAPTCHA
                name="recaptcha_code"
                    key={this.key}
                    ref={this.recaptchaRef}
                    sitekey={RECAPCTHA_KEY}
                    onChange={this.onRecaptchaChange}
                    onExpired={this.onRecaptchaExpired}
                /> */}
                {
                    (submitFailed && error) &&
                    <p className="err-txt">
                        Eksik veya hatalı bilgileri gözden geçirip tekrar deneyiniz.
                    </p>
                }
            </div>
        );
    }
}

export default RecaptchaFieldComponent;
