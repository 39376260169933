import React, { Component } from 'react';
import AddButtonComponent from "../../components/buttons/addButtonComponent";
import ErrorComponent from '../../components/layout/errorComponent';
import SucsessComponent from '../../components/layout/sucsessComponent';
import AddLangueModalComponent from '../../components/modal/addLangueModalComponent';
import { updateLanguageSelectByBrowser } from '../../services/webServices';
import { getDomainsAction, searchAction } from "../../actions/domainActions";
import { connect } from 'react-redux';
import { ASSETS } from '../../constants/Paths';
import LanguageSelectLbxComponent from './languageSelectLbxComponent';

class LanguageListContainer extends Component {

    state = {
        addLang: false,
        success: false,
        isUp: false,
        error: false,
        isLangOpen: false
    }
    orderType = "desc"
    orderName = "id"

    onSearch = (searchedText) => {
        this.setState({
            searchedText
        })

    }
    toggleLang = () => {
        this.setState({
            isLangOpen: !this.state.isLangOpen
        })
    }
    order = (_orderName) => {
        this.orderName = _orderName
        this.orderType = this.orderType === "desc" ? "asc" : "desc"

        this.setState({
            isUp: this.orderType === "asc"
        })
        console.log(this.orderType)

    }
    sortFunc = (a, b) => {
        if (this.orderType === "asc") {
            return (a[this.orderName] > b[this.orderName] ? 1 : b[this.orderName] > a[this.orderName] ? -1 : 0)
        } else {
            return (a[this.orderName] > b[this.orderName] ? -1 : b[this.orderName] > a[this.orderName] ? 1 : 0)
        }
    }
    addLangOpen = () => {
        this.setState({
            addLang: true
        })

    }
    addLangClose = () => {
        this.props.getLanguages();
        this.setState({
            addLang: false
        });

    }
    sorted = (item) => {
        if (item === "name") {
            console.log("name bu")
            this.setState({
                type: "name",
                sorted: true
            })
            if (this.state.sorted) {
                this.setState({

                    sorted: false
                })
            }
            this.props.getDomainCookies(this.state.type);
        }
        else if (item === "category") {
            console.log("category bu")
            this.setState({
                type: "category"
            })
            this.props.getLanguages(this.state.type);
        }
        this.forceUpdate();
    }
    updateBroweserSelection = (value) => {
        updateLanguageSelectByBrowser({
            domainId: this.props.domainId,
            set_language_by_browser: value
        }).then((res) => {
            if (res.data.success) {
                this.props.getLanguages();
                this.setState({
                    success: true
                })
                this.setTimer();
                this.toggleLang();
            } else {
                this.setState({
                    error: true
                })
                this.setTimer();
            }
        }).catch((err) => {
            this.setState({
                error: true
            })
            this.setTimer();
            console.log(err);
        })
    }

    setTimer = () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                success: false,
                error: false

            })
        }, 2000)
    }

    render() {
        let { langueArr, selectLang, set_language_by_browser } = this.props;
        console.log("set_language_by_browser---", set_language_by_browser);
        return (
            <div className="lang-root">
                <SucsessComponent warn={this.state.success} message={"Settings updated."} />
                <div className="lang-container">
                    <div className="lang-block">
                        <div className="text-block">
                            <span className="text-title-item">Select language according browser</span>
                            <p className="text-desc-item">When this feature is turned on, the language is
                                changed according to the user's browser settings.</p>
                        </div>
                        <div className="radio-block">
                            <div className="default-block">
                                <span>{set_language_by_browser == 1 ? "On" : set_language_by_browser == 0 ? "Off" : "-"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="edit-block" onClick={this.toggleLang}>
                        <span>Edit default</span>
                        <div className='edit-item' >
                            <img src={ASSETS + "img/edit.svg"} alt="edit" />
                        </div>
                    </div>
                </div>
                <div className="table-container">
                    <div className="table-head-block">
                        <div className="row-item">
                            <div className="row-td name-td">
                                <span className={this.state.isUp && this.orderName === "name" ? "up" : ""} onClick={() => this.order("name")}>Language</span>
                                {/* <div className="search-block">
                                        <div className="input-item">
                                            <input type="search" placeholder="Search" />
                                            <i className="icon-item">
                                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="7.5" cy="7.5" r="4.5" stroke="#A4A1C0" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11 11L13 13" stroke="#A4A1C0" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </i>
                                        </div>
                                    </div> */}
                            </div>

                            <div className="row-td status-td">
                                <span className={this.state.isUp && this.orderName === "status" ? "up" : ""} onClick={() => this.order("status")}>Status</span>
                            </div>
                            <div className="row-td button-td" onClick={() => { this.addLangOpen() }}>
                                <AddButtonComponent buttonName="Add Language" />
                            </div>
                        </div>
                    </div>
                    <div className="table-block">

                        {/*TODO: Component için kullanılacak satır.*/}
                        {
                            langueArr.sort(this.sortFunc).map((obj, index) => {
                                return (
                                    <div className="row-item" key={index}>

                                        <div className={`row-td name-td  ${obj.is_default === true ? "default" : ""}`}>
                                            <span>{obj.language.language_name}</span>
                                        </div>

                                        <div className="row-td status-td table">
                                            {/* TODO -- NOT ACTİVE PASSİVE */}

                                            <span className={`status-color  ${obj.is_active === true ? "active" : "passive"}`}></span>
                                        </div>
                                        <div className="row-td button-td">
                                            <div className="create-btn" onClick={() => { this.props.changeActiveLanguageTab("translations", obj.language.id) }} >
                                                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width={32} height={32} fill="white" />
                                                    <path d="M14 18L17 17L23.5858 10.4142C23.851 10.149 24 9.78929 24 9.41421C24 8.63317 23.3668 8 22.5858 8C22.2107 8 21.851 8.149 21.5858 8.41421L15 15L14 18Z" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M16 9H11C9.89543 9 9 9.89543 9 11V21C9 22.1046 9.89543 23 11 23H21C22.1046 23 23 22.1046 23 21V16" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                        }

                        {/*  <div className="row-item">
                                <div className="row-td name-td">
                                    <span>Spanish</span>
                                </div>
                             
                                <div className="row-td status-td table">
                                    <span className="status-color passive"></span>
                                </div>
                                <div className="row-td button-td">
                                    <div className="create-btn" onClick={()=>{ this.changeActiveLanguageTab("translations")}}>
                                        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width={32} height={32} fill="white" />
                                            <path d="M14 18L17 17L23.5858 10.4142C23.851 10.149 24 9.78929 24 9.41421C24 8.63317 23.3668 8 22.5858 8C22.2107 8 21.851 8.149 21.5858 8.41421L15 15L14 18Z" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M16 9H11C9.89543 9 9 9.89543 9 11V21C9 22.1046 9.89543 23 11 23H21C22.1046 23 23 22.1046 23 21V16" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                
                                </div>
                            </div>
                            <div className="row-item">
                                <div className="row-td name-td">
                                    <span>Portuguese</span>
                                </div>
                             
                                <div className="row-td status-td table">
                                    <span className="status-color not"></span>
                                </div>
                                <div className="row-td button-td">
                                    <div className="create-btn" onClick={()=>{ this.changeActiveLanguageTab("translations")}}>
                                        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width={32} height={32} fill="white" />
                                            <path d="M14 18L17 17L23.5858 10.4142C23.851 10.149 24 9.78929 24 9.41421C24 8.63317 23.3668 8 22.5858 8C22.2107 8 21.851 8.149 21.5858 8.41421L15 15L14 18Z" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M16 9H11C9.89543 9 9 9.89543 9 11V21C9 22.1046 9.89543 23 11 23H21C22.1046 23 23 22.1046 23 21V16" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                
                                </div>
                            </div>
                            <div className="row-item">
                                <div className="row-td name-td">
                                    <span>Korean</span>
                                </div>
                             
                                <div className="row-td status-td table">
                                    <span className="status-color not"></span>
                                </div>
                                <div className="row-td button-td">
                                    <div className="create-btn" onClick={()=>{ this.changeActiveLanguageTab("translations")}}>
                                        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width={32} height={32} fill="white" />
                                            <path d="M14 18L17 17L23.5858 10.4142C23.851 10.149 24 9.78929 24 9.41421C24 8.63317 23.3668 8 22.5858 8C22.2107 8 21.851 8.149 21.5858 8.41421L15 15L14 18Z" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M16 9H11C9.89543 9 9 9.89543 9 11V21C9 22.1046 9.89543 23 11 23H21C22.1046 23 23 22.1046 23 21V16" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                
                                </div>
                            </div> */}


                    </div>
                    {
                        this.state.addLang === true ?
                            <AddLangueModalComponent addLangClose={this.addLangClose} langueArr={langueArr} domainId={this.props.domainId} />
                            :
                            <>
                            </>
                    }
                </div>
                <ErrorComponent warn={this.state.error} />
                {
                    this.state.isLangOpen &&
                    <LanguageSelectLbxComponent isLangOpen={this.state.isLangOpen} toggleLang={this.toggleLang} set_language_by_browser={set_language_by_browser} updateBroweserSelection={this.updateBroweserSelection} />
                }
            </div>

        )
    }
};
const mapStateToProps = (state, ownProps) => ({
    domains: state.domainReducer.domains || [],
    filteredDomains: state.domainReducer.filteredDomains,

})
export default connect(mapStateToProps)(LanguageListContainer);