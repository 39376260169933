import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';

import TextInputComponent from '../formFields/textInputComponent';
import LoaderComponent from '../loaderComponent';
import SelectBoxComponent from '../formFields/selectBoxComponent';
import RadioInputComponent from '../formFields/radioInputComponent';
import RadioCheckboxComponent from '../formFields/radioCheckboxComponent';
import { createCookie, logout } from '../../services/webServices';
import SucsessComponent from '../layout/sucsessComponent';

const validate = (values) => {
    const errors = {};

    if (!values || !values.cookieName || values.cookieName === "") {
        errors.cookieName = "Zorunlu";
    }

    return errors;
};

class AddCookieListFormComponent extends Component {
    state = {
        isLoading: false,
        categoryOptionArr: [],
        success: false
    }

    componentDidMount() {
        let products = this.props.allCategories.map((ctr, key) => ({
            value: ctr.id,
            text: ctr.category_name,

        }))
        this.setState({
            categoryOptionArr: products
        })
    }

    componentWillUnmount(){
        let products = this.props.allCategories.map((ctr, key) => ({
            value: ctr.id,
            text: ctr.category_name,

        }))
        this.setState({
            categoryOptionArr: !products
        })
    }

    submit = (values) => {
        // this.setState({
        //     isLoading: true
        // })

        let cookieDetail = { id: undefined, cookie_category_id: values.category, description: values.description, is_abroad: values.cookieSource === 1 ? true : false, cookie_name: values.cookieName }

        createCookie({ cookieDetail, domain_id: this.props.domainId }).then((result) => {
            if (result.data.success) {
                this.success()
                this.props.closeNewCookie()
                document.body.classList.remove("no-scroll");
                this.props.setNewCookie();
            }

        })

    }

    success = () => {
        this.setState({
            success: true
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                success: false
            })
        }, 2000)
    }
    optionArr = [
        {
            value: 1,
            text: "test"
        },
        {
            value: 2,
            text: "test2"
        }
    ]

    render() {
        let { isLoading, categoryOptionArr } = this.state
        return (
            <div>
                {
                    isLoading &&
                    <LoaderComponent />
                }
                <Form onSubmit={this.submit}
                    validate={validate}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="lightBox-container">
                                <div className="head-item">
                                    <h2 className="light-box-title">Add Cookie</h2>
                                    <div className="close-icon" onClick={() => this.props.closeNewCookie()}>
                                        <i>
                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </i>
                                    </div>
                                </div>

                                <Field
                                    name={"cookieName"}
                                    type="text"
                                    label="Cookie Name *"
                                    component={TextInputComponent}
                                    placeholder="Cookie Name"

                                />
                                {/* <Field
                                    name={"host"}
                                    type="text"
                                    label="Host *"
                                    component={TextInputComponent}
                                    placeholder="Exm. gulenayva.cookie.com"
                                /> */}
                                {/* <Field
                                    name={"lifespan"}

                                    label="Lifespan *"
                                    component={SelectBoxComponent}
                                    options={this.optionArr}
                                    placeholder="Exm. Session"
                                /> */}
                                <Field
                                    name={"category"}

                                    label="Category *"
                                    component={SelectBoxComponent}
                                    options={categoryOptionArr}
                                    placeholder="Select Category"
                                />

                                <Field
                                    name={"description"}
                                    type="text"
                                    label="Description *"
                                    component={TextInputComponent}
                                    placeholder="Write description"
                                />
                                {/* <div className="radio-container">
                                    <h3>Select cookie party *</h3>
                                    <div className="radio-block">
                                        <div className="radio-item">
                                            <Field
                                                name={"cookieParty"}                                             
                                                component={RadioInputComponent}
                                                options={[{ value: 1, name: "First Party Cookies" }, { value: 2, name: "Third Party" }]}
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="radio-container">
                                    <h3>This cookie source can store data to abroad server *</h3>
                                    <div className="radio-block">
                                        <div className="radio-item">
                                            <Field
                                                name={"cookieSource"}

                                                component={RadioInputComponent}
                                                options={[{ value: 1, name: "Yes" }, { value: 2, name: "No" }]}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="btn-block">
                                    <div className="btn-item cancel" onClick={() => this.props.closeNewCookie()}>
                                        <span>Cancel</span>
                                    </div>
                                    <button type="submit" className="btn-item">
                                        <span>Add Cookie</span>
                                    </button>
                                </div>
                            </div>


                        </form>
                    )}>

                </Form>
                <SucsessComponent warn={this.state.success} message={"Cookie Eklendi"} />
            </div>
        );
    }
}

export default AddCookieListFormComponent;
