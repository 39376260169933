import React, { Component } from 'react';

class MultiCheckboxComponent extends Component {
    onChange = (id) => {
        let selectedArr = this.props.input.value?[...this.props.input.value]:[]
        let index = selectedArr.indexOf(id);
        if(index!==-1){
            selectedArr.splice(index,1)
        }else{
            selectedArr.push(id);
        }
        this.props.input.onChange(selectedArr)
    }
    render() {
        let { options, input } = this.props;
        return (
            <div className="check-box-block general">
                {
                    options.map((optionItem, key) =>
                        <div onClick={()=>{
                            if(!optionItem.default){
                                this.onChange(optionItem.id.toString())
                            }
                        }} className="container" key={key}>
                            <input onChange={()=>{}} value={optionItem.id} type="checkbox" checked={(input.value && input.value.indexOf(optionItem.id.toString())!==-1) || optionItem.default ? "checked":""} className={optionItem.default ? "default" : ""} />
                            <label htmlFor="">{optionItem.name}</label>
                        </div>
                    )
                }
            </div>
        )
    }
};

export default MultiCheckboxComponent