import React, {Component} from "react";
import AsideComponent from "../components/layout/asideComponent";
import BreadcrumbComponent from "../components/layout/breadcrumbComponent";



class SdkPage extends Component {
    state = {
        activeWindow: "SDK Version 2.1"
    }
    cookieScriptCopied = false;
    exampleScriptCopied = false;

    copyToClipBoard = (copyTextValue,copiedScriptKey) => {
        var dummy = document.createElement('input'),
            text = copyTextValue;
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        this[copiedScriptKey] = true;
        this.forceUpdate()
        this[`timeout${copiedScriptKey}`] = setTimeout(()=>{
            this[copiedScriptKey] = false;
            this.forceUpdate()
        },1000)
    }

    componentWillUnmount(){
        clearTimeout(this[`timeoutcookieScriptCopied`])
        clearTimeout(this[`timeoutexampleScriptCopied`])
    }
    activeWindov = (activeWindow) => {
        this.setState({
            activeWindow
        })
    }
    render() {
       

       
        const codeText1 = 
        `
    git clone $PATH_TO_YOUR_FORK
    cd BIG-bench
    git checkout -b my_awesome_json_task
        `;
   
        const codeText2 = 
        `
    cd bigbench/benchmark_tasks
    cp -r simple_arithmetic_json my_awesome_json_task
    cd my_awesome_json_task
        `;

        return (
            <div className="content-root sdk-root">
                
                <BreadcrumbComponent breadName="SDK" />

                <div className="content-wrapper">
                    <div className="content-container">
                        <div className="tab-btn-block">
                            {/* TODO --> active classı seçili olan sayfayı gösterir */}
                            <div className={`tab-btn-item ${this.state.activeWindow==="SDK Version 2.1"?"active":""}`} onClick={()=>{
                                this.activeWindov("SDK Version 2.1")
                            }}>
                                <span>SDK Version 2.1</span>
                            </div>
                            <div className={`tab-btn-item ${this.state.activeWindow==="SDK Version 2.2"?"active":""}`} onClick={()=>{
                                this.activeWindov("SDK Version 2.2")
                            }}>
                                <span>SDK Version 2.2</span>
                                <p>New</p>

                            </div>
                        
                        </div>
                        <div className="content-block">
                            <div className="head-item">                      
                               <h2>Realese Notes</h2>
                               <h3>SDK Version 2.1</h3>
                            </div>
                            <div className="text-item">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eu bibendum vulputate facilisis. Fringilla id id tristique suspendisse egestas vehicula. In tempor erat molestie viverra vulputate feugiat consequat, augue dignissim. Ut commodo facilisis mauris mi, sagittis eu. Morbi faucibus ac sagittis volutpat vitae. Metus amet porta mollis dignissim.</p>
                                <ul>
                                    <li>Lorem ipsum dolor sit amet,  adipiscing elit. Neque, sapien molestie varius faucibus. Fringilla est sit blandit tellus. Ultrices proin aliquam venenatis lacus. Facilisis nisl ipsum amet purus </li>
                                    <li>Arcu tellus at purus scelerisque. Mattis enim diam aliquet vestibulum a, sit. Non mattis convallis ultrices auctor ac est, ultrices pellentesque nam. Sit facilisis enim bibendum libero. Consectetur a non vitae in varius et aliquet. </li>
                                    <li>Vitae mattis interdum velit id porttitor ultrices posuere fermentum aliquam. Consectetur nunc faucibus elementum pulvinar ullamcorper est sit dictum. Amet id imperdiet mi volutpat turpis eget est. </li>
                                </ul>
                            </div>
                            <div className="description-table-block">
                                        <div className="description-table-head-item">
                                            <div className="row-th name-th">
                                                <span>Field</span>
                                           
                                            </div>
                                            <div className="row-th lang-th">
                                                <span>Description</span>
                                            </div>
                                          
                                        </div>
                                        <div className="description-table-td-item">
                                            <div className="row-td  name-td">
                                                <span>event</span>
                                            </div>
                                            <div className="row-td  lang-td">
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu cursus nisl mauris elit condimentum.</span>
                                            </div>
                                     

                                        </div>
                                        <div className="description-table-td-item">
                                            <div className="row-td  name-td">
                                                <span>getCategories</span>
                                            </div>
                                            <div className="row-td  lang-td">
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa lectus in aliquet viverra at ultrices</span>
                                            </div>
   

                                        </div>
                                        <div className="description-table-td-item">
                                            <div className="row-td  name-td">
                                                <span>open</span>
                                            </div>
                                            <div className="row-td  lang-td">
                                                <span>Lorem Ipsum</span>
                                            </div>
                                           

                                        </div>
                                        <div className="description-table-td-item">
                                            <div className="row-td  name-td">
                                                <span>close</span>
                                            </div>
                                            <div className="row-td  lang-td">
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing </span>
                                            </div>
                                           

                                        </div>
                                        <div className="description-table-td-item">
                                            <div className="row-td  name-td">
                                                <span>desc</span>
                                            </div>
                                            <div className="row-td  lang-td">
                                                <span>Tanımlama bilgilerini; sitemizin doğru şekilde çalışmasını sağlamak, içerikleri ve reklamları kişiselleştirmek, sosyal medya özellikleri sunmak ve site trafiğimizi analiz etmek için kullanıyoruz. Aynı zamanda site kullanımınızla ilgili bilgileri; sosyal medya, reklamcılık ve analiz ortaklarımızla paylaşıyoruz.</span>
                                            </div>
                                          

                                        </div>
                                        <div className="description-table-td-item">
                                            <div className="row-td  name-td">
                                                <span>onChange</span>
                                            </div>
                                            <div className="row-td  lang-td">
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id magna tempor at porttitor lobortis tortor vitae. Facilisis semper vitae nisl, erat eros, pulvinar. Mi commodo convallis bibendum.</span>
                                            </div>
                                           

                                        </div>
                                        <div className="description-table-td-item">
                                            <div className="row-td  name-td">
                                                <span>title</span>
                                            </div>
                                            <div className="row-td  lang-td">
                                                <span>Çerez izinlerinizi yönetin</span>
                                            </div>
                                           

                                        </div>
                            </div>
                            <div className="text-item">
                                <h2>Installation</h2>
                                <p>Lorem ipsum dolor sit amet, <span>dolor sit amet</span> consectetur adipiscing elit. Eget eu commodo non interdum lectus dolor habitant. Diam tortor, posuere faucibus sed turpis. Nullam blandit mauris a, Nisi, est tellus <span>dolor sit amet</span>arcu convallis interdum ac in. Facilisis lacinia ullamcorper nibh enim sociis. Vivamus.consectetur adipiscing elit. Nisi, est tellus arcu convallisa</p>

                          
                            </div>
                            <div className="scripts-container">
                            <div className="scripts-item">
                            <div className={`copy-code ${this.cookieScriptCopied?"copied":""}`} onClick={()=>{this.copyToClipBoard(codeText1,"cookieScriptCopied")}}>
                                        <span>Copy</span>
                                        
                                    </div>
                                    <div className="script">
                                        <pre>
                                            {codeText1}
                                        </pre>
                                    </div>
                               
                            </div>
                            </div>
                          
                            <div className="text-item">
                          
                                <p>We will base our task on an existing example. Create a new task directory by copying over the example task:</p>

                          
                            </div>
                            <div className="scripts-container">
                            <div className="scripts-item">
                            <div className={`copy-code ${this.cookieScriptCopied?"copied":""}`} onClick={()=>{this.copyToClipBoard(codeText2,"cookieScriptCopied")}}>
                                        <span>Copy</span>
                                        
                                    </div>
                                    <div className="script">
                                        <pre>
                                            {codeText2}
                                        </pre>
                                    </div>
                                   
                            </div>
                            </div>
                            
                        </div>
                    </div>
                    
                    <AsideComponent/>

                </div>
            </div>
        );
    }
}

export default SdkPage;
