import React, { Component } from "react";

class AsideNotifyComponent extends Component {

    render() {
        let { did_integrate, preSiteInfo } = this.props;
        return (
            <aside className="aside-root">
                <div className="aside-container">
                    <div className="aside-block sdk">

                        <div className={`text-item sdk-text ${did_integrate ? "integrated" : "not_integrated"}`}>
                            <span>{did_integrate ? "Integration works correctly" : "Not integrated"}</span>
                            <i className="integrated" >
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="16" fill="#E1FCDE" />
                                    <circle cx="16" cy="16" r="8" stroke="#2ACF1C" stroke-width="2"
                                        strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M13 17L15 19L20 14" stroke="#2ACF1C" stroke-width="2"
                                        strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </i>
                            <i className="not_integrated">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6667 19H7.33333C5.82349 19 4.85811 17.391 5.56863 16.0588L10.2353 7.30882C10.9882 5.89706 13.0118 5.89706 13.7647 7.30882L18.4314 16.0588C19.1419 17.391 18.1765 19 16.6667 19Z" stroke="#FF0000" stroke-width="2" strokeLinejoin="round"></path><path d="M12 10V11.5V13" stroke="#FF0000" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"></path><circle cx="12" cy="16" r="1" fill="#FF0000"></circle></svg>
                            </i>
                        </div>
                        <div className="text-item">
                            <span>SDK version 1.0</span>
                        </div>
                    </div>
                </div>
                <div className="aside-container">
                    <div className="title-item">
                        <span>Alerts</span>
                    </div>
                    <div className="aside-block notify">
                        {/* <div className="notify-item btn-active">
                            <div className="notify-text">
                                <i>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.6667 19H7.33333C5.82349 19 4.85811 17.391 5.56863 16.0588L10.2353 7.30882C10.9882 5.89706 13.0118 5.89706 13.7647 7.30882L18.4314 16.0588C19.1419 17.391 18.1765 19 16.6667 19Z"
                                            stroke="#FF0000" stroke-width="2" strokeLinejoin="round" />
                                        <path d="M12 10V11.5V13" stroke="#FF0000" stroke-width="2"
                                            strokeLinecap="round" strokeLinejoin="round" />
                                        <circle cx="12" cy="16" r="1" fill="#FF0000" />
                                    </svg>
                                </i>
                                <div className="text-item">

                                    <span>The system will not work correctly because the default policy is not defined</span>
                                </div>
                            </div>
                            <a href="#" className="notify-btn">
                                <span>Manage Policies</span>
                            </a>
                        </div> */}
                        {/*TODO: notify-item'ın içinde butonun gözükmesi için bu div'e btn-active class'ı gelmeli.*/}
                        {
                            (preSiteInfo && preSiteInfo.unCategorizedCookieCount > 0) &&
                            <div className="notify-item">
                                <div className="notify-text">
                                    <i>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M16.6667 19H7.33333C5.82349 19 4.85811 17.391 5.56863 16.0588L10.2353 7.30882C10.9882 5.89706 13.0118 5.89706 13.7647 7.30882L18.4314 16.0588C19.1419 17.391 18.1765 19 16.6667 19Z"
                                                stroke="#FF0000" stroke-width="2" strokeLinejoin="round" />
                                            <path d="M12 10V11.5V13" stroke="#FF0000" stroke-width="2"
                                                strokeLinecap="round" strokeLinejoin="round" />
                                            <circle cx="12" cy="16" r="1" fill="#FF0000" />
                                        </svg>
                                    </i>
                                    <div className="text-item">
                                        <span>There is uncategorized cookies in cookie list.</span>
                                    </div>

                                </div>
                            </div>
                        }

                        {/* <div className="notify-item">
                            <div className="notify-text">
                                <i>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="7" stroke="#FF961B" stroke-width="2"
                                            strokeLinejoin="round" />
                                        <path d="M12 9V10.5V12" stroke="#FF961B" stroke-width="2"
                                            strokeLinecap="round" strokeLinejoin="round" />
                                        <circle cx="12" cy="15" r="1" fill="#FF961B" />
                                    </svg>
                                </i>
                                <div className="text-item">
                                    <span>New sdk is available now.</span>

                                </div>

                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="aside-container">
                    <div className="title-item">
                        <span>Tips</span>
                    </div>
                    <div className="aside-block notify">
                        <div className="notify-item">
                            <div className="notify-text">
                                <i>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.8983 4.72721C11.5665 4.28611 12.4335 4.28611 13.1017 4.72721L14.28 5.50492C14.4929 5.64542 14.7306 5.74391 14.9805 5.79508L16.3636 6.07831C17.148 6.23895 17.7611 6.85197 17.9217 7.63641L18.2049 9.01949C18.2561 9.26936 18.3546 9.50713 18.4951 9.72L19.2728 10.8983C19.7139 11.5665 19.7139 12.4335 19.2728 13.1017L18.4951 14.28C18.3546 14.4929 18.2561 14.7306 18.2049 14.9805L17.9217 16.3636C17.7611 17.148 17.148 17.7611 16.3636 17.9217L14.9805 18.2049C14.7306 18.2561 14.4929 18.3546 14.28 18.4951L13.1017 19.2728C12.4335 19.7139 11.5665 19.7139 10.8983 19.2728L9.72 18.4951C9.50713 18.3546 9.26936 18.2561 9.01949 18.2049L7.63641 17.9217C6.85197 17.7611 6.23895 17.148 6.07831 16.3636L5.79508 14.9805C5.74391 14.7306 5.64542 14.4929 5.50492 14.28L4.72721 13.1017C4.28611 12.4335 4.28611 11.5665 4.72721 10.8983L5.50492 9.72C5.64542 9.50713 5.74391 9.26936 5.79508 9.01949L6.07831 7.63641C6.23895 6.85197 6.85197 6.23895 7.63641 6.07831L9.01949 5.79508C9.26936 5.74391 9.50713 5.64542 9.72 5.50492L10.8983 4.72721Z"
                                            stroke="#1B76FF" stroke-width="2" strokeLinejoin="round" />
                                        <path d="M12 15V13.5V12" stroke="#1B76FF" stroke-width="2"
                                            strokeLinecap="round" strokeLinejoin="round" />
                                        <circle r="1" transform="matrix(1 0 0 -1 12 9)" fill="#1B76FF" />
                                    </svg>
                                </i>
                                <div className="text-item">
                                    <span>You can easily create new policy for different websites.</span>
                                </div>

                            </div>

                        </div>
                        <div className="notify-item">
                            <div className="notify-text">
                                <i>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.8983 4.72721C11.5665 4.28611 12.4335 4.28611 13.1017 4.72721L14.28 5.50492C14.4929 5.64542 14.7306 5.74391 14.9805 5.79508L16.3636 6.07831C17.148 6.23895 17.7611 6.85197 17.9217 7.63641L18.2049 9.01949C18.2561 9.26936 18.3546 9.50713 18.4951 9.72L19.2728 10.8983C19.7139 11.5665 19.7139 12.4335 19.2728 13.1017L18.4951 14.28C18.3546 14.4929 18.2561 14.7306 18.2049 14.9805L17.9217 16.3636C17.7611 17.148 17.148 17.7611 16.3636 17.9217L14.9805 18.2049C14.7306 18.2561 14.4929 18.3546 14.28 18.4951L13.1017 19.2728C12.4335 19.7139 11.5665 19.7139 10.8983 19.2728L9.72 18.4951C9.50713 18.3546 9.26936 18.2561 9.01949 18.2049L7.63641 17.9217C6.85197 17.7611 6.23895 17.148 6.07831 16.3636L5.79508 14.9805C5.74391 14.7306 5.64542 14.4929 5.50492 14.28L4.72721 13.1017C4.28611 12.4335 4.28611 11.5665 4.72721 10.8983L5.50492 9.72C5.64542 9.50713 5.74391 9.26936 5.79508 9.01949L6.07831 7.63641C6.23895 6.85197 6.85197 6.23895 7.63641 6.07831L9.01949 5.79508C9.26936 5.74391 9.50713 5.64542 9.72 5.50492L10.8983 4.72721Z"
                                            stroke="#1B76FF" stroke-width="2" strokeLinejoin="round" />
                                        <path d="M12 15V13.5V12" stroke="#1B76FF" stroke-width="2"
                                            strokeLinecap="round" strokeLinejoin="round" />
                                        <circle r="1" transform="matrix(1 0 0 -1 12 9)" fill="#1B76FF" />
                                    </svg>
                                </i>
                                <div className="text-item">
                                    <span>You can test your tool on sandbox mode.</span>
                                </div>

                            </div>

                        </div>
                        <div className="notify-item">
                            <div className="notify-text">
                                <i>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.8983 4.72721C11.5665 4.28611 12.4335 4.28611 13.1017 4.72721L14.28 5.50492C14.4929 5.64542 14.7306 5.74391 14.9805 5.79508L16.3636 6.07831C17.148 6.23895 17.7611 6.85197 17.9217 7.63641L18.2049 9.01949C18.2561 9.26936 18.3546 9.50713 18.4951 9.72L19.2728 10.8983C19.7139 11.5665 19.7139 12.4335 19.2728 13.1017L18.4951 14.28C18.3546 14.4929 18.2561 14.7306 18.2049 14.9805L17.9217 16.3636C17.7611 17.148 17.148 17.7611 16.3636 17.9217L14.9805 18.2049C14.7306 18.2561 14.4929 18.3546 14.28 18.4951L13.1017 19.2728C12.4335 19.7139 11.5665 19.7139 10.8983 19.2728L9.72 18.4951C9.50713 18.3546 9.26936 18.2561 9.01949 18.2049L7.63641 17.9217C6.85197 17.7611 6.23895 17.148 6.07831 16.3636L5.79508 14.9805C5.74391 14.7306 5.64542 14.4929 5.50492 14.28L4.72721 13.1017C4.28611 12.4335 4.28611 11.5665 4.72721 10.8983L5.50492 9.72C5.64542 9.50713 5.74391 9.26936 5.79508 9.01949L6.07831 7.63641C6.23895 6.85197 6.85197 6.23895 7.63641 6.07831L9.01949 5.79508C9.26936 5.74391 9.50713 5.64542 9.72 5.50492L10.8983 4.72721Z"
                                            stroke="#1B76FF" stroke-width="2" strokeLinejoin="round" />
                                        <path d="M12 15V13.5V12" stroke="#1B76FF" stroke-width="2"
                                            strokeLinecap="round" strokeLinejoin="round" />
                                        <circle r="1" transform="matrix(1 0 0 -1 12 9)" fill="#1B76FF" />
                                    </svg>
                                </i>
                                <div className="text-item">
                                    <span>You can edit design colours from website detail page.</span>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </aside>
        );
    }
}


export default AsideNotifyComponent;
