import React, { Component } from 'react';
import { Field, Form } from "react-final-form";
import {getAllSystemLanguages,createLanguageForDomain} from "../../services/webServices";
import SelectBoxComponent from "../../components/formFields/selectBoxComponent";
import {dataStandardized} from "../../helper/index";
import ErrorComponent from '../layout/errorComponent';
import SucsessComponent from '../layout/sucsessComponent';

const validate = (values) => {
    const errors = {};

    if (!values || !values.lang || values.lang === "") {
        errors.lang = "Dil Seçiniz!";
    }
    return errors;
};
class AddLangueModalComponent extends Component {
    state = {
        isLoading: false,
        success:false,
        error:false,
        lang:[],
    }
    componentDidMount(){
        getAllSystemLanguages().then((result)=>{
            if(result.data.success){
                
               this.setState({
                   lang: result.data.success
               })
            }else{
                this.setState({
                    error:true
                })
            }
        }).catch((err)=>{
            this.setState({
                error:true
            })

        })
       
    }
  

    submit = (values) => {
        this.setState({
            isLoading: true
        })
        createLanguageForDomain({domainId:this.props.domainId,languageId:values.lang}).then((result)=>{
            if(result.data.success){
                this.langAdded();
            }else{
                this.setState({
                    error:true
                })
                this.hideErrorMsg();
            }
        })

    }
    hideErrorMsg = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(()=>{
            this.setState({
                error:false
            })
        },2000)
    }
    langAdded = () =>{
        this.props.addLangClose();
        this.setState({
            success:true
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(()=>{
            this.setState({
                success:false
            })
        },2000)
    }
    render() {
        
        return (
            
            <div className="lightBox-root lang">
            <div className="lightBox-wrapper">
                
                <Form onSubmit={this.submit}
                        validate={validate}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} className="lightBox-container">
                                <div className="head-item">
                                    <h2 className="light-box-title">Add Language</h2>
                                    <div className="close-icon" onClick={() => this.props.addLangClose()}>
                                        <i>
                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </i>
                                    </div>
                                </div>
                                <div className="lang-select-block">
                                   
                                   
                                        <Field
                                            name={"lang"}
                                            type="text"
                                            label={"Language"}
                                            component={SelectBoxComponent}
                                            placeholder={"Dil Seçiniz"}
                                            options={dataStandardized(this.state.lang.filter(item => this.props.langueArr.findIndex(langItem => langItem.language.id == item.id) == -1 ),"id","language_name")}



                                        />
                                  
                                </div>
                                <div className="btn-block">
                                        <button className="btn-item cancel" onClick={() => this.props.addLangClose()}>
                                            <span>Cancel</span>
                                        </button>
                                        <button type="submit" className="btn-item">
                                            <span>Add Language</span>
                                        </button>
                                    </div>
                            </form>
                        )}
                    >
                    </Form>
               
                
            </div>
            <ErrorComponent warn={this.state.error}/>
            <SucsessComponent warn={this.state.success} message={"Language added."} />
        </div>
        );
    }
}

export default AddLangueModalComponent;
