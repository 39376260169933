export const RECEIVE_SIGNED_USER = "RECEIVE_SIGNED_USER";
export const RECEIVE_USER_DOMAINS = "RECEIVE_USER_DOMAINS";
export const RECEIVE_DOMAIN_DETAIL = "RECEIVE_DOMAIN_DETAIL";
export const RECEIVE_SITE_INFO = "RECEIVE_SITE_INFO";
export const CHANGE_COOKIE_CATEGORY = "CHANGE_COOKIE_CATEGORY";
export const CHANGE_DOMAIN_SANDBOX_MODE = "CHANGE_DOMAIN_SANDBOX_MODE";
export const SEARCH_DOMAIN_BY_NAME = "SEARCH_DOMAIN_BY_NAME";
export const RECEIVE_PRE_SITE_INFO = "RECEIVE_PRE_SITE_INFO";
export const UPDATE_DOMAIN_DETAIL = "UPDATE_DOMAIN_DETAIL";
export const RECEIVE_CATEGORY_LIST =`RECEIVE_CATEGORY_LIST`;
export const RECEIVE_COOKIE_LIST = "RECEIVE_COOKIE_LIST";
export const DELETE_COOKIE_LIST = "DELETE_COOKIE_LIST";
export const RECEIVE_UNCATEGORIZED_COOKIE_LIST = "RECEIVE_UNCATEGORIZED_COOKIE_LIST";
export const DELETE_UNCATEGORIZED_COOKIE_LIST = "DELETE_UNCATEGORIZED_COOKIE_LIST";
export const RECEIVE_THIRD_PARTY = "RECEIVE_THIRD_PARTY";
