import React, {Component, Fragment} from "react";
import AddButtonComponent from "../components/buttons/addButtonComponent";
import BreadcrumbComponent from "../components/layout/breadcrumbComponent";
import {ADD_WEB_SITES, WEB_SITES, WEB_SITE_DETAIL_WITHOUT_PARAM, WEB_SITE_SITE_INFO} from "../constants/routePaths";
import {getDomainsAction, searchAction} from "../actions/domainActions";
import {connect} from "react-redux";
import WebsiteDomainItemComponent from "../components/websites/websiteDomainItemComponent";
import AddNewWebsiteComponent from "../components/websites/addNewWebsiteComponent";
import DeleteComponent from './../components/layout/deleteComponent';


class WebsitesPage extends Component {
   constructor(props) {
      super(props);
      props.dispatch(getDomainsAction())
   }
   orderType = "desc"
   orderName = "id"
   state = {
      lightBox: "close",
      searchedText:"",
      isUp:false,
      deleteWarning: false,
   }
   handleToggleClickDelete() {
      this.setState(prevState => ({
          deleteWarning: !prevState.deleteWarning
      }));
      setTimeout(() => {
          this.setState(prevState => ({
              deleteWarning: !prevState.deleteWarning
          }));
      }, 3000)
  }

   componentDidMount(){
      if(this.props.location.pathname === ADD_WEB_SITES){
         this.lightBox("open")
      }
   }
   onSearch = (searchedText) => {
      this.setState({
         searchedText
      })
      // const filteredDomains = this.props.domains.filter(x=>x.domain_name.toLowerCase().includes(searchedText.toLowerCase()))
      const filteredDomains = this.state.searchedText.length>0 ? this.props.domains.filter(x=>x.domain_name.toLowerCase().includes(searchedText.toLowerCase())) : this.props.domains
      const sortedDomains = this.orderType === "asc" ? (filteredDomains.sort((a,b)=>a.domain_name>b.domain_name ? 1:b.domain_name>a.domain_name?-1:0)) : (filteredDomains.sort((a,b)=>a.domain_name>b.domain_name ? 1:b.domain_name>a.domain_name?-1:0).reverse())
      this.props.dispatch(searchAction(sortedDomains))

   }
   order = (_orderName,status) => {
      this.orderName = _orderName
      this.orderType = this.orderType==="desc" ? "asc" : "desc"
      this.setState({
         isUp:this.orderType === "asc"
      })
      if(status){
         // item[status]?1:item[_orderName] ? 2 :0
         let arr=[];
         this.props.filteredDomains.map((item,index)=>{
            if(!item.is_sandbox && item.is_active){
               item.order_id = 2;
               arr.push(item)
            }
            if(item.is_sandbox){
               item.order_id = 1;
               arr.push(item)
            }
            if(!item.is_sandbox && !item.is_active){
               item.order_id = 0;
               arr.push(item)
            }
         })
         arr.sort((a,b)=> this.orderType ==="asc" ? (b.order_id - a.order_id) : (a.order_id - b.order_id))
         this.props.dispatch(searchAction(arr))
         this.forceUpdate()
         return;
      }

      const sortedDomains = this.orderType === "asc" ? (this.props.filteredDomains.sort((a,b)=>a[this.orderName]>b[this.orderName] ? 1:b[this.orderName]>a[this.orderName]?-1:0)) : (this.props.filteredDomains.sort((a,b)=>a[this.orderName]>b[this.orderName] ? 1:b[this.orderName]>a[this.orderName]?-1:0).reverse())
      this.props.dispatch(searchAction(sortedDomains))
      this.forceUpdate()
   }
   lightBox = (lightBox) => {
      this.setState({
         lightBox
      })
   }

   render() {
      let {filteredDomains} = this.props;

      return (
          <div className="content-root websites-root">

             <BreadcrumbComponent breadName="Websites"/>

             <div className={`content-wrapper ${this.state.lightBox === "open" ? "add-new" : ""}`}>
                <div className="table-container">
                   <div className="table-head-block">
                      <div className="row-item">
                         <div className={`row-td name-td  ${this.state.isUp && this.orderName === "domain_name"?"up":""} `} >
                            <span onClick={()=>this.order("domain_name")}>Site Name</span>
                            <div className="search-block">
                               <div className="input-item">
                                  <input type="search" placeholder="Search" onChange={(e)=>this.onSearch(e.target.value)}/>
                                  <i className="icon-item">
                                     <svg width={16} height={16} viewBox="0 0 16 16" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7.5" cy="7.5" r="4.5" stroke="#A4A1C0" stroke-width={2}
                                                strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M11 11L13 13" stroke="#A4A1C0" stroke-width={2} strokeLinecap="round"
                                              strokeLinejoin="round"/>
                                     </svg>
                                  </i>
                               </div>
                            </div>
                         </div>
                         <div className={`row-td domain-td  ${this.state.isUp && this.orderName === "domain"?"up":""} `} >
                            <span onClick={()=>this.order("domain")}>Domain</span>
                         </div>
                         <div className={`row-td number-td  ${this.state.isUp && this.orderName === "cookie_count"?"up":""} `} >
                            <span onClick={()=>this.order("cookie_count")}>Uncategorized Cookie</span>
                         </div>
                         <div className={`row-td status-td  ${this.state.isUp && this.orderName === "is_active"?"up":""} `} >
                            <span onClick={()=>this.order("is_active","is_sandbox")}>Status</span>
                         </div>
                         <div className="row-td button-td" onClick={() => this.lightBox("open")}>
                            <AddButtonComponent buttonName="New Site"/>
                         </div>
                      </div>
                   </div>
                   <div className="table-block">

                      {
                         filteredDomains.length > 0 && filteredDomains.map((item, index, arr) =>
                             <WebsiteDomainItemComponent  {...item} key={index}/>
                         )
                      }
                   </div>
                </div>
                <AddNewWebsiteComponent history={this.props.history} lightBox={this.lightBox}/>
             </div>
             <DeleteComponent warn={this.state.deleteWarning} />
          </div>
      );
   }
}

const mapStateToProps = state => ({
   domains: state.domainReducer.domains || [],
   filteredDomains:state.domainReducer.filteredDomains
})
export default connect(mapStateToProps)(WebsitesPage);
