import React, { Component } from "react";
import AsideComponent from "../layout/asideComponent";
import { WEB_SITES } from "../../constants/routePaths";
import LanguageListContainer from "../../containers/language/languageListContainer";
import LanguageDetailContainer from "../../containers/language/languageDetailContainer";
import {getDomainLanguages} from "../../services/webServices";
import BreadcrumbComponent from "../layout/breadcrumbComponent";
import { connect } from "react-redux";

class WebsiteLanguagesComponent extends Component {

    state = {
        activeLanguageTab: "preferences",
        detailLanguage:"tr",
        langueArr:[],
        selectIndex:1,
        set_language_by_browser:null
    }
 
    componentDidMount(){
        this.getLanguages();
    }

   
    getLanguages = () => {
        getDomainLanguages({domainId:this.props.match.params.id}).then((result)=>{
            if(result.data.success){
                this.setState({
                    langueArr:result.data.success,
                    set_language_by_browser:result.data.status
                })
            }
        })
    }

    changeActiveLanguageTab = (activeLanguageTab,detailLanguage) => {
        this.setState({
            activeLanguageTab,
            detailLanguage:detailLanguage||(this.state.langueArr && this.state.langueArr[0] ? this.state.langueArr[0].language_id:null)
        })
    }

    render() {
        return (
            <div className="content-root website-languages-root">
                 {
                    this.props.siteInfoData &&
                    <BreadcrumbComponent breadName="Websites" breadLink={WEB_SITES} subBreadName={this.props.siteInfoData.domain_name}/>
                }
                <div className="content-wrapper">
                    <div className="content-container">
                        <div className="tab-btn-block">
                            {/* TODO --> active classı seçili olan sayfayı gösterir */}
                            <div className={`tab-btn-item ${this.state.activeLanguageTab==="preferences"?"active":""}`} onClick={()=>{
                                this.changeActiveLanguageTab("preferences")
                            }}>
                                <span>Preferences</span>
                            </div>
                            <div className={`tab-btn-item ${this.state.activeLanguageTab==="translations"?"active":""}`} onClick={()=>{
                                this.changeActiveLanguageTab("translations")
                            }}>
                                <span>Translations</span>

                            </div>
                        </div>
                        <div className={`content-block ${this.state.activeLanguageTab === "translations" ? "translations-tab" : ""}`}>
                            {/* TODO --> active classın olduğu ekran gözükür */}
                            <LanguageListContainer set_language_by_browser={this.state.set_language_by_browser} getLanguages={this.getLanguages} langueArr={this.state.langueArr} changeActiveLanguageTab={this.changeActiveLanguageTab} domainId={this.props.match.params.id}/>
                            {
                                this.state.activeLanguageTab === "translations" ? 
                                <LanguageDetailContainer langueArr={this.state.langueArr} domainId={this.props.match.params.id} detailLanguage={this.state.detailLanguage} selectIndex={this.state.selectIndex}/>
                                :null
                            }
                            
                        </div>
                    </div>
                   <AsideComponent/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    siteInfoData: state.domainReducer.siteInfo&&state.domainReducer.siteInfo[ownProps.match.params.id]?state.domainReducer.siteInfo[ownProps.match.params.id]:null
})

export default connect(mapStateToProps)(WebsiteLanguagesComponent);
