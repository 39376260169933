import React, { Component } from 'react';

class LanguageSelectLbxComponent extends Component {
    state={
        langNo: ""
    }

    componentDidMount(){
        this.setLangNo(this.props.set_language_by_browser);
    }

    setLangNo = (langNo) => {
        this.setState({
            langNo
        })
    }
    render() {
        let { isLangOpen, toggleLang, set_language_by_browser, updateBroweserSelection } = this.props;
        console.log("set_language_by_browser----",set_language_by_browser);
        return (
            <div className={`lightBox-root ${isLangOpen ? "lang" : ""}`}>
                <div className="lightBox-wrapper">
                    <div className="lightBox-container">
                        <div className="head-item">
                            <h2 className='light-box-title'>Language according browser</h2>
                            <div className="close-icon" onClick={toggleLang}>
                                <i>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                </i>
                            </div>
                        </div>
                        <div className="form-block">
                            <span>Default behavior</span>
                            <div className="radio-item">
                                <input type="radio" onChange={() => {this.setLangNo(1)}} value="on" checked={this.state.langNo == 1 ? "checked" : ""} name="select-lang" id="check1" />
                                <label htmlFor="on" className="check1">On</label>
                                <input type="radio" onChange={() => {this.setLangNo(0)}} value="off" checked={this.state.langNo == 0 ? "checked" : ""} name="select-lang" id="check2" />
                                <label htmlFor="off" className="check2">Off</label>
                            </div>
                            <span className='info-text'>When this feature is turned on, the language is changed according to the user's browser settings.</span>
                        </div>
                        <div className="btn-block">
                            <div className="btn-item cancel" onClick={toggleLang}>
                                <span>Cancel</span>
                            </div>
                            <button className="btn-item" onClick={() => updateBroweserSelection(this.state.langNo)}>
                                <span>Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LanguageSelectLbxComponent;