import React, { Component } from 'react';
import AddCookieListFormComponent from '../forms/addCookieListFormComponent';

class AddCookieListComponent extends Component {
    render() {
        return (
            <div className="lightBox-root cookie">
            <div className="lightBox-wrapper">
                <div className="lightBox-container">
                    <AddCookieListFormComponent setNewCookie={this.props.setNewCookie} closeNewCookie={this.props.closeNewCookie} domainId={this.props.domainId} allCategories={this.props.allCategories}/>
     
                </div>
            </div>
        </div>
        );
    }
}

export default AddCookieListComponent;
