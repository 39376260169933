import React, {Component} from "react";
import BreadcrumbComponent from "../components/layout/breadcrumbComponent";




class PlansPage extends Component {

    render() {

       

        return (
            <div className="content-root plans-root">
                
                <BreadcrumbComponent breadName="Plans" />

                <div className="content-wrapper">
                    <div className="content-block">
                        <div className="plan-title-item">
                            <span>Upgrade your plan</span>
                        </div>
                        <div className="plan-block">
                            <div className="plan-item">
                                <div className="plan-text">
                                    <span>Standart</span>
                                    <p className="text-desc-item">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis vel tempus duis sem ornare</p>
                                </div>
                                <div className="plan-price">
                                    <span>29$/mo</span>
                                </div>
                                {/* TODO --> btn-item a active classı geldiğinde kullanıcın mevcut planı gözükür */}
                                <div className="plan-btn">
                                    <span className="btn-item active">Active Plan</span>
                                </div>
                                <div className="plan-desc">
                                    <span>5 Web Sites</span>
                                    <span>Monthly billing on an annual basis</span>
                                </div>

                            </div>
                            <div className="plan-item">
                                <div className="plan-text">
                                    <span>Pro</span>
                                    <p className="text-desc-item">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis vel tempus duis sem ornare</p>
                                </div>
                                <div className="plan-price">
                                    <span>49$/mo</span>
                                </div>
                                <div className="plan-btn">
                                    <span className="btn-item ">Upgrade to this plan</span>
                                </div>
                                <div className="plan-desc">
                                    <span>10 Web Sites</span>
                                    <span>Monthly billing on an annual basis</span>
                                </div>

                            </div>
                            <div className="plan-item">
                                <div className="plan-text">
                                    <span>Organization</span>
                                    <p className="text-desc-item">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis vel tempus duis sem ornare</p>
                                </div>
                                <div className="plan-price">
                                    <span>79$/mo</span>
                                </div>
                                <div className="plan-btn">
                                    <span className="btn-item ">Upgrade to this plan</span>
                                </div>
                                <div className="plan-desc">
                                    <span>25 Web Sites</span>
                                    <span>Monthly billing on an annual basis</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PlansPage;
