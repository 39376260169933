import React, { Component, Fragment } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import {
    LOGIN_PAGE,
    REGISTER_INVITATION,
    RESET_PASSWORD,
    RESET_PASSWORD2,
    RESET_PASSWORD3,
    DASHBOARD_PAGE,
    WEB_SITES,
    WEB_SITE_DETAIL,
    POLICIES_PAGE,
    SDK_PAGE,
    REPORTS_PAGE,
    POLICIES_ADD,
    USERS_PAGE,
    PLANS_PAGE,
    WEB_SITE_DESIGN,
    WEB_SITE_LANGUAGES,
    WEB_SITE_POLICY_AUDIENCE,
    WEB_SITE_COOKIE_LIST,
    WEB_SITE_SITE_INFO,
    WEB_SITE_INTEGRATION,
    RESET_PASSWORD4,

    WEB_SITE_CATEGORIES,

    COMPANY_LIST,
    SUPER_POLICIES,
    POLICIES_EDIT, ADD_WEB_SITES, ADD_USERS_PAGE, STATISTICS_PAGE, WEB_SITE_3RD_PARTIES
} from '../constants/routePaths';
import { connect } from "react-redux";
import { activeUserAction } from "../actions/index";
import WebsitesPage from '../pages/websitesPage';
import PlansPage from '../pages/plansPage';
import UserPage from '../pages/userPage';
import SdkPage from '../pages/sdkPage';
import faz2loginPage from '../pages/faz2loginPage';
import Faz2registerPage from '../pages/faz2registerPage'
import faz2resetPassword from '../pages/faz2resetPassword'

import Faz2HeaderComponent from './layout/faz2HeaderComponent';
import NavWebsiteDetailComponent from './nav/navWebsiteDetailComponent';
import NavComponent from './nav/navComponent';
import Faz2DashboardPage from "../pages/faz2DashboardPage";
import WebsiteDetailsComponent from "./websites/websiteDetailsComponent";
import WebsiteCookieListComponent from "./websites/websiteCookieListComponent";
import WebsitePolicyComponent from "./websites/websitePolicyComponent";
import WebsiteLanguagesComponent from "./websites/websiteLanguagesComponent";
import WebsiteDesignComponent from "./websites/websiteDesignComponent";
import WebsiteIntegrationComponent from "./websites/websiteIntegrationComponent";

import PoliciesPage from '../pages/policiesPage';
import Faz2registerInvitation from "../pages/faz2registerInvitation";
import faz2resetpassword2 from "../pages/faz2resetpassword2";
import faz2resetpassword3 from "../pages/faz2resetpassword3";
import faz2resetpassword4 from "../pages/faz2resetpassword4";
import PoliciyAddComponent from './policies/policiyAddComponent';
import WebsiteCategoriesComponent from './websites/websiteCategoriesComponent';
import NavSuperUserComponent from './nav/navSuperUserComponent';
import CompanyListComponent from './superAdmin/companyListComponent';
import SuperUserHeaderComponent from './layout/superUserHeaderComponent';
import SuperUserPoliciesComponent from './superAdmin/superUserPoliciesComponent';
import PoliciyEditComponent from './policies/policiyEditComponent';
import websiteStatisticComponent from './websites/websiteStatisticComponent';
import Website3rdPartiesComponent from './websites/website3rdPartiesComponent';

class RoutesComponent extends Component {

    componentDidMount() {
        this.props.dispatch(activeUserAction())
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.props.dispatch(activeUserAction())
        }
    }

    render() {
        let { user, superUser } = this.props;
        return (
            <div>
                {
                    user ?

                        <div>
                            {
                                !superUser ?

                                    <Fragment>
                                        <Faz2HeaderComponent />
                                        <div className="main-root">
                                            <Switch>
                                                {/*<Route exact path={WEB_SITE_DETAIL} component={NavWebsiteDetailComponent} />*/}
                                                <Route path={WEB_SITE_DETAIL} component={NavWebsiteDetailComponent} />
                                                <Route component={NavComponent} />
                                            </Switch>
                                            <div className="main-wrapper">
                                                <Switch>
                                                    <Route exact path={DASHBOARD_PAGE} component={Faz2DashboardPage} />
                                                    <Route exact path={WEB_SITES} component={WebsitesPage} />
                                                    <Route exact path={ADD_WEB_SITES} component={WebsitesPage} />
                                                    <Route exact path={PLANS_PAGE} component={PlansPage} />
                                                    {
                                                        user.role_id == 1 &&
                                                        <Route exact path={USERS_PAGE} component={UserPage} />
                                                    }
                                                    {
                                                        user.role_id == 1 &&
                                                        <Route exact path={ADD_USERS_PAGE} component={UserPage} />
                                                    }
                                                    <Route exact path={SDK_PAGE} component={SdkPage} />

                                                    <Route exact path={POLICIES_PAGE} component={PoliciesPage} />
                                                    <Route exact path={POLICIES_ADD} component={PoliciyAddComponent} />
                                                    <Route exact path={POLICIES_EDIT} component={PoliciyEditComponent} />

                                                    <Route exact path={WEB_SITE_SITE_INFO}
                                                        component={WebsiteDetailsComponent} />
                                                    <Route exact path={WEB_SITE_COOKIE_LIST}
                                                        component={WebsiteCookieListComponent} />
                                                    <Route exact path={WEB_SITE_POLICY_AUDIENCE}
                                                        component={WebsitePolicyComponent} />
                                                    <Route exact path={WEB_SITE_LANGUAGES}
                                                        component={WebsiteLanguagesComponent} />
                                                    <Route exact path={WEB_SITE_DESIGN}
                                                        component={WebsiteDesignComponent} />
                                                    <Route exact path={WEB_SITE_INTEGRATION}
                                                        component={WebsiteIntegrationComponent} />
                                                    <Route exact path={WEB_SITE_CATEGORIES}
                                                        component={WebsiteCategoriesComponent} />
                                                    {/* <Route exact path={STATISTICS_PAGE} 
                                                        component={websiteStatisticComponent}/> */}
                                                              <Route exact path={WEB_SITE_3RD_PARTIES} 
                                                        component={Website3rdPartiesComponent}/>
                                                </Switch>
                                            </div>
                                        </div>
                                    </Fragment>

                                    :

                                    <Fragment>
                                        <SuperUserHeaderComponent />
                                        <div className="main-root">
                                            <Route component={NavSuperUserComponent} />
                                            <div className="main-wrapper">
                                                <Switch>
                                                    <Route exact path={COMPANY_LIST} component={CompanyListComponent} />
                                                    <Route exact path={SUPER_POLICIES} component={SuperUserPoliciesComponent} />
                                                </Switch>
                                            </div>
                                        </div>
                                    </Fragment>
                            }
                        </div>

                        :

                        <div>
                            <Route exact path="/"> <Redirect to={LOGIN_PAGE} /> </Route>

                            <Switch>
                                <Route exact path={LOGIN_PAGE} component={faz2loginPage} />
                                {/* <Route exact path={REGISTER_PAGE} component={Faz2registerPage}/> */}
                                <Route exact path={REGISTER_INVITATION} component={Faz2registerInvitation} />
                                <Route exact path={RESET_PASSWORD} component={faz2resetPassword} />
                                <Route exact path={RESET_PASSWORD2} component={faz2resetpassword2} />
                                <Route exact path={RESET_PASSWORD3} component={faz2resetpassword3} />
                                <Route exact path={RESET_PASSWORD4} component={faz2resetpassword4} />
                            </Switch>
                        </div>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user
})

export default withRouter(connect(mapStateToProps)(RoutesComponent));
