
import React, {Component, Fragment} from "react";
import { NavLink } from "react-router-dom";
import {
    
    SUPER_POLICIES,
    COMPANY_LIST
    
} from "../../constants/routePaths";

class NavSuperUserComponent extends Component {
    render() {
        return (
            <Fragment>
            <nav className="nav-block">
                <ul>
                    <li>
                        <NavLink exact to={COMPANY_LIST} className={`nav-item`}>
                         
                            Company List
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={SUPER_POLICIES} className="nav-item">
                    
                            Policies
                        </NavLink>
                    </li>
              
                </ul>
            </nav>
            </Fragment>
        )
    }
}

export default NavSuperUserComponent;
