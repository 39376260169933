import React, { Component } from 'react';
import { Field } from 'react-final-form'
import TextAreaInputComponent from '../formFields/textAreaInputComponent';

class CategoriesLanguageComponent extends Component {
    state = {
        onlyMissingTransation: false
    }
    toggleOnlyMissing = () => {
        this.setState({
            onlyMissingTransation: !this.state.onlyMissingTransation
        })
    }
    render() {
        let { selectedLanguage, categories, langueArr } = this.props;
        let analytics = categories.find(categoryItem => categoryItem.category_id == 1);
        let required = categories.find(categoryItem => categoryItem.category_id == 2);
        let unknown = categories.find(categoryItem => categoryItem.category_id == 3);
        let social = categories.find(categoryItem => categoryItem.category_id == 4);
        let adds = categories.find(categoryItem => categoryItem.category_id == 5);
        return (
            <>
                <div className="lang-table-wrapper categries">
                    <div className="lang-head-container">
                        <div className="check-box">
                            <input type="checkbox" onChange={this.toggleOnlyMissing} checked={this.state.onlyMissingTransation ? "checked" : ""}/>
                            <label htmlFor="translations">Only missing translations</label>
                        </div>
                    </div>
                    <div className="description-table-block">
                        <div className="description-table-head-item">
                            <div className="row-th name-th">
                                <span>Element name</span>
                                <i className="icon-item">
                                    <svg width={16} height={16} viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="7.5" cy="7.5" r="4.5" stroke="#A4A1C0"
                                            stroke-width={2} strokeLinecap="round"
                                            strokeLinejoin="round" />
                                        <path d="M11 11L13 13" stroke="#A4A1C0" stroke-width={2}
                                            strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </i>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&
                                    <div className="row-th lang-th input-item" key={key}>
                                        {/* <input type="text" placeholder="Turkish" /> */}
                                        <span>{languageItem.language.language_name} {languageItem.is_default ? "(Default)" : ""}</span>
                                    </div>
                                )
                            }
                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !analytics || !analytics.lang_title || !JSON.parse(analytics.lang_title)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Analytics(Category title)</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field
                                                    name={`categories."1".lang_title.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={analytics && analytics.lang_title ? JSON.parse(analytics.lang_title)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{analytics && analytics.lang_title ? JSON.parse(analytics.lang_title)[languageItem.language.language_code] : null}</span>
                                        }
                                    </div>

                                )
                            }
                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !analytics || !analytics.lang_description || !JSON.parse(analytics.lang_description)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Analytics(Category description)</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field
                                                    name={`categories."1".lang_description.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={analytics && analytics.lang_description ? JSON.parse(analytics.lang_description)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{analytics && analytics.lang_description ? JSON.parse(analytics.lang_description)[languageItem.language.language_code] : null}</span>
                                        }
                                    </div>

                                )
                            }
                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !required || !required.lang_title || !JSON.parse(required.lang_title)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Required(Category title)</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field
                                                    name={`categories."2".lang_title.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={required && required.lang_title ? JSON.parse(required.lang_title)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{required && required.lang_title ? JSON.parse(required.lang_title)[languageItem.language.language_code] : null}</span>
                                        }
                                    </div>

                                )
                            }
                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !required || !required.lang_description || !JSON.parse(required.lang_description)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Required(Category description)</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field
                                                    name={`categories."2".lang_description.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={required && required.lang_description ? JSON.parse(required.lang_description)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{required && required.lang_description ? JSON.parse(required.lang_description)[languageItem.language.language_code] : null}</span>
                                        }
                                    </div>

                                )
                            }
                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !social || !social.lang_title || !JSON.parse(social.lang_title)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Social(Category title)</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field
                                                    name={`categories."4".lang_title.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={social && social.lang_title ? JSON.parse(social.lang_title)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{social && social.lang_title ? JSON.parse(social.lang_title)[languageItem.language.language_code] : null}</span>
                                        }
                                    </div>

                                )
                            }
                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !social || !social.lang_description || !JSON.parse(social.lang_description)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Social(Category description)</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field
                                                    name={`categories."4".lang_description.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={social && social.lang_description ? JSON.parse(social.lang_description)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{social && social.lang_description ? JSON.parse(social.lang_description)[languageItem.language.language_code] : null}</span>
                                        }
                                    </div>

                                )
                            }
                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !adds || !adds.lang_title || !JSON.parse(adds.lang_title)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Adds(Category title)</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field
                                                    name={`categories."5".lang_title.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={adds && adds.lang_title ? JSON.parse(adds.lang_title)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{adds && adds.lang_title ? JSON.parse(adds.lang_title)[languageItem.language.language_code] : null}</span>
                                        }
                                    </div>

                                )
                            }
                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !adds || !adds.lang_description || !JSON.parse(adds.lang_description)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Adds(Category description)</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                (selectedLanguage.language.id === languageItem.language.id || languageItem.is_default) &&
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field
                                                    name={`categories."5".lang_description.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={adds && adds.lang_description ? JSON.parse(adds.lang_description)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{adds && adds.lang_description ? JSON.parse(adds.lang_description)[languageItem.language.language_code] : null}</span>
                                        }
                                    </div>

                                )
                            }
                        </div>
                        {/* <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !unknown || !unknown.lang_title || !JSON.parse(unknown.lang_title)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Unknown(Category title)</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field
                                                    name={`categories."3".lang_title.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={unknown && unknown.lang_title ? JSON.parse(unknown.lang_title)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{unknown && unknown.lang_title ? JSON.parse(unknown.lang_title)[languageItem.language.language_code] : null}</span>
                                        }
                                    </div>

                                )
                            }
                        </div>
                        <div className="description-table-td-item" style={{display:(this.state.onlyMissingTransation===false || !unknown || !unknown.lang_description || !JSON.parse(unknown.lang_description)[selectedLanguage.language.language_code])?"flex":"none"}}>
                            <div className="row-td  name-td">
                                <span>Unknown(Category description)</span>
                            </div>
                            {
                                langueArr.map((languageItem, key) =>
                                    <div key={key} className="row-td  lang-td input-item">
                                        {
                                            selectedLanguage.language.id === languageItem.language.id ?
                                                <Field
                                                    name={`categories."3".lang_description.${languageItem.language.language_code}`}
                                                    type="text"
                                                    defaultValue={unknown && unknown.lang_description ? JSON.parse(unknown.lang_description)[languageItem.language.language_code] : null}
                                                    component={TextAreaInputComponent}
                                                />
                                                :
                                                <span>{unknown && unknown.lang_description ? JSON.parse(unknown.lang_description)[languageItem.language.language_code] : null}</span>
                                        }
                                    </div>

                                )
                            }
                        </div> */}

                    </div>


                </div>
            </>
        )
    }
};

export default CategoriesLanguageComponent;