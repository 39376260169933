import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { getSitePreInfoAction } from "../../actions/domainActions";
import {
    DASHBOARD_PAGE,
    WEB_SITE_COOKIE_LIST, WEB_SITE_DESIGN,
    WEB_SITE_DETAIL, WEB_SITE_INTEGRATION, WEB_SITE_LANGUAGES,
    WEB_SITE_POLICY_AUDIENCE, WEB_SITE_SITE_INFO, WEB_SITE_CATEGORIES,
    WEB_SITES,
    WEB_SITE_STATISTICS,
    WEB_SITE_3RD_PARTIES
} from "../../constants/routePaths";

class NavWebsiteDetailComponent extends Component {
    constructor(props) {
        super(props);
        this.props.dispatch(getSitePreInfoAction(this.props.match.params.id))
    }
    render() {
        let { presiteInfo } = this.props;
        return (
            <nav className="nav-block nav-web-details-root">
                <Link to={WEB_SITES} className="nav-item back-to" >
                    <i>
                        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 5L4 8L7 11" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4 8H12" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </i>
                    Back to websites
                </Link>
                <ul>
                    <li>
                        <NavLink exact to={WEB_SITE_SITE_INFO.replace(":id", this.props.match.params.id)} className={`nav-item`} >
                            <i>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 12C5 8.13401 8.13401 5 12 5V5C15.866 5 19 8.13401 19 12V12C19 15.866 15.866 19 12 19V19C8.13401 19 5 15.866 5 12V12Z" stroke="#716D92" stroke-width={2} />
                                    <path d="M12 11V16" stroke="#716D92" stroke-width={2} />
                                    <circle cx={12} cy={9} r={1} fill="#716D92" />
                                </svg>
                            </i>
                            Site Info
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to={WEB_SITE_CATEGORIES.replace(":id", this.props.match.params.id)} className={`nav-item`}>
                            <i>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="5" y="5" width="5" height="5" stroke="#716D92" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <rect x="14" y="5" width="5" height="5" stroke="#716D92" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <rect x="14" y="14" width="5" height="5" stroke="#716D92" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <rect x="5" y="14" width="5" height="5" stroke="#716D92" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </i>
                            Categories

                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to={WEB_SITE_COOKIE_LIST.replace(":id", this.props.match.params.id)} className={`nav-item`}>
                            <i>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.8991 11.8985L17.8823 11.7161C17.8326 11.4481 17.6757 11.2121 17.4478 11.0625C17.2199 10.913 16.9409 10.8629 16.6753 10.9239L16.8991 11.8985ZM12.1015 7.10088L13.0761 7.32474C13.1371 7.0591 13.087 6.78007 12.9375 6.5522C12.7879 6.32433 12.5519 6.16738 12.2839 6.11766L12.1015 7.10088ZM16.6753 10.9239C16.4592 10.9736 16.2333 11 16 11V13C16.385 13 16.7611 12.9563 17.123 12.8732L16.6753 10.9239ZM16 11C14.3431 11 13 9.65685 13 8H11C11 10.7614 13.2386 13 16 13V11ZM13 8C13 7.76671 13.0264 7.54083 13.0761 7.32474L11.1268 6.87702C11.0437 7.23894 11 7.61499 11 8H13ZM11 8C11.3148 8 11.6218 8.02896 11.919 8.0841L12.2839 6.11766C11.867 6.0403 11.4377 6 11 6V8ZM6 13C6 10.2386 8.23858 8 11 8V6C7.13401 6 4 9.13401 4 13H6ZM11 18C8.23858 18 6 15.7614 6 13H4C4 16.866 7.13401 20 11 20V18ZM16 13C16 15.7614 13.7614 18 11 18V20C14.866 20 18 16.866 18 13H16ZM15.9159 12.081C15.971 12.3782 16 12.6852 16 13H18C18 12.5623 17.9597 12.133 17.8823 11.7161L15.9159 12.081Z" fill="#716D92" />
                                    <circle cx="15.5" cy="6.5" r="1.5" fill="#716D92" />
                                    <circle cx={19} cy={10} r={1} fill="#716D92" />
                                    <circle cx={12} cy={14} r={1} fill="#716D92" />
                                    <circle cx={7} cy={12} r={2} fill="#716D92" />
                                    <circle cx="11.5" cy="17.5" r="1.5" fill="#716D92" />
                                </svg>
                            </i>
                            Cookie List
                            {
                                presiteInfo && <span>{presiteInfo.unCategorizedCookieCount}</span>
                            }
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to={WEB_SITE_POLICY_AUDIENCE.replace(":id", this.props.match.params.id)} className={`nav-item`}>
                            <i>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 16V16C15 14.3431 13.6569 13 12 13V13V13C10.3431 13 9 14.3431 9 16V16" stroke="#716D92" stroke-width={2} strokeLinecap="round" />
                                    <circle cx={12} cy={10} r={2} stroke="#716D92" stroke-width={2} strokeLinecap="round" />
                                    <circle cx={12} cy={12} r={7} stroke="#716D92" stroke-width={2} strokeLinecap="round" />
                                    <path d="M12 3V5" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 19V21" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M19 12H21" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M3 12H5" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </i>
                            Policy Audience
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to={WEB_SITE_LANGUAGES.replace(":id", this.props.match.params.id)} className={`nav-item`}>
                            <i>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 8C4 6.34315 5.34315 5 7 5H17C18.6569 5 20 6.34315 20 8V12C20 13.6569 18.6569 15 17 15H14L10 19V15H7C5.34315 15 4 13.6569 4 12V8Z" stroke="#716D92" stroke-width={2} strokeLinejoin="round" />
                                    <path d="M8 9L8 11" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 9L12 11" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16 9L16 11" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </i>
                            Languages
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to={WEB_SITE_DESIGN.replace(":id", this.props.match.params.id)} className={`nav-item`}>
                            <i>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.5 4.44338L18.2942 7.78867C18.6036 7.96731 18.7942 8.29743 18.7942 8.6547V15.3453C18.7942 15.7026 18.6036 16.0327 18.2942 16.2113L12.5 19.5566C12.1906 19.7353 11.8094 19.7353 11.5 19.5566L5.70577 16.2113C5.39637 16.0327 5.20577 15.7026 5.20577 15.3453V8.6547C5.20577 8.29743 5.39637 7.96731 5.70577 7.78868L11.5 4.44338C11.8094 4.26474 12.1906 4.26474 12.5 4.44338Z" stroke="#716D92" stroke-width={2} />
                                    <path d="M12 12L18.5 8" stroke="#716D92" stroke-width={2} />
                                    <path d="M5.5 8L12 12" stroke="#716D92" stroke-width={2} />
                                    <path d="M12 12V19.5" stroke="#716D92" stroke-width={2} />
                                </svg>
                            </i>
                            Design
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to={WEB_SITE_INTEGRATION.replace(":id", this.props.match.params.id)} className={`nav-item`}>
                            <i>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 7C8 8.10457 7.10457 9 6 9C4.89543 9 4 8.10457 4 7C4 5.89543 4.89543 5 6 5C7.10457 5 8 5.89543 8 7Z" fill="#716D92" />
                                    <path d="M20 17C20 18.1046 19.1046 19 18 19C16.8954 19 16 18.1046 16 17C16 15.8954 16.8954 15 18 15C19.1046 15 20 15.8954 20 17Z" fill="#716D92" />
                                    <path d="M12 17H9C7.34315 17 6 15.6569 6 14V7" stroke="#716D92" stroke-width={2} strokeLinecap="round" />
                                    <path d="M12 7L15 7C16.6569 7 18 8.34315 18 10L18 17" stroke="#716D92" stroke-width={2} strokeLinecap="round" />
                                    <path d="M12.4645 17.7478L12.4645 16.2522L13.3992 17L12.4645 17.7478Z" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.5355 6.2522L11.5355 7.7478L10.6008 7L11.5355 6.2522Z" stroke="#716D92" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </i>
                            Integration
                        </NavLink>
                    </li>
              
                    <li>
                        <NavLink exact to={WEB_SITE_3RD_PARTIES.replace(":id", this.props.match.params.id)} className={`nav-item`}>
                            <i>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 16C19 12.6863 16.0899 10 12.5 10C8.91015 10 6 12.6863 6 16" stroke="#3430FF" stroke-width="2"/>
<circle cx="12" cy="9" r="2" fill="white" stroke="#3430FF" stroke-width="2"/>
<circle cx="19" cy="15" r="2" fill="white" stroke="#3430FF" stroke-width="2"/>
<circle cx="6" cy="15" r="2" fill="white" stroke="#3430FF" stroke-width="2"/>
</svg>


                            </i>
                            3rd Parties
                        </NavLink>
                    </li>

                </ul>
            </nav>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    presiteInfo: state.domainReducer.preSiteInfo ? state.domainReducer.preSiteInfo[ownProps.match.params.id] : null
})

export default connect(mapStateToProps)(NavWebsiteDetailComponent);
