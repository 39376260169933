import React, {Component} from "react";
import {ASSETS} from "../constants/Paths-prod";
import {Field, Form} from "react-final-form";
import {required} from "../helper";
import TextInputComponent from "../components/formFields/textInputComponent";
import RecaptchaFieldComponent from "../components/formFields/recaptchaFieldComponent";
import {NavLink} from "react-router-dom";
import {LOGIN_PAGE} from "../constants/routePaths";
import SlideComponent from "../components/slideComponent";
import CoverComponent from "../components/common/coverComponent";
import LogoComponent from "../components/common/logoComponent";


class faz2resetpassword4 extends Component {

    render() {

        return (
            <div className="login-root reset-pass4-root">

                <div className="login-wrapper">
                    <div className="login-left-wrapper">
                        <LogoComponent/>
                        <div className="login-content-container">
                            <div className="login-container">
                                <div className="title-block">
                                    <div className="title-item">
                                        <h3>Password Reset</h3>
                                    </div>
                                </div>
                                <div className="click-block">
                                    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="64" cy="64" r="64" fill="#E1FCDE"/>
                                        <circle cx="64" cy="64" r="32" stroke="#2ACF1C" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M52 68L60 76L80 56" stroke="#2ACF1C" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>

                                    <p>Password Succesfully Changed</p>

                                </div>
                                <div className="btn-block">
                                    <div className="btn-item">
                                        <span>Go to Login</span>
                                    </div>
                                </div>
                                <div className="bottom-btn-block">
                                    <div className="text-item">
                                        <span>I have an account </span>
                                    </div>
                                    <NavLink exact to={LOGIN_PAGE} className={`btn-item white-btn`}>
                                        <span>Login</span>
                                    </NavLink>

                                </div>


                            </div>
                        </div>
                    </div>

                    <CoverComponent/>
                </div>

            </div>



        );
    }
}

export default faz2resetpassword4;
