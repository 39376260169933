import React, {Component} from "react";
import {ASSETS} from "../constants/Paths-prod";
import {Field, Form} from "react-final-form";
import {required} from "../helper";
import TextInputComponent from "../components/formFields/textInputComponent";
import RecaptchaFieldComponent from "../components/formFields/recaptchaFieldComponent";
import {NavLink} from "react-router-dom";
import {LOGIN_PAGE} from "../constants/routePaths";
import SlideComponent from "../components/slideComponent";
import RegisterFormComponent from "../components/forms/registerFormComponent";
import RegisterinvFormComponent from "../components/forms/registerinvFormComponent";
import CoverComponent from "../components/common/coverComponent";
import LogoComponent from "../components/common/logoComponent";
import {getRegisterUser} from "../services/webServices";

class Faz2registerInvitation extends Component {
    state={
        registerMail:""
    }


    componentDidMount(){
        getRegisterUser({user_id:this.props.match.params.userId,token:this.props.match.params.token}).then((result)=>{
            if(result.data.success){    
                this.setState({
                    registerMail : result.data.success
                })

            }
        })
        

   }
    render() {

        return (
            <div className="login-root register-inv-root">

                <div className="login-wrapper">
                    <div className="login-left-wrapper">
                        <LogoComponent/>
                        <div className="login-content-container">
                            <div className="login-container">
                                <div className="title-block">
                                    <div className="title-item">
                                        <h3>Register by invitation</h3>
                                    </div>
                                </div>
                                <div className="text-block">
                                    <span>{this.state.registerMail}</span>
                                    <p>You are registering with your e-mail address.</p>
                                </div>
                                <RegisterinvFormComponent/>

                                <div className="bottom-btn-block">
                                    <div className="text-item">
                                        <span>I have an account</span>
                                    </div>
                                    <NavLink exact to={LOGIN_PAGE} className={`btn-item white-btn`}>
                                        <span>Login</span>
                                    </NavLink>

                                </div>


                            </div>
                        </div>
                    </div>

                    <CoverComponent/>
                </div>

            </div>



        );
    }
}

export default Faz2registerInvitation;
