import{combineReducers} from 'redux';
import React from 'react';
import userReducer from "./userReducer";
import domainReducer from "./domainReducer"
import CategoryReducer from './categoryReducer';
import thirdPartyReducer from './thirdPartyReducer';

const combineRed = combineReducers({
    userReducer,
    domainReducer,
    CategoryReducer,
    thirdPartyReducer
});

export default combineRed