import React, { Component } from 'react';
import AddButtonComponent from "../buttons/addButtonComponent";
import CookieItemComponent from './cookieItemComponent';
import { getDomainsAction, searchAction } from "../../actions/domainActions";
import { connect } from 'react-redux';
import AddCookieListComponent from '../modal/addCookieListComponent';
import SucsessComponent from '../layout/sucsessComponent';

class CookieListComponent extends Component {
    state = {
        isUp: false,
        addCookie: false,
        newCookieAdded: false,
        sorted: false,
        control: false,
        IsSuccess: false,
    }
    orderType = "desc"
    orderName = "id"
    success = () => {
   
        this.setState({
            IsSuccess: true,
          

        });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
           this.setState({
            IsSuccess: false
           })
        }, 2000)

     }
    setNewCookie = () => {
        this.setState({ newCookieAdded: true })
    }

    openNewCookie = () => {
        this.setState({
            addCookie: true
        })
        document.body.classList.add("no-scroll");
    }

    closeNewCookie = () => {
        this.setState({
            addCookie: false
        })
        document.body.classList.remove("no-scroll");
        this.props.getDomainCookies();
    }

    updateDomains = () => {
        this.props.getDomainCookies();
    }
    onSearch = (searchedText) => {
        this.setState({
            searchedText
        })
        // const filteredDomains = this.props.domains.filter(x=>x.domain_name.toLowerCase().includes(searchedText.toLowerCase()))
        const filteredDomains = this.state.searchedText.length > 0 ? this.props.domains.filter(x => x.domain_name.toLowerCase().includes(searchedText.toLowerCase())) : this.props.domains
        const sortedDomains = this.orderType === "asc" ? (filteredDomains.sort((a, b) => a.domain_name > b.domain_name ? 1 : b.domain_name > a.domain_name ? -1 : 0)) : (filteredDomains.sort((a, b) => a.domain_name > b.domain_name ? 1 : b.domain_name > a.domain_name ? -1 : 0).reverse())
        this.props.dispatch(searchAction(sortedDomains))

    }
    sorted = (item) => {
        this.setState({
            type: item,
            sorted: this.state.type===item? !this.state.sorted:false
        })
    }
    sortCookies = (a, b) => {
        if (this.state.type === "name") {
            return this.state.sorted === false ? (a.cookie.cookie_name > b.cookie.cookie_name ? 1 : b.cookie.cookie_name > a.cookie.cookie_name ? -1 : 0) : (a.cookie.cookie_name > b.cookie.cookie_name ? -1 : b.cookie.cookie_name > a.cookie.cookie_name ? 1 : 0)
        }
        if (this.state.type === "source") {
            return this.state.sorted === false ? (a.source > b.source ? 1 : b.source > a.source ? -1 : 0) : (a.source > b.source ? -1 : b.source > a.source ? 1 : 0)
        }
        if (this.state.type === "life") {
            return this.state.sorted === false ? (a.lifespan_id > b.lifespan_id ? 1 : b.lifespan_id > a.lifespan_id ? -1 : 0) : (a.lifespan_id > b.lifespan_id ? -1 : b.lifespan_id > a.lifespan_id ? 1 : 0)
        }
        if (this.state.type === "hostname") {
            return this.state.sorted === false ? (a.hostname > b.hostname ? 1 : b.hostname > a.hostname ? -1 : 0) : (a.hostname > b.hostname ? -1 : b.hostname > a.hostname ? 1 : 0)
        }
        if (this.state.type === "category") {
            return this.state.sorted === false ? (a.cookie.cookie_category.category_name > b.cookie.cookie_category.category_name ? 1 : b.cookie.cookie_category.category_name > a.cookie.cookie_category.category_name ? -1 : 0) : (a.cookie.cookie_category.category_name > b.cookie.cookie_category.category_name ? -1 : b.cookie.cookie_category.category_name > a.cookie.cookie_category.category_name ? 1 : 0)
        }
    }
    render() {
        let { cookies, domainId, allCategories } = this.props;
        console.log("cookies----",cookies);
        return (
            <div className="table-container">
                <div className="table-head-block">
                    <div className="row-item">
                        <div className={`row-td name-td ${this.state.type === "name" && this.state.sorted === true ? "up" : ""}`}>
                            <span onClick={() => { this.sorted("name"); }}>Cookie Name</span>

                        </div>
                        <div className={`row-td source-td ${this.state.sorted === true && this.state.type === "source" ? "up" : ""}`}>
                            <span onClick={() => this.sorted("source")}>Source</span>

                        </div>
                        <div className={`row-td life-td ${this.state.sorted === true && this.state.type === "life" ? "up" : ""}`}>
                            <span onClick={() => this.sorted("life")}>Lifespan</span>
                        </div>
                        <div className={`row-td hostname-td ${this.state.sorted === true && this.state.type === "hostname" ? "up" : ""}`} >
                            <span onClick={() => this.sorted("hostname")}>Hostname</span>
                        </div>
                        <div className={`row-td category-td ${this.state.sorted === true && this.state.type === "category" ? "up" : ""}`}>
                            <span onClick={() => this.sorted("category")}>Category</span>
                        </div>
                        <div className="row-td descri-td">
                            <span className={this.state.isUp && this.orderName === "descri"?"up":""} onClick={()=>this.order("descri")}>Description</span>
                        </div>
                        <div className="row-td button-td" onClick={() => { this.openNewCookie() }}>
                            <AddButtonComponent buttonName="New Cookie" />
                        </div>
                    </div>
                </div>
                <div className="table-block cookie">
                    <div>
                        {
                          cookies&&  cookies.sort(this.sortCookies).map((cookieItem, key) =>
                                <CookieItemComponent  activeWindow={this.props.activeWindow} success={this.success} newCookieAdded={this.state.newCookieAdded} getDomainCookies={this.props.getDomainCookies} allCategories={allCategories} domainId={domainId} {...cookieItem} key={cookieItem.id} />

                            )
                        }
                    </div>
                </div>
                {
                    this.state.addCookie ?
                        <AddCookieListComponent getDomainCookies={this.props.getDomainCookies} closeNewCookie={this.closeNewCookie} domainId={domainId} allCategories={allCategories} setNewCookie={this.setNewCookie} />
                        :
                        <div />
                }
                            <SucsessComponent className={"fixed-pos"} warn={this.state.IsSuccess} message={"Cookie Silindi"} />

            </div>

        )
    }
};
const mapStateToProps = (state, ownProps) => ({
    domains: state.domainReducer.domains || [],
    filteredDomains: state.domainReducer.filteredDomains,
  


})

export default connect(mapStateToProps)(CookieListComponent)