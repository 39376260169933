import React, { Component } from 'react';
import { deleteDomainPolicy } from '../../services/webServices';

class PolicyDeleteAudienceLbxComponent extends Component {

    deleteDomain = (key) => {
        let domain = this.props.domainArr[key];
        deleteDomainPolicy(domain.id,domain.domain_id,domain.policy_id).then((res)=> {
            if(res.data.success){
                this.props.getPolicies();
                this.props.toggleDelete();
            }
        }).catch((err)=> console.log("ERR--", err))
    }
    

    render() {
        let {toggleDelete, deleteKey} = this.props;
        return (
            <div className="lightBox-root delete">
                <div className="lightBox-wrapper">
                    <div className="lightBox-container ">
                        <div className="head-item">
                            <h2 className="light-box-title">Delete Audience</h2>
                            <div className="close-icon" onClick={toggleDelete}>
                                <i>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </i>
                            </div>
                        </div>
                        <div className="text-block">
                            <p>Do you want to delete the policy?</p>
                            <span>“Delete Audience ”</span>
                        </div>
                        <div className="btn-block">
                            <div className="btn-item cancel" onClick={toggleDelete}>
                                <span>Cancel</span>
                            </div>
                            <div className="btn-item delete" onClick={() => this.deleteDomain(deleteKey)}>
                                <span>Delete</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PolicyDeleteAudienceLbxComponent;