import React, {Component} from "react";
import AddButtonComponent from "../buttons/addButtonComponent";
import {NavLink} from "react-router-dom";
import {WEB_SITES,WEB_SITE_SITE_INFO} from "../../constants/routePaths";
class AddNewComponentListWebsite extends Component {
  
    render() {
        let {buttonName, title, text, icon,domains,link} = this.props;
        return (
            <div className="table-container">
            <div className="table-head-block">
                <div className="row-item">
                    <div className="row-td name-td">
                        <span className="black">{title}</span>
                      
                    </div>
                   
                    <div className="row-td button-td">
                        <AddButtonComponent buttonName = {buttonName} link={link}/>
                    </div>
                </div>
            </div>
            <div className="table-block">

                {/*TODO: Component için kullanılacak satır.*/}
                {
                  domains &&
                    domains.map((obj,index) => {
                        return(
                            <div className="row-item" key={index}>
                                <div className="row-td name-td">
                                    <span>{obj.domain_name}</span>
                                </div>
                           
                            <div className="row-td status-td">
                                <span  className={`status-color  ${obj.did_integrate === true ? "integrated" : "unintegrated" } `}></span>
                            </div>
                            <NavLink to={WEB_SITE_SITE_INFO.replace(":id",obj.id)} className="row-td button-td" >
                                <div className="create-btn" >
                                    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width={32} height={32} fill="white" />
                                        <path d="M14 18L17 17L23.5858 10.4142C23.851 10.149 24 9.78929 24 9.41421C24 8.63317 23.3668 8 22.5858 8C22.2107 8 21.851 8.149 21.5858 8.41421L15 15L14 18Z" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16 9H11C9.89543 9 9 9.89543 9 11V21C9 22.1046 9.89543 23 11 23H21C22.1046 23 23 22.1046 23 21V16" stroke="black" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                               
                            </NavLink>
                        </div>
                        )
                    })
                }
          
                <NavLink to={WEB_SITES} className="btn-item see-all">
                    <span className="see-all-text">See All</span>
                </NavLink>
               
            </div>
        </div>
        );
    }
}

export default AddNewComponentListWebsite;
