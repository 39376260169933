import {RECEIVE_SIGNED_USER} from "../constants/actionTypes";

const initialStore = {
    user:null
};

const userReducer = (state = initialStore, action) => {
    switch (action.type) {
        case RECEIVE_SIGNED_USER:
            return {
                user:action.payload
            };
        default :
            return state;
    }
};

export default userReducer;