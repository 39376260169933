import React, { Component } from "react";
import { connect } from "react-redux";
import { getDomainLanguageDetailAction } from "../../actions/domainActions";
import CategoriesLanguageComponent from "../../components/languageDetail/categoriesLanguageComponent";
import CookieLanguageComponent from "../../components/languageDetail/cookieLanguageComponent";
import InterfaceLanguageComponent from "../../components/languageDetail/interfaceLanguageComponent";
import ErrorComponent from "../../components/layout/errorComponent";
import { Form } from 'react-final-form'
import SucsessComponent from "../../components/layout/sucsessComponent";
import { saveDomainLanguageDetails,updateDefaultLanguage ,getDomainLanguages} from "../../services/webServices";
import ButtonBlockComponent from "../../components/buttons/buttonBlockComponent";

class LanguageDetailContainer extends Component {
    state = {
        actveTableTab: "categories",
        selectedLanguage: 1,
        didSelectOpen: false,
        success: false,
        error: false,
        lang:"",
    }


    constructor(props) {
        super(props);
        this.getDomainDetail();
    }
    
    
    

    getDomainDetail = () => {
        this.props.dispatch(getDomainLanguageDetailAction(this.props.domainId, (res) => {
            if (res) {
                this.setState({
                    ...res
                })
            }
        }));
    }

    updateDefaultLang = (lang_id)=>{
        updateDefaultLanguage({domainId:this.props.domainId,languageId:lang_id}).then((result)=>{
            window.location.reload()

        })
    }

    componentDidMount(){
        getDomainLanguages({domainId:this.props.domainId}).then((result)=>{
            if(result.data.success){
                this.setState({
                    langueArr:result.data.success
                })
            }
        })
        if(this.props.detailLanguage){
            this.setState({
                selectedLanguage: this.props.detailLanguage
            })
        }else if(this.props.langueArr && this.props.langueArr[0]){
            this.setState({
                selectedLanguage: this.props.langueArr[0].id
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.detailLanguage !== this.props.detailLanguage) {
            // this.setState({
            //     selectedLanguage: this.props.detailLanguage
            // })
        }
   
    }

    changeActiveTableTab = (actveTableTab) => {
        this.setState({
            actveTableTab
        })
    }
    toggleSelect = () => {
        this.setState({
            didSelectOpen: !this.state.didSelectOpen
        })
    }
    onLangChange = (selectedLanguage,active) => {
        this.setState({
            selectedLanguage:selectedLanguage,  
        })
       
       
    }
    submit = (values) => {
        let selectedLanguage = this.props.langueArr.find(item => item.language.id == this.state.selectedLanguage)
        let activeValue = this.state[selectedLanguage.language.language_code] !== undefined ?this.state[selectedLanguage.language.language_code]:selectedLanguage.is_active;
        saveDomainLanguageDetails(this.props.domainId, { languages: values, is_active:activeValue,lang: this.state.selectedLanguage, language_code:selectedLanguage.language.language_code }).then((result) => {
            if (result.data.success) {
                this.setState({
                    success: true
                })
                this.getDomainDetail();
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.setState({
                        success: false
                    })
                }, 2000)
            } else {
                this.setState({
                    error: true
                })
                clearTimeout(this.timer);
                this.errorTimer = setTimeout(() => {
                    this.setState({
                        error: false
                    })
                }, 2000)
            }
        })
    }
    changeActive = (is_active,selectedLanguage) => {
        this.setState({
            [selectedLanguage.language.language_code]:is_active
        })
    }
    render() {
        let {langueArr} = this.props
        let selectedLanguage = langueArr.find(item => item.language.id == this.state.selectedLanguage)
        let activeValue;
        if(selectedLanguage){
            activeValue = this.state[selectedLanguage.language.language_code] !== undefined ?this.state[selectedLanguage.language.language_code]:selectedLanguage.is_active;
        }
        return (
            
                selectedLanguage ? 
        
            <div className="translations-block">
                <div className="lang-select-container">
                    <div className="lang-select-block">
                        <div className="lang-title-item">
                            <span className="text-title-item">Language</span>
                            {/* TODO: passive clası geldiğine title-btn-item görünmez */}
                            <span onClick={()=>{this.updateDefaultLang(selectedLanguage.language_id)}} className={`title-btn-item ${selectedLanguage.is_default === true ? "passive" : ""}`}>Make this default</span>
                        </div>
                        {/*TODO:select-block a opened class ı geldiğinde select-menü açılır*/}
                        <div className={`select-block ${this.state.didSelectOpen === true ? "opened" : ""}`} onClick={this.toggleSelect}>
                            <span>{selectedLanguage.language.language_name }</span>
                            <div className="lang-option-block">
                                <ul>
                                    {
                                        langueArr.map((obj,index)=>{
                                            return(
                                                <li key={index} onClick={() => this.onLangChange(obj.language.id)}>{obj.language.language_name}</li>
                                            )
                                        })
                                    }
                                    {/* <li onClick={() => this.onLangChange(this.state.selectedLanguage === "tr" ? "en" : "tr")}>{this.state.selectedLanguage === "tr" ? "English" : "Turkish"}</li> */}

                                </ul>
                            </div>
                        </div>
                        {
                            selectedLanguage.is_default==0 &&
                            <div className="lang-radio-btn">
                                <div className="radio-item">
    
                                    <input type="radio" onChange={() => { this.changeActive(1,selectedLanguage) }} value="Active" name="lang-status" id="check1" checked={activeValue ? "checked":false}   />
                                    <label htmlFor="Active" className="check1">Active</label>
                                    <input type="radio" onChange={() => { this.changeActive(0,selectedLanguage) }} value="Passive" name="lang-status" id="check2" checked={!activeValue ? "checked":false} />
                                    <label htmlFor="Passive" className="check2">Passive</label>
                                </div>
                                <p className="text-desc-item">Language will be visible when selected active.</p>
    
                            </div>
                        }
                    </div>
                </div>
                <div className="lang-table-head-container">
                    {/* TODO --> ACTİVE CLASI ALAN HEAD_İTEM GÖZÜKÜR*/}
                    {/* <span className={`head-item  ${this.state.actveTableTab === "cookie" ? "active" : ""}`} onClick={() => this.changeActiveTableTab("cookie")} >Cookies</span> */}
                    <span className={`head-item  ${this.state.actveTableTab === "categories" ? "active" : ""}`} onClick={() => this.changeActiveTableTab("categories")} >Categories</span>
                    <span className={`head-item  ${this.state.actveTableTab === "interface" ? "active" : ""}`} onClick={() => this.changeActiveTableTab("interface")} >Interface</span>

                </div>
                {/* TODO -- > lang-table-root clası cookie categries interface  aldığında ilgili tablo gözükür*/}
                <Form
                    onSubmit={this.submit}
                    render={({ handleSubmit }) => (
                        <div className={`lang-table-root  ${this.state.actveTableTab === "interface" ? "interface" : this.state.actveTableTab === "categories" ? "categries" : "cookie"}`}>
                            <form onSubmit={handleSubmit}>
                                {
                                    (this.state.domainTexts) &&
                                    <InterfaceLanguageComponent langueArr={langueArr} getDomainDetail={this.getDomainDetail} domainId={this.props.domainId} selectedLanguage={selectedLanguage} domainTexts={this.state.domainTexts} />
                                }
                                {/* <CookieLanguageComponent/> */}
                                {
                                    (this.state.categories) &&
                                    <CategoriesLanguageComponent langueArr={langueArr} getDomainDetail={this.getDomainDetail} domainId={this.props.domainId} selectedLanguage={selectedLanguage} categories={this.state.categories} />
                                }

                                <SucsessComponent warn={this.state.success} message={"Catgeories Languages successfuly updated."} />
                                <ErrorComponent warn={this.state.error} />
                                <ButtonBlockComponent />
                            </form>
                        </div>
                    )
                    }
                />

            </div>
            :
            <div>
            </div>
        )
    }
};

export default connect()(LanguageDetailContainer)