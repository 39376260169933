export  const LOGIN_PAGE = "/login"
// export  const REGISTER_PAGE = "/register"
export  const REGISTER_INVITATION ="/register/:token/:userId"
export  const RESET_PASSWORD = "/reset"
export  const RESET_PASSWORD2 = "/reset2"
export  const RESET_PASSWORD3 = "/reset3"
export  const RESET_PASSWORD4 = "/reset4"
export const DASHBOARD_PAGE = "/dashboard"
export const WEB_SITES = "/websites"
export const ADD_WEB_SITES = "/websites/add"

export const WEB_SITE_DETAIL = "/websites/:id/"
export const WEB_SITE_DETAIL_WITHOUT_PARAM = "/websites/"

export const POLICIES_PAGE = "/policies"
export const POLICIES_ADD = "/policies/add"
export const POLICIES_EDIT = "/policies/edit/:id"
export const POLICIES_EDIT_WITHOUT_PARAM = "/policies/edit/"

export const SDK_PAGE = "/sdk"
export const REPORTS_PAGE = "/reports"
export const USERS_PAGE = "/users"
export const ADD_USERS_PAGE = "/users/add"
export const PLANS_PAGE = "/plans"
export const STATISTICS_PAGE = "/statistics"

export const COMPANY_LIST = "/companylist"
export const SUPER_POLICIES = "/adminpolicies"


export const WEB_SITE_SITE_INFO = "/websites/:id/site-info"
export const WEB_SITE_COOKIE_LIST = "/websites/:id/cookie-list"
export const WEB_SITE_POLICY_AUDIENCE = "/websites/:id/policy-audience"
export const WEB_SITE_LANGUAGES = "/websites/:id/languages"
export const WEB_SITE_DESIGN = "/websites/:id/design"
export const WEB_SITE_CATEGORIES = "/websites/:id/category"
export const WEB_SITE_INTEGRATION = "/websites/:id/integration"

export const WEB_SITE_3RD_PARTIES= "/websites/:id/parties"

