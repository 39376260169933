import React, {Component} from 'react';
import {Field, Form} from "react-final-form";
import TextInputComponent from "../formFields/textInputComponent";
import RecaptchaFieldComponent from "../formFields/recaptchaFieldComponent";
import {signinAction} from "../../actions";
import {connect} from "react-redux";
import {DASHBOARD_PAGE} from "../../constants/routePaths";
import {withRouter} from "react-router-dom";
import LoaderComponent from "../loaderComponent";
import PasswordRequirementsComponent from "../formFields/passwordReguirementsComponent";

const validate = (values) => {
    const errors = {};
    if (!values || !values.email || values.email === "" || !/^([a-zA-Z0-9!#$%&'*-`{|}~_.]{1}[a-zA-Z0-9!#$%&'*-`{|}~_.]*)(@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6})$/i.test(values.email)) {
        errors.email = "Email giriniz.";
    }
    if (!values || !values.password || values.password === "") {
        errors.password = "Şifre giriniz";
    }
    if (!values || !values.password_again || values.password_again === "") {
        errors.password_again = "Şifre giriniz";
    }
    if (!values || !values.name || values.name === "") {
        errors.name = "Ad giriniz";
    }
    if (!values || !values.surname || values.surname === "") {
        errors.surname = "Soyad giriniz";
    }
    if (!values.recaptcha_code || values.recaptcha_code.trim().length === 0) {
        errors.recaptcha_code = true
    }
    console.log(errors)
    return errors;
};

class RegisterFormComponent extends Component {
    state = {
        isLoading: false
    }
    submit = (values) => {
        this.setState({
            isLoading: true
        })
        this.props.dispatch(signinAction(values, (success) => {
            if (success) {
                this.setState({
                    isLoading: false
                })
                this.props.history.push(DASHBOARD_PAGE)
            } else {
                this.setState({
                    isLoading: false
                })
            }
        }))
    }

    render() {
        let {isLoading} = this.state;
        return (
            <div>
                {
                    isLoading &&
                    <LoaderComponent/>
                }
                <Form onSubmit={this.submit}
                      validate={validate}
                      render={({handleSubmit}) => (
                          <form onSubmit={handleSubmit}>
                              <div className="input-block">
                                  <Field
                                      name={"name"}
                                      type="text"
                                      label="Name*"
                                      component={TextInputComponent}
                                      err_txt="Please enter name"
                                  />

                                  <Field
                                      name={"surname"}
                                      type="text"
                                      label="Surname*"
                                      component={TextInputComponent}
                                      err_txt="Please enter surname"
                                  />
                              </div>
                              <div className="input-block full">
                                  <Field
                                      name={"email"}
                                      type="text"
                                      label="E-mail*"
                                      component={TextInputComponent}
                                      err_txt="Please enter e-mail"
                                  />
                              </div>
                              <div className="input-block">
                                  <Field
                                      name={"password"}
                                      type="password"
                                      label="Password*"
                                      component={TextInputComponent}
                                      err_txt="Please enter password"
                                  />
                                  {/*TODO: password again için eşleşme kontrolü yapılmalı.*/}
                                  <Field
                                      name={"password_again"}
                                      type="password"
                                      label="Password Again*"
                                      component={TextInputComponent}
                                      err_txt="Passwords do not match."
                                  />
                              </div>
                              <Field
                                  name="recaptcha_code"
                                  component={RecaptchaFieldComponent}
                              />
                              <div className="btn-block">
                                  <button type="submit" className="btn-item">
                                      Register
                                  </button>
                              </div>
                          </form>
                      )}>

                </Form>

            </div>
        );
    }
}

export default withRouter(connect()(RegisterFormComponent));
