import React, { Component } from 'react';

class DeleteCookieListComponent extends Component {
    render() {
        return (
            <div className="lightBox-root delete">
                <div className="lightBox-wrapper">
                    <div className="lightBox-container ">
                        <div className="head-item">
                            <h2 className="light-box-title">Delete Cookies</h2>
                            <div className="close-icon" onClick={() => this.props.lightBox()}>
                                <i>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 6.5L12 12.5M12 12.5L18 6.5M12 12.5L6 18.5M12 12.5L18 18.5" stroke="black" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </i>
                            </div>
                        </div>
                        <div className="text-block">
                            <p>Do you want to delete the policy?</p>
                            <span>“Delete Cookie ”</span>
                        </div>
                        <div className="btn-block">
                            <div className="btn-item cancel" onClick={() => this.props.lightBox()}>
                                <span>Cancel</span>
                            </div>
                            <div className="btn-item delete" onClick={() => { this.props.deleteCokieFunc(); }}>
                                <span>Delete</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeleteCookieListComponent;