import React, { Component } from 'react';
//import { updateDomainCookieAction } from './../../actions/domainActions';

class AnalyticsBoxComponent extends Component {
    state = {
        isOpen: false,
        isOpenDot: false,
        editLigtbox: false,
        deleteLightbox: false,
        IsSuccess: false,
        isStatus: ""

    }

    analytics = []
    analytics_storage = []
    functionality_storage = []
    ad_storage = []

    componentDidMount() {
        this.props.cookie && this.props.cookie.filter(item => item.third_party == "analytics" ? this.analytics.push(item) : item)
        this.setTextCategory()
    }

    setTextCategory = () => {
       
        this.analytics && this.analytics.forEach(item => {
            let categoryName = this.props.allCategories?.find(ctg => ctg.id === item.category_id)?.category_name

            if (item.policy_value === "analytics_storage" && this.analytics_storage.filter(x=>x === categoryName).length === 0) {
                this.analytics_storage.push(categoryName)
                console.log("cliccccck");
            } else if (item.policy_value === "functionality_storage" && this.functionality_storage.filter(x=>x === categoryName).length === 0) {
                this.functionality_storage.push(categoryName)
            } else if (item.policy_value === "ad_storage" && this.ad_storage.filter(x=>x === categoryName).length === 0) {
                this.ad_storage.push(categoryName)
            }
            
        }
        )
        
        this.forceUpdate()
    }


    changeCookieCategory = (categoryItem , third,policy) => {
        let categoryName = this.props.allCategories?.find(ctg => ctg.id === categoryItem.id)?.category_name
        if (this.state.isOpen && categoryItem.id !== this.props.cookie.cookie_category_id) {
            let params ={
                category_id : categoryItem.id,
                third_party : third,
                policy_value : policy,
                isDeleted : false
            }
            if (policy === "analytics_storage" && this.analytics_storage.filter(x=>x === categoryName).length === 0) {
                this.analytics_storage.push(categoryName)
                params.isDeleted = false
                console.log("analytics_storage",this.analytics_storage);
                console.log("analytics_storage_cliccccckaa");
        

            } else if(policy === "analytics_storage") {
                let index = this.analytics_storage.indexOf(categoryName);
                if (index > -1) { 
                    this.analytics_storage.splice(index, 1); 
                    params.isDeleted = true
                  }
            }
            if (policy === "functionality_storage" && this.functionality_storage.filter(x=>x === categoryName).length === 0) {
                this.functionality_storage.push(categoryName)
                params.isDeleted = false
            } else if(policy === "functionality_storage") {
                let index = this.functionality_storage.indexOf(categoryName);
                if (index > -1) { 
                    this.functionality_storage.splice(index, 1); 
                    params.isDeleted = true
                  }
            }
            if (policy === "ad_storage" && this.ad_storage.filter(x=>x === categoryName).length === 0) {
                this.ad_storage.push(categoryName)
                params.isDeleted = false
            } else if(policy === "ad_storage") {
                let index = this.ad_storage.indexOf(categoryName);
                if (index > -1) { 
                    this.ad_storage.splice(index, 1); 
                    params.isDeleted = true
                  }
            }
            this.props.updateValAnalistic(params)
        }

    }
    toggleCategoryMenu = (sta) => {
        this.setState({
            isOpen: !this.state.isOpen,
            isStatus: sta
        })
    }
    render() {
        let { allCategories, cookie } = this.props
        return (
            <div className='parties-table-container'>
                <div className="parties-table-title-block">
                    <div className="title-item name-td">
                        <span>Name</span>
                    </div>
                    <div className="title-item category-td">
                        <span>Category</span>
                    </div>
                </div>
                <div className="parties-table-item-block">
                    <div className="table-item name-td">
                        <span>analytics_storage</span>
                    </div>
                    <div className="table-item category-td">

                        <div className={`select-block ${this.state.isOpen && this.state.isStatus == "analytics" ? "opened" : ""}`} ref={this.optionRoot} onClick={()=>this.toggleCategoryMenu("analytics")}>
                            {/* <span>{this.state.analytics_storage}</span> */}
                            {
                                 this.analytics_storage?

                                this.analytics_storage.map((item, key) => {
                                    return (
                                        <span key={key} >{item && key > 0 ? ", ":""}{item}</span>
                                    )
                                })
                                :
                                <span>Kategoriler</span>
                            }

                            <ul className="category-list">
                                {
                                    allCategories && allCategories.filter(item => item.id !== cookie.category_id).map((categoryItem, key) =>
                                        <li onClick={() => { this.changeCookieCategory(categoryItem,"analytics","analytics_storage") }} key={key} className="category-item" >
                                            {categoryItem && categoryItem.category_name ? categoryItem.category_name : "Bilinmeyen"}
                                        </li>
                                    )
                                }


                            </ul>
                        </div>
                    </div>
                </div>
                <div className="parties-table-item-block">
                    <div className="table-item name-td">
                        <span>functionality_storage</span>
                    </div>
                    <div className="table-item category-td">
                        <div className={`select-block ${this.state.isOpen && this.state.isStatus == "functionality" ? "opened" : ""}`} ref={this.optionRoot} onClick={()=>this.toggleCategoryMenu("functionality")}>
                           
                            {
                                this.functionality_storage ? 
                                this.functionality_storage.map((item, key) => {
                                    return (
                                        <span key={key} >{item && key > 0 ? ", ":""}{item}</span>
                                    )
                                })
                                :
                                <span>Kategoriler</span>
                            }

                            <ul className="category-list">
                                {
                                    allCategories && allCategories.filter(item => item.id !== cookie.category_id).map((categoryItem, key) =>
                                        <li onClick={() => { this.changeCookieCategory(categoryItem,"analytics","functionality_storage") }} key={key} className="category-item" >
                                            {categoryItem && categoryItem.category_name ? categoryItem.category_name : "Bilinmeyen"}
                                        </li>
                                    )
                                }


                            </ul>
                        </div>
                    </div>
                </div>
                <div className="parties-table-item-block">
                    <div className="table-item name-td">
                        <span>ad_storage</span>
                    </div>
                    <div className="table-item category-td">
                        <div className={`select-block ${this.state.isOpen && this.state.isStatus == "ad_storage" ? "opened" : ""}`} ref={this.optionRoot} onClick={()=>this.toggleCategoryMenu("ad_storage")}>
                            {/* <span>{this.state.ad_storage}</span> */}
                            {
                                this.ad_storage ?
                                this.ad_storage.map((item, key) => {
                                    return (
                                        <span key={key} >{item && key > 0 ? ", ":""}{item}  </span>
                                    )
                                })
                                :
                                <span>Kategoriler</span>
                            }

                            <ul className="category-list">
                                {
                                    allCategories && allCategories.filter(item => item.id !== cookie.category_id).map((categoryItem, key) =>
                                        <li onClick={() => { this.changeCookieCategory(categoryItem,"analytics","ad_storage") }} key={key} className="category-item" >
                                            {categoryItem && categoryItem.category_name ? categoryItem.category_name : "Bilinmeyen"}
                                        </li>
                                    )
                                }


                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AnalyticsBoxComponent;
