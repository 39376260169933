import React, { Component } from 'react';

class StatisticValueComponent extends Component {

    componentDidMount(){
    }

    render() {
  
        return (
            <div className='statistic-value-container'>
                <span>Accepted</span>
                <div className="stat-value-block">
                    {
                        this.props.statisticData.map((item, key) =>
                        item.category_name !== "Unknown" &&
                            <div key={key} className="stat-value-item statistic-item">
                                <span>{item.accept_count}</span>
                                <p style={{color:item.category_name === "abroadData" && "#"+this.props.foeignColor || item.category_name === "Analitik" && "#"+this.props.analistColor || item.category_name === "Social" && "#"+this.props.socialColor || item.category_name === "Required" && "#"+this.props.requareColor     } }>{ item.category_name === "abroadData" ? "Foreign Data Exit Permission" : item.category_name}</p>
                            </div>
                        )
                    }
                    {/* && color:item.category_name === "Analitik" ? "#"+this.props.analistColor && color:item.category_name === "Analytics" ? "#"+this.props.analistColor && color:item.category_name === "Social" ? "#"+this.props.socialColor && color:item.category_name === "Required" ? "#"+this.props.requareColor */}
                </div>
            </div>
        );
    }
}

export default StatisticValueComponent;